import { fs24, QuestionStack } from '@scout24ch/fs24-design-system';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_INSURANCE_OPTIONS } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
  getVehicleData,
} from 'state/selectors';
import { useTranslation, useValidation } from 'hooks';
import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';
import { getLookupData } from 'state/LookUpData/selectors';
import { StepButtons } from '../StepButtons';
import {
  AccidentInsuranceDailyAllowanceInput,
  AccidentInsuranceInput,
  AccidentInsuranceSumPayableAtDeathInput,
  AccidentInsuranceSumPayableAtDisabilityInput,
  AccidentInsuredPersonsInput,
  BonusProtectionInput,
  CarriedThingsInsuranceSumInput,
  CurrentInsuranceCompanyInput,
  FreeGarageChoiceInput,
  FullyComprehensiveInsuranceCostSharingInput,
  HasCurrentInsuranceCompanyInput,
  HasProtectiveClothingInput,
  HasSuspensionRenunciationInput,
  InsuranceTypeInput,
  ParkingDamageInfiniteInput,
  ParkingDamageInput,
  PartialCoverInsuranceCostSharingInput,
} from './components';
import {
  ID_CURRENT_INSURANCE,
  ID_FREE_GARAGE_CHOICE,
  ID_FULLY_COMPREHENSIVE,
  ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING,
  ID_HAS_PROTECTIVE_CLOTHING,
  ID_PARTIAL_COVER,
  ID_PARTIAL_COVER_INSURANCE_COST_SHARING,
} from './components/const';
import {
  getHasPreviousInsuranceState,
  staticInsuranceCompanyValues,
} from './helpers/insuranceOptionsUtils';
import { StepInsuranceOptionsProps } from './StepInsuranceOptions';

export const StepMotoInsuranceOptions: React.FC<StepInsuranceOptionsProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const lookupData = useSelector(getLookupData);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const {
    usageDetails: { leasing },
    insuranceOptions,
  } = useSelector(getInquiryInputs);
  const vehicle = useSelector(getVehicleData);
  const { t } = useTranslation();
  const validation = useValidation(
    NS_STEP_INSURANCE_OPTIONS,
    getInquiryValidation,
  );

  const [hasPreviousInsurance, setHasPreviousInsurance] = React.useState(
    getHasPreviousInsuranceState(insuranceOptions.currentInsuranceCompany),
  );

  const onChange = (name: string, value: any) => {
    insuranceOptions[name] = value;

    // reset values of the fields of "isPartialCoverInsurance" if it was first selected and it own fields changed
    // and user maybe deselects it then we should reset it's values back as not being touched
    if (name === ID_PARTIAL_COVER && value === false) {
      insuranceOptions[ID_PARTIAL_COVER_INSURANCE_COST_SHARING] =
        defaultLookupValue;
      insuranceOptions[ID_FREE_GARAGE_CHOICE] = defaultLookupValue;
      insuranceOptions[ID_HAS_PROTECTIVE_CLOTHING] = null;
    }

    // reset values of the fields of "isFullyComprehensiveInsurance" if it was first selected and it own fields changed
    // and user maybe deselects it then we should reset it's values back as not being touched
    if (name === ID_FULLY_COMPREHENSIVE && value === false) {
      insuranceOptions[ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING] =
        defaultLookupValue;
    }

    dispatch(inquiryInputChanged(insuranceOptions, null));
  };

  const carriedThingsInsuranceInput = () => {
    const {
      isPartialCoverInsurance,
      isFullyComprehensiveInsurance,
      carriedThingsInsuranceSum,
    } = insuranceOptions;

    if (isPartialCoverInsurance || isFullyComprehensiveInsurance) {
      return (
        <CarriedThingsInsuranceSumInput
          value={carriedThingsInsuranceSum}
          validation={validation}
          lookupData={lookupData.carriedThingsInsuranceSums}
          onChange={(value) => onChange('carriedThingsInsuranceSum', value)}
        />
      );
    }
    return null;
  };

  const parkingDamageInput = () => {
    if (
      insuranceOptions.isPartialCoverInsurance ||
      insuranceOptions.isFullyComprehensiveInsurance
    ) {
      return (
        <React.Fragment>
          <ParkingDamageInput
            value={insuranceOptions.isParkingDamage}
            validation={validation}
            onChange={(value) => onChange('isParkingDamage', value)}
          />
          {insuranceOptions.isParkingDamage && (
            <ParkingDamageInfiniteInput
              value={insuranceOptions.isParkingDamageSumUnlimited}
              validation={validation}
              onChange={(value) =>
                onChange('isParkingDamageSumUnlimited', value)
              }
            />
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  const currentInsuranceCompanyInput = () => {
    return (
      <React.Fragment>
        <HasCurrentInsuranceCompanyInput
          value={hasPreviousInsurance}
          onChange={(value) => {
            setHasPreviousInsurance(value);
            if (value === false) {
              onChange(
                ID_CURRENT_INSURANCE,
                staticInsuranceCompanyValues.noInsurance,
              );
            } else {
              onChange(ID_CURRENT_INSURANCE, staticInsuranceCompanyValues.none);
            }
          }}
          validation={validation}
        />
        {hasPreviousInsurance && (
          <CurrentInsuranceCompanyInput
            value={insuranceOptions.currentInsuranceCompany}
            validation={validation}
            lookupData={lookupData.insuranceCompanies}
            onChange={(value) => onChange('currentInsuranceCompany', value)}
          />
        )}
      </React.Fragment>
    );
  };

  const accidentInsurancInputs = () => {
    return (
      <React.Fragment>
        <AccidentInsuranceInput
          value={insuranceOptions.isAccidentInsurance}
          validation={validation}
          onChange={(value) => onChange('isAccidentInsurance', value)}
        />
        {insuranceOptions.isAccidentInsurance && (
          <React.Fragment>
            <AccidentInsuredPersonsInput
              value={insuranceOptions.accidentInsuredPersons}
              lookupData={lookupData.insuredPersons}
              validation={validation}
              onChange={(value) => onChange('accidentInsuredPersons', value)}
            />
            <AccidentInsuranceSumPayableAtDeathInput
              value={insuranceOptions.accidentInsuranceSumPayableAtDeath}
              validation={validation}
              lookupData={lookupData.insuredSumsAtDeath}
              onChange={(value) =>
                onChange('accidentInsuranceSumPayableAtDeath', value)
              }
            />
            <AccidentInsuranceSumPayableAtDisabilityInput
              value={insuranceOptions.accidentInsuranceSumPayableAtDisability}
              validation={validation}
              lookupData={lookupData.insuredSumsAtDisability}
              onChange={(value) =>
                onChange('accidentInsuranceSumPayableAtDisability', value)
              }
            />
            <AccidentInsuranceDailyAllowanceInput
              value={insuranceOptions.accidentInsuranceDailyAllowance}
              validation={validation}
              lookupData={lookupData.allowances}
              onChange={(value) =>
                onChange('accidentInsuranceDailyAllowance', value)
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const vehicleOlderThan7 =
    vehicle && moment().diff(vehicle.dateOfFirstRegistration, 'years') > 7;

  return (
    <QuestionStack as="form">
      <InsuranceTypeInput
        liabilityInsurance={insuranceOptions.isLiabilityInsurance}
        partialCoverInsurance={insuranceOptions.isPartialCoverInsurance}
        fullyComprehensiveInsurance={
          insuranceOptions.isFullyComprehensiveInsurance
        }
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
        disabled={leasing}
        partialCoverSuggested={!leasing && vehicleOlderThan7}
        fullyComprehensiveSuggested={!leasing && !vehicleOlderThan7}
      />
      {insuranceOptions.isPartialCoverInsurance && (
        <PartialCoverInsuranceCostSharingInput
          value={insuranceOptions.partialCoverInsuranceCostSharing}
          validation={validation}
          lookupData={lookupData.partialCoverCostSharings}
          onChange={(value) =>
            onChange('partialCoverInsuranceCostSharing', value)
          }
        />
      )}
      {insuranceOptions.isPartialCoverInsurance && (
        <FreeGarageChoiceInput
          value={insuranceOptions.freeGarageChoice}
          validation={validation}
          lookupData={lookupData.freeGarageChoices}
          onChange={(value) => onChange('freeGarageChoice', value)}
        />
      )}
      {insuranceOptions.isFullyComprehensiveInsurance && (
        <FullyComprehensiveInsuranceCostSharingInput
          value={insuranceOptions.fullyComprehensiveInsuranceCostSharing}
          validation={validation}
          lookupData={lookupData.fullyComprehensiveCostSharings}
          onChange={(value) =>
            onChange('fullyComprehensiveInsuranceCostSharing', value)
          }
        />
      )}
      <fs24.p fontSize="lg" mt={12} mb={6}>
        {t(`${NS_STEP_INSURANCE_OPTIONS}.additional-title`)}
      </fs24.p>
      <BonusProtectionInput
        value={insuranceOptions.isBonusProtection}
        validation={validation}
        onChange={(value) => onChange('isBonusProtection', value)}
      />
      {insuranceOptions.isPartialCoverInsurance && (
        <HasProtectiveClothingInput
          value={insuranceOptions.hasProtectiveClothing}
          validation={validation}
          onChange={(value) => onChange('hasProtectiveClothing', value)}
        />
      )}
      <HasSuspensionRenunciationInput
        value={insuranceOptions.hasSuspensionRenunciation}
        validation={validation}
        onChange={(value) => onChange('hasSuspensionRenunciation', value)}
      />
      {carriedThingsInsuranceInput()}
      {parkingDamageInput()}
      {accidentInsurancInputs()}
      {currentInsuranceCompanyInput()}
      <StepButtons
        nextStep={props.nextStep}
        previousStep={props.previousStep}
        lastStep={props.lastStep}
        isAdjustingData={props.isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
