import React from 'react';
import { Alert } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';

interface TypeCertificateErrorsProps {
  haveNoResults: boolean;
  typeCertificateError: boolean;
}

export const TypeCertificateErrors: React.FC<TypeCertificateErrorsProps> = (
  props,
) => {
  const { haveNoResults, typeCertificateError } = props;
  const { t } = useTranslation();

  return (
    <>
      {haveNoResults ? (
        <Alert status="error">
          {t('step:usageDetails.typeCertificate-empty')}
        </Alert>
      ) : null}
      {typeCertificateError ? (
        <Alert status="error">{t('common:common.serverError')}</Alert>
      ) : null}
    </>
  );
};
