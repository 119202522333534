import React from 'react';
import { Text } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'react-i18next';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { suspensionOfDrivingLicenseDefaults } from '../helpers/incidentsDefaults';
import { ListInput } from './ListInput';
import { SuspensionInput } from './SuspensionInput';
import { SuspensionOfDrivingLicense } from '../types';

type SuspensionListInputProps = {
  suspensions: SuspensionOfDrivingLicense[];
  validation: EvaluatedValidation;
  onChange: (obj: Record<string, any>) => void;
};

function SuspensionListInput(props: SuspensionListInputProps) {
  const { suspensions, validation, onChange } = props;
  const { t } = useTranslation();

  function handleOnChange(i: number, { name, value }) {
    const copiedSuspensions = [...suspensions];
    copiedSuspensions[i][name] = value;
    onChange({
      name: 'suspensionOfDrivingLicense',
      value: copiedSuspensions,
    });
  }

  function addElement(index: number) {
    const updatedSuspensions = [...suspensions];
    updatedSuspensions.splice(
      index + 1,
      0,
      suspensionOfDrivingLicenseDefaults(),
    );
    onChange({ name: 'suspensionOfDrivingLicense', value: updatedSuspensions });
  }

  function removeElement(index: number) {
    const updatedSuspensions = [...suspensions];
    updatedSuspensions.splice(index, 1);
    onChange({ name: 'suspensionOfDrivingLicense', value: updatedSuspensions });
  }

  return (
    <div>
      <Text fontSize="xl" mb={6}>
        {t(`${NS_STEP_INCIDENTS}.suspensions-title`)}
      </Text>
      <ListInput
        translationKey="suspensions"
        addElement={addElement}
        removeElement={removeElement}
      >
        {suspensions.map((suspension, i) => (
          <SuspensionInput
            key={i}
            index={Number(i)}
            suspension={suspension}
            validation={validation}
            onChange={(e) => handleOnChange(i, e)}
          />
        ))}
      </ListInput>
    </div>
  );
}

export default SuspensionListInput;
