import { useSelector } from 'react-redux';
import { getVehicle } from 'state/InquiryInput/selectors';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { SearchFlow } from 'utils/types';

export function useNumberPlateSearch() {
  const vehicle = useSelector(getVehicle);
  const { searchType } = vehicle;

  const isNumberPlateSearch =
    IS_AUTO_INSURANCE &&
    (searchType === SearchFlow.LICENSE_PLATE_NUMBER || !searchType);

  return {
    isNumberPlateSearch,
  };
}
