import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getInquiryInputs } from 'state/selectors';

export function useUpdateEmailHash() {
  const { setEmailHashCookies } = useGoogleTagManager();
  const { mainDriver, policyHolder } = useSelector(getInquiryInputs);

  // set/update cookies for email hash after email is set in previous step
  useEffect(() => {
    const driverEmail = policyHolder?.emailAddress || mainDriver?.emailAddress;
    setEmailHashCookies(driverEmail);
  }, [
    setEmailHashCookies,
    mainDriver?.emailAddress,
    policyHolder?.emailAddress,
  ]);
}
