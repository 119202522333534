import { FormContainer, Heading } from '@scout24ch/fs24-design-system';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useTranslation } from 'react-i18next';
import { storeError } from 'state/Error/actions';
import {
  changeStepWithValidation,
  createLongInquiry as createLongInquiryAction,
  requestChangeStep,
} from 'state/InquiryInput/actions';
import {
  fetchPreviousInsuranceInquiry,
  storeAutoScout24UserId,
} from 'state/InsuranceInquiry/actions';
import { withAutomaticRedirectToPremiums } from 'components/withAutomaticRedirectToPremiums';
import { PARAM_INQUIRY_KEY, PARAM_STEP } from 'utils/queryparam-const';
import tryToScrollToValidation from 'utils/refUtil';
import { hasErrors } from 'utils/validation';
import {
  getAutoScout24UserIdQueryParam,
  getQueryParam,
} from 'utils/routerUtil';
import { goToError, goToInquiry } from 'utils/navigationUtil';
import {
  NS_STEP_COMMON,
  NS_STEP_INCIDENTS,
  STEP_INSURANCE_OPTIONS,
  STEP_VEHICLE,
} from 'utils/namespace-const';
import { getGlobal } from 'utils/browserUtil';
import { LoadingIndicatorHolder } from 'components/Loader';
import usePrevious from 'hooks/usePrevious';
import {
  getInquiryInputs,
  getInquiryValidation,
  getVehicleData,
} from 'state/selectors';
import {
  getSavingInsuranceInquiryError,
  getSavingInsuranceInquiryFailed,
} from 'state/InsuranceInquiry/selectors';
import { getLookupData } from 'state/LookUpData/selectors';
import {
  getInquiryScrollToValidation,
  getInquiryStep,
} from 'state/InquiryInput/selectors';
import { getIsAdjustingData } from 'state/AdjustingData/selectors';
import StepperHolder from './components/common/StepperHolder';
import { Steps } from './components/Steps';
import { INSURANCE_INQUIRY_STEPS } from '../../utils/const';
import { useIsMobile } from '../../utils/responsive-const';
import { BackButton } from './components/common/BackButton';
import { SeoSection } from '../../components/SeoSection';

function FormWrapper({ children }: { children: React.ReactNode }) {
  return (
    <FormContainer px={[3, null, 0]} mb={[6, null, 20]}>
      {children}
    </FormContainer>
  );
}

const pagesWithCustomOrWithoutTitle = [
  STEP_INSURANCE_OPTIONS.name,
  STEP_VEHICLE.name,
];

function InquiryPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const node = React.useRef();
  const savingInsuranceInquiryFailed = useSelector(
    getSavingInsuranceInquiryFailed,
  );
  const inquiryError = useSelector(getSavingInsuranceInquiryError);
  const lookUpData = useSelector(getLookupData);
  const vehicle = useSelector(getVehicleData);
  const inputs = useSelector(getInquiryInputs);
  const step = useSelector(getInquiryStep);
  const validation = useSelector(getInquiryValidation);
  const scrollToValidation = useSelector(getInquiryScrollToValidation);
  const isAdjustingData = useSelector(getIsAdjustingData);
  const prevStep = usePrevious(step);
  const isMobile = useIsMobile();

  const changeStep = React.useCallback(() => {
    const name = getQueryParam(router, PARAM_STEP);
    const currentStepIndex = step?.index ?? -1;
    const newStep =
      INSURANCE_INQUIRY_STEPS.find((step) => step.name === name) ??
      INSURANCE_INQUIRY_STEPS[0];
    if (currentStepIndex < newStep.index) {
      dispatch(requestChangeStep(newStep));
      return;
    }

    if (currentStepIndex > newStep.index) {
      dispatch(changeStepWithValidation(newStep, step));
    }
  }, [dispatch, router, step]);

  function storeUserIdQueryParam() {
    const userId = getAutoScout24UserIdQueryParam(router);
    if (userId) {
      dispatch(storeAutoScout24UserId(userId));
    }
  }

  const goToErrorPage = React.useCallback(
    (prevFailed: boolean) => {
      if (
        savingInsuranceInquiryFailed &&
        prevFailed !== savingInsuranceInquiryFailed
      ) {
        dispatch(
          storeError({
            message: `${NS_STEP_INCIDENTS}.saving-error`,
            statusCode: (inquiryError as any).status,
          }),
        );

        goToError(router);
      }
    },
    [dispatch, inquiryError, router, savingInsuranceInquiryFailed],
  );

  const createLongInquiry = React.useCallback(() => {
    if (!inputs && vehicle && lookUpData) {
      dispatch(
        createLongInquiryAction(getQueryParam(router, PARAM_INQUIRY_KEY)),
      );
    }
  }, [dispatch, inputs, router, lookUpData, vehicle]);

  React.useEffect(() => {
    getGlobal().addEventListener('popstate', changeStep);

    return () => {
      getGlobal().removeEventListener('popstate', changeStep);
    };
  }, [changeStep]);

  useOnMount(() => {
    changeStep();

    if (!isAdjustingData) {
      dispatch(fetchPreviousInsuranceInquiry());
    }

    storeUserIdQueryParam();
  });

  React.useEffect(() => {
    if (prevStep && prevStep.name !== step.name) {
      goToInquiry(router, step.name);
    }
  }, [router, step.name, prevStep]);

  React.useEffect(() => {
    goToErrorPage(savingInsuranceInquiryFailed);
  }, [goToErrorPage, savingInsuranceInquiryFailed]);

  React.useEffect(() => {
    createLongInquiry();
  }, [createLongInquiry]);

  React.useEffect(() => {
    if (hasErrors(validation)) {
      tryToScrollToValidation(node?.current, scrollToValidation);
    }
  }, [validation, scrollToValidation]);

  if (!inputs) {
    return <LoadingIndicatorHolder />;
  }

  return (
    <div ref={node}>
      <SeoSection />
      {step && (
        <>
          {!isMobile ? <BackButton /> : null}
          <StepperHolder />
          <FormWrapper>
            {pagesWithCustomOrWithoutTitle.includes(step.name) ? null : (
              <Heading size="xl" mt={[4, null, 8]} mb={[6, null, 12]}>
                {t(`${NS_STEP_COMMON}.${step.name}`)}
              </Heading>
            )}
            <Steps />
          </FormWrapper>
        </>
      )}
    </div>
  );
}

export default withAutomaticRedirectToPremiums(InquiryPage);
