import React from 'react';
import { LookupItem } from 'utils/types';
import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';
import { useSelector } from 'react-redux';
import { getLookupData } from 'state/LookUpData/selectors';
import { ClaimTypeInput } from './ClaimTypeInput';
import { ClaimAmountInput } from './ClaimAmountInput';
import { ClaimCauseInput } from './ClaimCauseInput';
import { ClaimSelfInflictedInput } from './ClaimSelfInflictedInput';
import { ClaimCauserInput } from './ClaimCauserInput';
import ListInputForm from './ListInputForm';
import MonthYearInput from './MonthYearInput';
import { ID_CLAIM_AMOUNT, ID_CLAIM_DATE } from './const';

const CLAIM_TYPES = {
  liability: {
    name: 'Liability',
    prefix: 'L',
  },
  comprehensive: {
    name: 'Comprehensive',
    prefix: 'C',
  },
};

const filterByType = (item, type) => {
  if (item.name === 'None') {
    return true;
  }
  switch (type.name) {
    case CLAIM_TYPES.liability.name:
      return item.name.startsWith(CLAIM_TYPES.liability.prefix);
    case CLAIM_TYPES.comprehensive.name:
      return item.name.startsWith(CLAIM_TYPES.comprehensive.prefix);
    default:
      return true;
  }
};

export interface ClaimInputProps {
  index: number;
  claim: any;
  validation: any;
  onChange: (item: any) => void;
  translatedClaimCauses?: LookupItem[];
  translatedSelfInflictedOptions?: LookupItem[];
  translatedClaimCausers?: LookupItem[];
}

export const ClaimInput: React.FC<ClaimInputProps> = (props) => {
  const { index, claim, validation, onChange } = props;
  const lookupData = useSelector(getLookupData);

  const filterClaimCauses = (claim) => {
    return lookupData.claimCauses.filter((item) =>
      filterByType(item, claim.type),
    );
  };

  return (
    <ListInputForm index={index}>
      <ClaimTypeInput
        index={index}
        value={claim.type}
        validation={validation}
        lookupData={lookupData.claimTypes}
        onChange={(value) => {
          claim.cause = defaultLookupValue;
          onChange({ name: 'type', value });
        }}
      />
      <MonthYearInput
        id={ID_CLAIM_DATE}
        propName="date"
        value={claim.date}
        onChange={onChange}
        validation={validation}
      />
      <ClaimAmountInput
        index={index}
        value={claim.amount}
        validation={validation}
        onChange={(value) =>
          onChange({
            name: ID_CLAIM_AMOUNT,
            value: value === undefined ? '' : String(value),
          })
        }
      />
      {claim.type !== null && (
        <ClaimCauseInput
          index={index}
          value={claim.cause}
          onChange={(value) => onChange({ name: 'cause', value })}
          validation={validation}
          lookupData={filterClaimCauses(claim)}
        />
      )}
      <ClaimSelfInflictedInput
        index={index}
        value={claim.selfInflicted}
        validation={validation}
        lookupData={lookupData.selfInflictedOptions}
        onChange={(value) => onChange({ name: 'selfInflicted', value })}
      />
      <ClaimCauserInput
        index={index}
        value={claim.causer}
        onChange={(value) => onChange({ name: 'causer', value })}
        validation={validation}
        lookupData={lookupData.claimCausers}
      />
    </ListInputForm>
  );
};
