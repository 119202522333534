import _result from 'lodash/result';

const tryToScrollToValidation = (ref: Element, scrollToValidation: boolean) => {
  if (ref && scrollToValidation) {
    const invalidVehicleSearch = ref.getElementsByClassName(
      'vehicle-search-text is-invalid',
    )[0];

    if (invalidVehicleSearch) {
      const closest = invalidVehicleSearch.closest('.chakra-form-control');
      _result(closest, 'scrollIntoView');

      return;
    }

    const invalidElement = Array.from(
      ref.getElementsByClassName('chakra-form__error-message'),
    ).find(({ textContent }) => textContent.length > 0);

    if (invalidElement) {
      const closest = invalidElement.closest('.chakra-form-control');
      _result(closest, 'scrollIntoView');

      return;
    }

    const invalidSelector = Array.from(
      ref.getElementsByClassName('selector-invalid'),
    ).find(({ textContent }) => textContent.length > 0);

    if (invalidSelector) {
      const closest = invalidSelector.closest('.form-group');
      _result(closest, 'scrollIntoView');
    }
  }
};

export default tryToScrollToValidation;
