import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_HAS_EXISTING_PARKING_DAMAGE } from './const';

export const ExistingParkingDamageInput: React.FC<BooleanQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_HAS_EXISTING_PARKING_DAMAGE}`,
      )}
      value={value}
      name={ID_HAS_EXISTING_PARKING_DAMAGE}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_HAS_EXISTING_PARKING_DAMAGE)}
      errorMessage={t(validation.key(ID_HAS_EXISTING_PARKING_DAMAGE))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
