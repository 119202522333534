import React from 'react';
import { QuestionStack } from '@scout24ch/fs24-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { useOnMount } from '@scout24ch/fs24-hooks';
import _set from 'lodash/set';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { CityInput } from 'components/CityInput';
import {
  STEP_DRIVER_MAIN,
  STEP_DRIVER_POLICY,
  NS_STEP_DRIVER,
  ID_GENDER,
} from 'utils/namespace-const';
import * as DriverUtils from 'utils/driverUtils';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useValidation } from 'hooks';
import { getLookupData } from 'state/LookUpData/selectors';
import { useUser } from 'hooks/useUser';
import {
  ID_DATE_OF_BIRTH,
  ID_DRIVER_DATE_OF_BIRTH,
  ID_DRIVER_GENDER,
  ID_EMAIL_ADDRESS,
} from './components/const';
import {
  DateOfBirthInput,
  DateOfDrivingLicenseInput,
  DriverDateOfBirthInput,
  DriverGenderInput,
  DriverResidencePermitInput,
  DriverResidencePermitSinceInput,
  DriversOutsideTheHouseholdInput,
  DriversUnderTheAgeOf25Input,
  DriversWithLearningOrTrialLicenseInput,
  GenderInput,
  IsMainDriverInput,
  NationalityInput,
  OtherDriversInput,
  ResidencePermitInput,
  ResidencePermitSinceInput,
  EmailAddressInput,
  DriverEmailAddressInput,
  InsuranceStartDateInput,
} from './components';
import { StepButtons } from '../StepButtons';
import { City } from '../../../../utils/types';

export interface StepDriverProps {
  nextStep: () => void;
  previousStep: () => void;
  lastStep: () => void;
  isAdjustingData: boolean;
}

export function StepDriver(props: StepDriverProps): JSX.Element {
  const { isAdjustingData, nextStep, previousStep, lastStep } = props;
  const lookupData = useSelector(getLookupData);
  const { mainDriver, policyHolder, insuranceOptions } =
    useSelector(getInquiryInputs);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const validation = useValidation(NS_STEP_DRIVER, getInquiryValidation);
  const { user } = useUser();
  const dispatch = useDispatch();

  useOnMount(() => {
    if (user) {
      onChange(ID_EMAIL_ADDRESS, user.email);
    }
  });

  const onChange = (name: string, value: any) => {
    _set({ mainDriver, policyHolder }, name, value);

    DriverUtils.tryClearResidencePermit(
      mainDriver,
      STEP_DRIVER_MAIN,
      name,
      value,
    );
    DriverUtils.tryClearResidencePermit(
      policyHolder,
      STEP_DRIVER_POLICY,
      name,
      value,
    );

    dispatch(inquiryInputChanged(mainDriver, STEP_DRIVER_MAIN));
    dispatch(inquiryInputChanged(policyHolder, STEP_DRIVER_POLICY));
  };

  const policyHolderInputs = () => {
    const field = mainDriver.isPolicyHolder ? mainDriver : policyHolder;

    return (
      <>
        <IsMainDriverInput
          value={mainDriver.isPolicyHolder}
          validation={validation}
          onChange={(value) => onChange('mainDriver.isPolicyHolder', value)}
        />
        {mainDriver.isPolicyHolder === false && (
          <>
            <DriverGenderInput
              value={field.gender}
              validation={validation}
              lookupData={lookupData.genders}
              onChange={(value) => onChange(ID_DRIVER_GENDER, value)}
            />
            <DriverDateOfBirthInput
              value={field.dateOfBirth}
              validation={validation}
              onChange={(value) => onChange(ID_DRIVER_DATE_OF_BIRTH, value)}
            />
            <CityInput
              value={field.postCode as number}
              city={field.city as City}
              onChange={(obj) => onChange(obj.name, obj.value)}
              validation={validation}
              path={STEP_DRIVER_POLICY}
            />
            <NationalityInput
              name="policyHolder.nationality"
              value={field.nationality}
              validation={validation}
              lookupData={lookupData.countries}
              onChange={(value) => onChange('policyHolder.nationality', value)}
            />
            {!['CH', 'None'].includes(field.nationality.name) && (
              <>
                <DriverResidencePermitInput
                  value={field.residencePermit}
                  validation={validation}
                  lookupData={lookupData.residencePermits}
                  onChange={(value) =>
                    onChange('policyHolder.residencePermit', value)
                  }
                />
                <DriverResidencePermitSinceInput
                  value={field.residencePermitSince as string}
                  validation={validation}
                  onChange={(value) =>
                    onChange('policyHolder.residencePermitSince', value)
                  }
                />
              </>
            )}
            <DriverEmailAddressInput
              value={field.emailAddress}
              validation={validation}
              onChange={(value) => onChange('policyHolder.emailAddress', value)}
            />
          </>
        )}
      </>
    );
  };

  const additionalDriverInputs = () => {
    const { driverDetails } = mainDriver;

    return (
      <>
        <OtherDriversInput
          value={driverDetails.otherDrivers}
          validation={validation}
          onChange={(value) =>
            onChange('mainDriver.driverDetails.otherDrivers', value)
          }
        />
        {driverDetails.otherDrivers === true && (
          <>
            <DriversOutsideTheHouseholdInput
              value={driverDetails.driversOutsideTheHousehold}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversOutsideTheHousehold',
                  value,
                )
              }
            />
            <DriversWithLearningOrTrialLicenseInput
              value={driverDetails.driversWithLearningOrTrialLicense}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversWithLearningOrTrialLicense',
                  value,
                )
              }
            />
            <DriversUnderTheAgeOf25Input
              value={driverDetails.driversUnderTheAgeOf25}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversUnderTheAgeOf25',
                  value,
                )
              }
            />
          </>
        )}
      </>
    );
  };

  return (
    <QuestionStack as="form">
      <GenderInput
        value={mainDriver.gender}
        onChange={(value) => onChange(ID_GENDER, value)}
        validation={validation}
        lookupData={lookupData.genders}
      />
      <DateOfBirthInput
        value={mainDriver.dateOfBirth}
        validation={validation}
        onChange={(value) => onChange(ID_DATE_OF_BIRTH, value)}
      />
      <DateOfDrivingLicenseInput
        value={mainDriver.dateOfDrivingLicense as string}
        validation={validation}
        onChange={(value) => onChange('mainDriver.dateOfDrivingLicense', value)}
      />
      <NationalityInput
        name="mainDriver.nationality"
        value={mainDriver.nationality}
        validation={validation}
        lookupData={lookupData.countries}
        onChange={(value) => onChange('mainDriver.nationality', value)}
      />
      {!['CH', 'None'].includes(mainDriver.nationality.name) && (
        <>
          <ResidencePermitInput
            value={mainDriver.residencePermit}
            validation={validation}
            lookupData={lookupData.residencePermits}
            onChange={(value) => onChange('mainDriver.residencePermit', value)}
          />
          <ResidencePermitSinceInput
            value={mainDriver.residencePermitSince as string}
            validation={validation}
            onChange={(value) =>
              onChange('mainDriver.residencePermitSince', value)
            }
          />
        </>
      )}
      <CityInput
        value={mainDriver.postCode as number}
        city={mainDriver.city as City}
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
        path={STEP_DRIVER_MAIN}
      />
      {user == null && (
        <EmailAddressInput
          value={mainDriver.emailAddress}
          validation={validation}
          onChange={(value) => onChange('mainDriver.emailAddress', value)}
        />
      )}
      {policyHolderInputs()}
      {additionalDriverInputs()}
      <InsuranceStartDateInput
        value={insuranceOptions.insuranceStartDate as string}
        onChange={(value) => {
          insuranceOptions.insuranceStartDate = value;
          dispatch(inquiryInputChanged(insuranceOptions, null));
        }}
        validation={validation}
      />
      <StepButtons
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
}
