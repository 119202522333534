import { Moment } from 'moment';
import React from 'react';
import AutoSuggest, {
  ChangeEvent,
  InputProps,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { useTranslation } from 'react-i18next';
import { fs24, Question, Text } from '@scout24ch/fs24-design-system';
import { VehicleTypeSuggestion } from 'containers/InquiryPage/components/StepVehicle/types';
import { IEvaluatedValidation } from 'utils/validation/types';
import { ID_VEHICLE_SEARCH_TEXT } from '../const';
import { NS_STEP_VEHICLE } from '../../../../../../utils/namespace-const';

interface VehicleSearchInputProps {
  validation: IEvaluatedValidation;
  value: string;
  isVehicleSelected: boolean;
  onChange(value: string): void;
  onSuggestionSelected(suggestion: VehicleTypeSuggestion): void;
  search(
    searchTerm: string,
  ): Promise<Omit<VehicleTypeSuggestion, 'firstRegistrationDates'>[]>;
}

export const getProductionYearRange = (
  firstRegistrationDates: Moment[],
): string => {
  if (!firstRegistrationDates?.length) {
    return '';
  }

  const yearStart =
    firstRegistrationDates[firstRegistrationDates.length - 1].year();
  const yearEnd = firstRegistrationDates[0].year();
  return `(${yearStart} - ${yearEnd})`;
};

const VehicleSearchInput = (props: VehicleSearchInputProps) => {
  const {
    validation,
    value,
    isVehicleSelected,
    search,
    onChange,
    onSuggestionSelected,
  } = props;

  const { t } = useTranslation();
  const [suggestions, setSuggestions] = React.useState([]);

  const handleSearch = async (term: string) => {
    try {
      const vehicleTypes: Omit<
        VehicleTypeSuggestion,
        'firstRegistrationDates'
      >[] = await search(term);

      setSuggestions(vehicleTypes);
    } catch (error) {
      clearSuggestions();
    }
  };

  const vehicleValidationKey = () => {
    return !value ? 'notFound' : 'empty';
  };

  const handleChange = (_: any, { newValue }: ChangeEvent) => {
    onChange(newValue);
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const onSuggestionFetchRequested = async ({
    value,
  }: SuggestionsFetchRequestedParams) => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      clearSuggestions();
      return;
    }

    await handleSearch(inputValue);
  };

  const handleOnSuggestionSelected = async (
    _: any,
    eventData: SuggestionSelectedEventData<VehicleTypeSuggestion>,
  ) => {
    _.preventDefault();

    const typeName = getSuggestionValue(eventData.suggestion);
    onChange(typeName);
    onSuggestionSelected(eventData.suggestion);
  };

  const getSuggestionValue = (suggestion: VehicleTypeSuggestion): string => {
    const productionYearRange = getProductionYearRange(
      suggestion.firstRegistrationDates,
    );
    return `${suggestion.vehicleType} ${productionYearRange}`;
  };

  // Ignore type check for inputProps because of this issue: https://github.com/moroshko/react-autosuggest/issues/636
  // type of onChange is different for HTML input and AutoSuggest input props.
  const renderInput = (inputProps: any) => {
    const isInvalid = validation.isInvalid(ID_VEHICLE_SEARCH_TEXT);

    return (
      <fs24.input
        {...inputProps}
        data-hj-whitelist
        id={ID_VEHICLE_SEARCH_TEXT}
        fontSize="md"
        h={15}
        borderRadius="md"
        border="1px"
        borderColor={isInvalid ? 'red.500' : 'grey.900'}
        bgColor={isInvalid ? 'red.50' : 'white'}
        px={3}
        w="full"
      />
    );
  };

  const renderSuggestion = (suggestion: VehicleTypeSuggestion) => (
    <div>{getSuggestionValue(suggestion)}</div>
  );

  const inputProps: InputProps<VehicleTypeSuggestion> = {
    value: value || '',
    placeholder: t(`${NS_STEP_VEHICLE}.${ID_VEHICLE_SEARCH_TEXT}-placeholder`),
    onChange: handleChange,
  };

  const isInvalid = validation.isInvalid(ID_VEHICLE_SEARCH_TEXT);

  return (
    <Question
      name={ID_VEHICLE_SEARCH_TEXT}
      label={t(`${NS_STEP_VEHICLE}.${ID_VEHICLE_SEARCH_TEXT}`)}
      showFeedback={isVehicleSelected}
      sx={{
        '.react-autosuggest__container': {
          position: 'relative',
        },
        '.react-autosuggest__input--focused': {
          outline: 'none',
        },

        '.react-autosuggest__suggestions-container': {
          display: 'none',
          maxHeight: ' 300px',
          overflowY: 'auto',
        },

        '.react-autosuggest__suggestions-container--open': {
          display: 'block',
          position: 'absolute',
          top: '62px',
          width: '100%',
          borderRadius: '4px',
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.12)',
          backgroundColor: 'white',
          fontWeight: 300,
          fontSize: 'sm',
          zIndex: 100,
        },

        '.react-autosuggest__suggestions-list': {
          margin: 0,
          padding: 0,
          listStyleType: 'none',
        },

        '.react-autosuggest__suggestion': {
          cursor: 'pointer',
          padding: '10px 20px',
        },

        '.react-autosuggest__suggestion--highlighted': {
          backgroundColor: 'rgba(17, 117, 217, 0.06)',
        },
      }}
    >
      <AutoSuggest
        suggestions={suggestions}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={onSuggestionFetchRequested}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleOnSuggestionSelected}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInput}
      />

      {isInvalid && (
        <Text
          mt={2}
          lineHeight={140}
          color="info.red"
          fontSize="xs"
          className="chakra-form__error-message"
        >
          {t(
            `${NS_STEP_VEHICLE}.${ID_VEHICLE_SEARCH_TEXT}-${vehicleValidationKey()}`,
          )}
        </Text>
      )}
    </Question>
  );
};

export default VehicleSearchInput;
