import { Icon } from '@scout24ch/fs24-design-system';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { changeStepWithValidation } from 'state/InquiryInput/actions';
import { PARAM_VEHICLE_ID } from 'utils/queryparam-const';
import { getQueryParam } from 'utils/routerUtil';
import { goToFinanceScout } from 'utils/navigationUtil';
import { NS_STEP_COMMON, STEP_VEHICLE } from 'utils/namespace-const';
import { getInquiryStep } from 'state/InquiryInput/selectors';
import { INSURANCE_INQUIRY_STEPS } from '../../../../../utils/const';
import { NavigationBar } from '../../../../../components/NavigationBar';
import { ArrowLeft } from '../../../../../Icon';

export const BackButton = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const step = useSelector(getInquiryStep);
  const vehicleId = getQueryParam(router, PARAM_VEHICLE_ID);

  const handleBackClick = () => {
    if (step.name === STEP_VEHICLE.name) {
      goToFinanceScout(router, vehicleId);
      return;
    }

    const nextStep = INSURANCE_INQUIRY_STEPS.find(
      ({ index }) => index === step.index - 1,
    );

    dispatch(changeStepWithValidation(nextStep, step));
  };

  return (
    <NavigationBar
      title={`${NS_STEP_COMMON}.previous`}
      icon={<Icon w={6} h={6} as={ArrowLeft} />}
      whiteBg
      callback={handleBackClick}
    />
  );
};
