import { useSelector } from 'react-redux';
import { getVehicle } from 'state/InquiryInput/selectors';
import { useEffect, useState } from 'react';

export function useAS24PerfectCarMatch() {
  const vehicle = useSelector(getVehicle);
  const [showAS24VehicleInfoCard, setShowAS24VehicleInfoCard] = useState<
    boolean | undefined
  >(undefined);
  const { eurotaxId, as24VehicleId, eurotaxMatches } = vehicle;

  const isAS24Vehicle = Boolean(as24VehicleId);
  const isPerfectMatch = Boolean(eurotaxId && eurotaxMatches?.length === 1);

  useEffect(() => {
    setShowAS24VehicleInfoCard(isAS24Vehicle);
  }, [isAS24Vehicle]);

  return {
    isPerfectMatch,
    showAS24VehicleInfoCard,
    setShowAS24VehicleInfoCard,
  };
}
