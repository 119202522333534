import { captureException } from '@sentry/browser';
import { searchVehicles } from '../../../../../../api/vehicles';
import {
  UsageDetails,
  Vehicle,
} from '../../../../../../state/InquiryInput/types';
import { CarBrands, MotoBrands } from '../../../../../../utils/types';
import { VehicleSuggestion } from '../../types';

function padMonth(month: number) {
  return month ? month.toString().padStart(2, '0') : '01';
}

function filterOutBrandName(modelName: string) {
  // since we do not call eurotax api we get different values for model name now
  // which also include brand name, so we need to filter it out
  for (const key in CarBrands) {
    if (modelName.includes(key)) {
      return modelName.replace(new RegExp(`${key} `), '');
    }
  }

  for (const key in MotoBrands) {
    if (modelName.includes(key)) {
      return modelName.replace(new RegExp(`${key} `), '');
    }
  }

  return modelName;
}

const extractVehicleByTypeCertificate = async (
  makeName: string,
  vehicleType: string,
  firstRegistrationMonth: number,
  firstRegistrationYear: number,
  euroTaxId: number,
  typeCertificate: string,
) => {
  const vehicles = await searchVehicles(
    makeName,
    vehicleType,
    firstRegistrationMonth,
    firstRegistrationYear,
    euroTaxId,
  );

  if (vehicles.length === 1) {
    return vehicles[0];
  }

  const vehicleByEurotaxId = vehicles.find((model) => model.id === euroTaxId);

  if (vehicleByEurotaxId) {
    return vehicleByEurotaxId;
  }

  return vehicles.find((model) =>
    model.certificationCodes.includes(typeCertificate),
  );
};

export const formatSelectedVehicle = async (
  euroTaxId: number,
  makeName: string,
  mileage: number,
  typeName: string = null,
  vehicles: VehicleSuggestion[],
  vehicle: Vehicle,
  usageDetails: UsageDetails,
  vehicleType: string,
  typeCertificate?: string,
  firstRegistrationMonth?: number,
  firstRegistrationYear?: number,
) => {
  const selectedVehicle = typeCertificate
    ? await extractVehicleByTypeCertificate(
        vehicle.makeName ?? makeName,
        vehicleType,
        usageDetails.firstRegistrationMonth ?? vehicle.firstRegMonth,
        usageDetails.firstRegistrationYear ?? vehicle.firstRegYear,
        euroTaxId,
        typeCertificate,
      )
    : vehicles.find((vehicle) => vehicle.id === euroTaxId);

  if (!selectedVehicle) {
    captureException(selectedVehicle, {
      extra: {
        typeCertificate,
        euroTaxId,
        vehicles: JSON.stringify(vehicles),
      },
    });
  }

  const {
    id,
    bodyType,
    doors,
    engineHorsePower,
    make,
    model,
    year,
    name,
    priceProvider,
    transmissionType,
  } = selectedVehicle ?? {};

  vehicle.makeName = makeName ?? make;
  vehicle.eurotaxId = id ?? vehicle.eurotaxId;
  vehicle.modelName = model ?? vehicle.modelName;
  vehicle.type = bodyType ?? vehicle.type;
  vehicle.enginePowerHp = engineHorsePower ?? vehicle.enginePowerHp;
  vehicle.doors = doors ?? vehicle.doors;
  vehicle.dateOfFirstRegistration =
    vehicle.dateOfFirstRegistration ??
    `${
      firstRegistrationYear ??
      usageDetails.firstRegistrationYear ??
      vehicle.firstRegYear
    }-${
      padMonth(firstRegistrationMonth ?? usageDetails.firstRegistrationMonth) ??
      padMonth(vehicle.firstRegMonth)
    }-01T00:00:00`;
  vehicle.price = Math.round(priceProvider) ?? Math.round(vehicle.price);
  vehicle.typeName =
    filterOutBrandName(name ?? '') ?? vehicle.typeName ?? typeName;
  vehicle.modelYear = year ?? vehicle.modelYear;
  vehicle.firstRegMonth =
    firstRegistrationMonth ??
    usageDetails.firstRegistrationMonth ??
    vehicle.firstRegMonth;
  vehicle.firstRegYear =
    firstRegistrationYear ??
    usageDetails.firstRegistrationYear ??
    vehicle.firstRegYear;
  vehicle.typeNameSelected = true;
  vehicle.mileage = mileage ?? vehicle.mileage;
  vehicle.priceOfAccessories = priceProvider
    ? Math.round((priceProvider / 100) * 10)
    : vehicle.priceOfAccessories ?? null;
  vehicle.transmissionType = transmissionType ?? vehicle.transmissionType;

  return vehicle;
};
