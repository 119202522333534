import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailIllustration from '@scout24ch/fs24-assets/img/illustrations/functional/Email_2.svg';
import {
  Button,
  QuestionStack,
  SubmitButton,
  TextQuestion,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  fs24,
  Heading,
} from '@scout24ch/fs24-design-system';
import { SaveSearch } from '@scout24ch/fs24-design-system/icons/24';
import { useAuth0 } from '@scout24ch/fs24-auth-react';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import {
  ACTION_SHARE,
  ACTION_SHARE_SEND_MAIL,
  CATEGORY_INSURANCE_COMPARISON,
} from 'utils/GTM/events';
import { NS_PREMIUM_COMMON } from 'utils/namespace-const';
import { validateEmail } from '../../utils/validation';

interface ShareButtonProps {
  isSimpleButton?: boolean;
  isInverseButton?: boolean;
  trackingLabel?: string;
  shareFn(email?: string): void;
  enterFullscreen?: () => void;
  openModal?: () => void;
  leaveFullscreen?: () => void;
  closeModal?: () => void;
}

const ShareButton = (props: ShareButtonProps) => {
  const { isSimpleButton, isInverseButton, trackingLabel, shareFn } = props;
  const { t } = useTranslation();
  const { user } = useAuth0();
  const inquiry = useSelector((state: any) => state.inquiryInput.inputs);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState(
    user?.email ?? inquiry?.mainDriver?.emailAddress ?? '',
  );
  const [isValid, setIsValid] = React.useState(true);
  const gtm = useGoogleTagManager();

  const sendEmail = async () => {
    const trackingData: {
      eventCategory: string;
      eventAction: string;
      eventLabel?: string;
    } = {
      eventCategory: CATEGORY_INSURANCE_COMPARISON,
      eventAction: ACTION_SHARE_SEND_MAIL,
    };

    if (trackingLabel) {
      trackingData.eventLabel = trackingLabel;
    }

    const isInvalid = await validateEmail(email);
    setIsValid(isInvalid ? false : true);

    if (isInvalid) {
      return;
    }

    setIsModalOpen(false);
    gtm.push(trackingData);
    shareFn(email);
  };

  function handleSubmit(event: React.FormEvent<HTMLDivElement>) {
    event.preventDefault();

    sendEmail();
  }

  function handleEmailChange(value: string) {
    setEmail(value);
  }

  function onOpenModal() {
    if (user) {
      setEmail(user.email);
      sendEmail();

      return;
    }

    gtm.push({
      eventCategory: CATEGORY_INSURANCE_COMPARISON,
      eventAction: ACTION_SHARE,
    });

    setIsModalOpen(true);
  }

  function renderShareButton() {
    if (isSimpleButton || isInverseButton) {
      return (
        <Button variant="ghost" onClick={onOpenModal}>
          {t(`${NS_PREMIUM_COMMON}.save`)}
        </Button>
      );
    }

    return (
      <Button
        variant="link"
        minH="none"
        leftIcon={<SaveSearch />}
        minW={0}
        sx={{
          '.chakra-button__icon': {
            mr: [0, 2],
          },
        }}
        onClick={onOpenModal}
      >
        <span>{t(`${NS_PREMIUM_COMMON}.share.icon-title`)}</span>
      </Button>
    );
  }

  return (
    <>
      {renderShareButton()}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent pt={[8, null, 16]}>
          <ModalCloseButton />
          <ModalBody w="full" pb={12} px={[6, 6, 10]}>
            <div>
              <fs24.div display="flex" justifyContent="center" mb={6}>
                <fs24.img
                  src={EmailIllustration.src}
                  width={EmailIllustration.width}
                  maxW={['120px', null, 'none']}
                  height="auto"
                />
              </fs24.div>
              <Heading as="h3" fontSize="lg" fontWeight="bold" mb={4}>
                {t(`${NS_PREMIUM_COMMON}.share.title`)}
              </Heading>
              <fs24.p mb={6}>{t(`${NS_PREMIUM_COMMON}.share.text`)}</fs24.p>
              <QuestionStack as="form" onSubmit={handleSubmit}>
                <TextQuestion
                  label={t(`${NS_PREMIUM_COMMON}.share.input-title`)}
                  name="share-email"
                  value={email}
                  onValueChange={handleEmailChange}
                  errorMessage={
                    !isValid
                      ? t(`${NS_PREMIUM_COMMON}.share.input-invalid`)
                      : ''
                  }
                />
                <SubmitButton variant="secondary">
                  {t(`${NS_PREMIUM_COMMON}.share.button`)}
                </SubmitButton>
              </QuestionStack>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
