import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_OTHER_DRIVERS } from './const';

export const OtherDriversInput: React.FC<BooleanQuestionProps> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_OTHER_DRIVERS}`)}
      value={value}
      name={ID_OTHER_DRIVERS}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_OTHER_DRIVERS)}
      errorMessage={t(validation.key(ID_OTHER_DRIVERS))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
