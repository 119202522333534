import React from 'react';
import { BooleanQuestion, Checkbox, fs24 } from '@scout24ch/fs24-design-system';
import { isEmptyArray } from '@scout24ch/fs24-utils';
import { useTranslation } from 'hooks';

import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { BooleanQuestionProps } from 'interfaces';
import {
  claimDefaults,
  infringementDefaults,
  suspensionOfDrivingLicenseDefaults,
} from '../helpers/incidentsDefaults';
import {
  ID_ADDITIONAL_QUESTIONS,
  ID_IS_ADDITIONAL_QUESTIONS,
  ID_HAS_CLAIMS,
  ID_HAS_INFRINGEMENTS,
  ID_HAS_SUSPENSION_OF_DRIVING_LICENSE,
} from './const';
import { Claim, Infringement } from '../types';

type AdditionalQuestionsInputProps = {
  isAdditionalQuestions: boolean;
  claims: Claim[];
  infringements: Infringement[];
  suspensionOfDrivingLicense: any[] | null;
  onChange(obj: Record<string, any>): void;
} & Omit<BooleanQuestionProps, 'value'>;

export const AdditionalQuestionsInput = (
  props: AdditionalQuestionsInputProps,
) => {
  const { t } = useTranslation();
  const {
    isAdditionalQuestions = null,
    claims = [],
    infringements = [],
    suspensionOfDrivingLicense = [],
    onChange,
    validation,
  } = props;

  return (
    <>
      <BooleanQuestion
        label={t(`${NS_STEP_INCIDENTS}.${ID_ADDITIONAL_QUESTIONS}`)}
        value={isAdditionalQuestions}
        name={ID_IS_ADDITIONAL_QUESTIONS}
        onChange={(value) => onChange({ name: 'isAdditionalQuestions', value })}
        onBlur={() => validation.isInvalid(ID_IS_ADDITIONAL_QUESTIONS)}
        errorMessage={t(validation.key(ID_IS_ADDITIONAL_QUESTIONS))}
        showFeedback={typeof isAdditionalQuestions === 'boolean'}
      />
      {isAdditionalQuestions && (
        <div>
          <fs24.div mb={3}>
            <Checkbox
              name={ID_HAS_CLAIMS}
              id={ID_HAS_CLAIMS}
              isChecked={!isEmptyArray(claims)}
              onChange={() =>
                onChange({
                  name: 'claims',
                  value: !isEmptyArray(claims) ? [] : [claimDefaults()],
                })
              }
            >
              {t(`${NS_STEP_INCIDENTS}.${ID_HAS_CLAIMS}`)}
            </Checkbox>
          </fs24.div>
          <fs24.div mb={3}>
            <Checkbox
              name={ID_HAS_INFRINGEMENTS}
              id={ID_HAS_INFRINGEMENTS}
              isChecked={!isEmptyArray(infringements)}
              onChange={() =>
                onChange({
                  name: 'infringements',
                  value: !isEmptyArray(infringements)
                    ? []
                    : [infringementDefaults()],
                })
              }
            >
              {t(`${NS_STEP_INCIDENTS}.${ID_HAS_INFRINGEMENTS}`)}
            </Checkbox>
          </fs24.div>
          <fs24.div mb={3}>
            <Checkbox
              name={ID_HAS_SUSPENSION_OF_DRIVING_LICENSE}
              id={ID_HAS_SUSPENSION_OF_DRIVING_LICENSE}
              isChecked={!isEmptyArray(suspensionOfDrivingLicense)}
              onChange={() =>
                onChange({
                  name: 'suspensionOfDrivingLicense',
                  value: !isEmptyArray(suspensionOfDrivingLicense)
                    ? []
                    : [suspensionOfDrivingLicenseDefaults()],
                })
              }
            >
              {t(
                `${NS_STEP_INCIDENTS}.${ID_HAS_SUSPENSION_OF_DRIVING_LICENSE}`,
              )}
            </Checkbox>
          </fs24.div>
        </div>
      )}
    </>
  );
};
