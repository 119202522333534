import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_INSURANCE_OPTIONS } from 'utils/namespace-const';
import { BooleanQuestionProps } from 'interfaces';
import { ID_IS_BONUS_PROTECTION } from './const';

export const BonusProtectionInput: React.FC<BooleanQuestionProps> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_BONUS_PROTECTION}`)}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_BONUS_PROTECTION}-info`,
      )}
      value={value}
      name={ID_IS_BONUS_PROTECTION}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_IS_BONUS_PROTECTION)}
      errorMessage={t(validation.key(ID_IS_BONUS_PROTECTION))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
