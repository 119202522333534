import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE } from './const';

export const AccidentInsuranceDailyAllowanceInput: React.FC<
  RadioGroupQuestionProps
> = (props) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((i) => ({
    value: i,
    label: t(`${NS_STEP_INSURANCE_OPTIONS}.allowance-${i.name.toLowerCase()}`),
  }));

  return (
    <RadioGroupQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE}`,
      )}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE}-info`,
      )}
      name={ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE)}
      errorMessage={t(validation.key(ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE))}
      showFeedback={!!value?.key}
    />
  );
};
