import React from 'react';
import { IEvaluatedValidation } from 'utils/validation/types';
import { YearMonthInput } from './YearMonthInput';

export interface FirstRegistrationDateInputProps {
  month: number;
  year: number;
  validation: IEvaluatedValidation;
  onYearChange(year: number): void;
  onMonthChange(month: number): void;
}

export const FirstRegistrationDateInput: React.FC<
  FirstRegistrationDateInputProps
> = (props) => {
  const { month, year, validation, onYearChange, onMonthChange } = props;

  return (
    <YearMonthInput
      month={month}
      year={year}
      onYearChange={onYearChange}
      onMonthChange={onMonthChange}
      validation={validation}
    />
  );
};
