import React from 'react';
import { StepVehicle, StepMotoVehicle } from '.';

export const createStepVehicle = (
  nextStep: () => void,
  previousStep: () => void,
  isAutoInsurance: boolean,
  lastStep: () => void,
  isAdjustingData: boolean,
  vehicleId: number,
  abredirect: string, // TODO: remove after AGGR-4752 is done (FeatureFlag.PortalAS24VehicleRedirect)
): JSX.Element => {
  if (isAutoInsurance) {
    return (
      <StepVehicle
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        vehicleId={vehicleId}
        abredirect={abredirect} // TODO: remove after AGGR-4752 is done (FeatureFlag.PortalAS24VehicleRedirect)
      />
    );
  }
  return (
    <StepMotoVehicle
      nextStep={nextStep}
      previousStep={previousStep}
      lastStep={lastStep}
      isAdjustingData={isAdjustingData}
      vehicleId={vehicleId}
    />
  );
};
