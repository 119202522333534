import { Flex, Text } from '@scout24ch/fs24-design-system';
import { Lock } from '@scout24ch/fs24-design-system/icons/24';
import React from 'react';
import { useTranslation } from 'hooks';
import { NS_STEP_COMMON } from 'utils/namespace-const';

export function DataProtectionSeal(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" justifyContent="center" pt={1} fill="grey.700">
      <Lock height="20px" fill="grey.700" />
      <Text color="grey.700" fontSize="xs" ml={1}>
        {t(`${NS_STEP_COMMON}.protection-seal`)}
      </Text>
    </Flex>
  );
}
