import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ButtonProps,
} from '@scout24ch/fs24-design-system';
import { validateEmail } from '@scout24ch/fs24-forms';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { useTranslation } from 'hooks';
import { NS_STEP_COMMON, NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { getEuroTaxId, getInquiryInputs } from 'state/selectors';
import { useIsMobile } from 'utils/responsive-const';
import { DataProtectionSeal } from '../../../../components/DataProtectionSeal/DataProtectionSeal';
interface StepButtonsProps extends ButtonProps {
  nextStepText?: string;
  nextStep: () => void;
  previousStep: () => void;
  lastStep?: () => void;
  isAdjustingData?: boolean;
  isInquiryPageValid: boolean;
}

export const StepButtons: React.FC<StepButtonsProps> = (props) => {
  const {
    isAdjustingData,
    isInquiryPageValid,
    nextStepText,
    previousStep,
    lastStep,
    nextStep,
  } = props;

  const { usageDetails, mainDriver } = useSelector(getInquiryInputs);
  const eurotaxId = useSelector(getEuroTaxId);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const showFinishButton =
    !validateEmail(mainDriver.emailAddress) &&
    isAdjustingData &&
    lastStep !== null &&
    isInquiryPageValid &&
    (parseInt(String(usageDetails.mileage), 10) >= 0 || !IS_AUTO_INSURANCE) &&
    eurotaxId;

  return (
    <ButtonGroup mb={10}>
      <div id="lastStepButton-container">
        {showFinishButton && (
          <Button width="full" variant="primary" onClick={lastStep}>
            {t(`${NS_STEP_INCIDENTS}.button-next`)}
          </Button>
        )}
      </div>
      <Button
        variant={showFinishButton ? 'secondary' : 'primary'}
        onClick={nextStep}
      >
        {nextStepText || t(`${NS_STEP_COMMON}.next`)}
      </Button>
      {isMobile && (
        <Button onClick={previousStep} variant={'ghost'}>
          {t(`${NS_STEP_COMMON}.previous`)}
        </Button>
      )}
      <DataProtectionSeal />
    </ButtonGroup>
  );
};
