import { captureException } from '@sentry/node';
import axios, { AxiosResponse } from 'axios';
import { City } from '../../state/InquiryInput/types';
import { APPLICATION_HOST } from '../../utils/const';
import { cloudFlareInterceptor } from '../utils/cloudFlareInterceptor';

export const apiBaseURL =
  process.env.NODE_ENV === 'production'
    ? `${APPLICATION_HOST}/api/location-service/api/v1`
    : process.env.LOCATION_SERVICE_URL;

export const locationService = axios.create({
  baseURL: apiBaseURL,
});

locationService.interceptors.request.use(cloudFlareInterceptor);

export interface CityResponse {
  canton: string;
  bfsNumber: number;
  name: string;
  zipCode: number;
  zipCodeAddition: string;
}

export const getCity = async (postCode: number): Promise<City[]> => {
  try {
    const response: AxiosResponse<CityResponse[]> = await locationService.get(
      `/cities/${postCode}`,
    );

    return response.data?.map((city) => ({
      name: city.name,
      postCode: city.zipCode,
      bfsNumber: city.bfsNumber,
      canton: city.canton,
    }));
  } catch (error) {
    captureException(error);
    return null;
  }
};
