import React, { useCallback } from 'react';
import { QuestionStack } from '@scout24ch/fs24-design-system';
import { Rule } from 'json-rules-engine';
import _set from 'lodash/set';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { CityInput } from 'components/CityInput';
import * as DriverUtils from 'utils/driverUtils';
import {
  NS_STEP_DRIVER,
  STEP_DRIVER_MAIN,
  STEP_DRIVER_POLICY,
} from 'utils/namespace-const';
import {
  getInquiry,
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useValidation } from 'hooks';
import { getLookupData } from 'state/LookUpData/selectors';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useUser } from 'hooks/useUser';
import { StepButtons } from '../StepButtons';
import {
  DateOfBirthInput,
  DateOfDrivingLicenseInput,
  DateOfMotoDrivingLicenseInput,
  DriverDateOfBirthInput,
  DriverEmailAddressInput,
  DriverGenderInput,
  DriverResidencePermitInput,
  DriverResidencePermitSinceInput,
  DriversOutsideTheHouseholdInput,
  DriversUnderTheAgeOf25Input,
  DriversWithLearningOrTrialLicenseInput,
  EmailAddressInput,
  GenderInput,
  HasAutoDrivingLicenseInput,
  InsuranceStartDateInput,
  IsMainDriverInput,
  NationalityInput,
  OtherDriversInput,
  ResidencePermitInput,
  ResidencePermitSinceInput,
} from './components';
import {
  ID_DATE_OF_BIRTH,
  ID_DATE_OF_MOTO_DRIVING_LICENSE,
  ID_DRIVER_DATE_OF_BIRTH,
  ID_DRIVER_GENDER,
  ID_EMAIL_ADDRESS,
  ID_GENDER,
  ID_HAS_AUTO_DRIVING_LICENSE,
} from './components/const';
import motoRules from './helpers/motoRules';
import autoRules from './helpers/rules';
import { motoEngine } from './helpers/motoDriverValidation';
import { StepDriverProps } from './StepDriver';
import { City } from '../../../../utils/types';

const dateOfBirthGreaterThan16 = new Rule(motoRules.dateOfBirthGreaterThan16);
const dateOfBirthGreaterThan = new Rule(autoRules.dateOfBirthGreaterThan);
const driverDateOfBirthGreaterThan = new Rule(
  autoRules.driverDateOfBirthGreaterThan,
);
const driverDateOfBirthGreaterThan16 = new Rule(
  motoRules.driverDateOfBirthGreaterThan16,
);

export const StepMotoDriver: React.FC<StepDriverProps> = (props) => {
  const { isAdjustingData, nextStep, previousStep, lastStep } = props;
  const { mainDriver, policyHolder, insuranceOptions } =
    useSelector(getInquiryInputs);
  const lookupData = useSelector(getLookupData);
  const validation = useValidation(NS_STEP_DRIVER, getInquiryValidation);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const { vehicle } = useSelector(getInquiry).inputs;
  const { user } = useUser();
  const dispatch = useDispatch();

  const onChange = useCallback(
    (name: string, value: any) => {
      _set({ mainDriver, policyHolder }, name, value);

      DriverUtils.tryClearResidencePermit(
        mainDriver,
        STEP_DRIVER_MAIN,
        name,
        value,
      );
      DriverUtils.tryClearResidencePermit(
        policyHolder,
        STEP_DRIVER_POLICY,
        name,
        value,
      );

      if (name === ID_HAS_AUTO_DRIVING_LICENSE && value === false) {
        delete mainDriver.dateOfDrivingLicense;
      }

      dispatch(inquiryInputChanged(mainDriver, STEP_DRIVER_MAIN));
      dispatch(inquiryInputChanged(policyHolder, STEP_DRIVER_POLICY));
    },
    [dispatch, mainDriver, policyHolder],
  );

  React.useEffect(() => {
    if (vehicle.capacityCcm < 126) {
      motoEngine.addRule(dateOfBirthGreaterThan16);
      motoEngine.addRule(driverDateOfBirthGreaterThan16);
    } else {
      motoEngine.addRule(dateOfBirthGreaterThan);
      motoEngine.addRule(driverDateOfBirthGreaterThan);
    }

    return () => {
      if (vehicle.capacityCcm < 126) {
        motoEngine.addRule(dateOfBirthGreaterThan16);
      }
    };
  }, [vehicle.capacityCcm]);

  useOnMount(() => {
    if (user) {
      onChange(ID_EMAIL_ADDRESS, user.email);
    }
  });

  const policyHolderInputs = () => {
    const field = mainDriver.isPolicyHolder ? mainDriver : policyHolder;

    return (
      <React.Fragment>
        <IsMainDriverInput
          value={mainDriver.isPolicyHolder}
          validation={validation}
          onChange={(value) => onChange('mainDriver.isPolicyHolder', value)}
        />
        {mainDriver.isPolicyHolder === false && (
          <>
            <DriverGenderInput
              value={field.gender}
              validation={validation}
              lookupData={lookupData.genders}
              onChange={(value) => onChange(ID_DRIVER_GENDER, value)}
            />
            <DriverDateOfBirthInput
              value={field.dateOfBirth}
              validation={validation}
              onChange={(value) => onChange(ID_DRIVER_DATE_OF_BIRTH, value)}
            />
            <CityInput
              value={field.postCode as number}
              city={field.city as City}
              onChange={(obj) => onChange(obj.name, obj.value)}
              validation={validation}
              path={STEP_DRIVER_POLICY}
            />
            <NationalityInput
              name="policyHolder.nationality"
              value={field.nationality}
              validation={validation}
              lookupData={lookupData.countries}
              onChange={(value) => onChange('policyHolder.nationality', value)}
            />
            {mainDriver.isPolicyHolder === false &&
              !['CH', 'None'].includes(field.nationality.name) && (
                <React.Fragment>
                  <DriverResidencePermitInput
                    value={field.residencePermit}
                    validation={validation}
                    lookupData={lookupData.residencePermits}
                    onChange={(value) =>
                      onChange('policyHolder.residencePermit', value)
                    }
                  />
                  <DriverResidencePermitSinceInput
                    value={field.residencePermitSince as string}
                    validation={validation}
                    onChange={(value) =>
                      onChange('policyHolder.residencePermitSince', value)
                    }
                  />
                </React.Fragment>
              )}
            <DriverEmailAddressInput
              value={field.emailAddress}
              validation={validation}
              onChange={(value) => onChange('policyHolder.emailAddress', value)}
            />
          </>
        )}
      </React.Fragment>
    );
  };

  const additionalDriverInputs = () => {
    const { driverDetails } = mainDriver;

    return (
      <React.Fragment>
        <OtherDriversInput
          value={driverDetails.otherDrivers}
          validation={validation}
          onChange={(value) =>
            onChange('mainDriver.driverDetails.otherDrivers', value)
          }
        />
        {driverDetails.otherDrivers === true && (
          <React.Fragment>
            <DriversOutsideTheHouseholdInput
              value={driverDetails.driversOutsideTheHousehold}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversOutsideTheHousehold',
                  value,
                )
              }
            />
            <DriversWithLearningOrTrialLicenseInput
              value={driverDetails.driversWithLearningOrTrialLicense}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversWithLearningOrTrialLicense',
                  value,
                )
              }
            />
            <DriversUnderTheAgeOf25Input
              value={driverDetails.driversUnderTheAgeOf25}
              validation={validation}
              onChange={(value) =>
                onChange(
                  'mainDriver.driverDetails.driversUnderTheAgeOf25',
                  value,
                )
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <QuestionStack as="form">
      <GenderInput
        value={mainDriver.gender}
        onChange={(value) => onChange(ID_GENDER, value)}
        validation={validation}
        lookupData={lookupData.genders}
      />
      <DateOfBirthInput
        value={mainDriver.dateOfBirth}
        onChange={(value) => onChange(ID_DATE_OF_BIRTH, value)}
        validation={validation}
      />
      <DateOfMotoDrivingLicenseInput
        value={mainDriver.dateOfMotoDrivingLicense as string}
        validation={validation}
        onChange={(value) => onChange(ID_DATE_OF_MOTO_DRIVING_LICENSE, value)}
      />
      <HasAutoDrivingLicenseInput
        value={mainDriver.hasAutoDrivingLicense}
        validation={validation}
        onChange={(value) => {
          onChange('mainDriver.hasAutoDrivingLicense', value);
        }}
      />
      {mainDriver.hasAutoDrivingLicense === true && (
        <DateOfDrivingLicenseInput
          value={mainDriver.dateOfDrivingLicense as string}
          validation={validation}
          onChange={(value) =>
            onChange('mainDriver.dateOfDrivingLicense', value)
          }
        />
      )}
      <NationalityInput
        name="mainDriver.nationality"
        value={mainDriver.nationality}
        validation={validation}
        lookupData={lookupData.countries}
        onChange={(value) => onChange('mainDriver.nationality', value)}
      />
      {!['CH', 'None'].includes(mainDriver.nationality.name) && (
        <React.Fragment>
          <ResidencePermitInput
            value={mainDriver.residencePermit}
            validation={validation}
            lookupData={lookupData.residencePermits}
            onChange={(value) => onChange('mainDriver.residencePermit', value)}
          />
          <ResidencePermitSinceInput
            value={mainDriver.residencePermitSince as string}
            validation={validation}
            onChange={(value) =>
              onChange('mainDriver.residencePermitSince', value)
            }
          />
        </React.Fragment>
      )}
      <CityInput
        value={mainDriver.postCode as number}
        city={mainDriver.city as City}
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
        path={STEP_DRIVER_MAIN}
      />
      {user == null && (
        <EmailAddressInput
          value={mainDriver.emailAddress}
          validation={validation}
          onChange={(value) => onChange('mainDriver.emailAddress', value)}
        />
      )}
      {policyHolderInputs()}
      {additionalDriverInputs()}
      <InsuranceStartDateInput
        value={insuranceOptions.insuranceStartDate as string}
        validation={validation}
        onChange={(value) => {
          insuranceOptions.insuranceStartDate = value;
          dispatch(inquiryInputChanged(insuranceOptions, null));
        }}
      />
      <StepButtons
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
