import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_FREE_GARAGE_CHOICE } from './const';

export const FreeGarageChoiceInput: React.FC<RadioGroupQuestionProps> = (
  props,
) => {
  const { value = null, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((i) => ({
    value: i,
    label: t(
      `${NS_STEP_INSURANCE_OPTIONS}.${ID_FREE_GARAGE_CHOICE}-${i.name.toLowerCase()}`,
    ),
  }));
  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_FREE_GARAGE_CHOICE}`)}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_FREE_GARAGE_CHOICE}-suggestion`,
      )}
      value={value}
      name={ID_FREE_GARAGE_CHOICE}
      onChange={onChange}
      options={options}
      onBlur={() => validation.isInvalid(ID_FREE_GARAGE_CHOICE)}
      errorMessage={t(validation.key(ID_FREE_GARAGE_CHOICE))}
      showFeedback={!!value?.key}
    />
  );
};
