import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { fs24 } from '@scout24ch/fs24-design-system';
import { EVENT_LABEL_POSITION_TOP_RIGHT } from 'utils/GTM/events';
import ShareButton from 'components/ShareButton/ShareButton';
import { navigateToRoute } from 'utils/navigationUtil';

export interface NavigationBarProps {
  title: string;
  navigateTo?: { routeName: string; params: Record<string, any> };
  callback: () => void;
  shareFn?: (email?: string) => void;
  hideEdit?: boolean;
  icon: React.ReactNode;
  whiteBg?: boolean;
}

export const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const { title, shareFn, hideEdit, icon, whiteBg = false } = props;
  const router = useRouter();
  const { t } = useTranslation();

  const handleClick = (e) => {
    const { navigateTo, callback } = props;
    if (e) e.stopPropagation();

    if (navigateTo) {
      navigateToRoute(navigateTo.routeName, router, navigateTo.params);
    } else if (callback) {
      callback();
    } else {
      router.back();
    }
  };

  return (
    <fs24.div
      p={4}
      mb={4}
      bgColor={whiteBg ? 'white' : 'grey.100'}
      id="nav-bar"
    >
      <fs24.div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
      >
        <div>
          {!hideEdit && (
            <fs24.div
              onClick={handleClick}
              role="button"
              sx={{ svg: { path: { fill: 'blue.500' } } }}
              display="flex"
              alignItems="center"
              aria-label="Navigation bar button"
            >
              {icon}
              <fs24.span ml={2} color="blue.500">
                {t(title)}
              </fs24.span>
            </fs24.div>
          )}
        </div>
        {shareFn && (
          <div>
            <ShareButton
              //@ts-ignore
              shareFn={shareFn}
              trackingLabel={EVENT_LABEL_POSITION_TOP_RIGHT}
            />
          </div>
        )}
      </fs24.div>
    </fs24.div>
  );
};
