import React from 'react';
import { QuestionStack } from '@scout24ch/fs24-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useValidation } from 'hooks';
import { StepButtons } from '../StepButtons';
import {
  KmPerYearInput,
  LeasingInput,
  PriceOfAccessoriesInput,
  RegistrationCantonInput,
  UsageInput,
  YearInput,
  VehicleSearch,
  HasPurchasedInput,
} from './components';
import {
  ID_KM_PER_YEAR,
  ID_PRICE_OF_ACCESSORIES,
  ID_YEAR_OF_PURCHASE,
} from './components/const';
import { StepVehicleProps } from './StepVehicle';

/**
 * AGGR-1898
 * Added additional input field (HasPurchasedInput) to year of purchase question
 * We don't want to add another field to backend/db, so as a result of that,
 * this function will map the value so the yes/no input stays checked
 * even though expectedPurchaseYear changes on the YearInput dropdown
 * @param value [number || null]
 */
const hasPurchasedInputValue = (value: number | null): number | null => {
  return [null, -1].includes(value) ? value : 0;
};

export const StepMotoVehicle: React.FC<StepVehicleProps> = (props) => {
  const { vehicleId, isAdjustingData, nextStep, previousStep, lastStep } =
    props;
  const { usageDetails } = useSelector(getInquiryInputs);
  const validation = useValidation(NS_STEP_VEHICLE, getInquiryValidation);
  const dispatch = useDispatch();
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);

  const onChange = (name: string, value: any) => {
    usageDetails[name] = value;
    dispatch(inquiryInputChanged(usageDetails, null));
  };

  const yearBoughtInput = () => {
    const { expectedPurchaseYear } = usageDetails;

    return (
      <React.Fragment>
        <HasPurchasedInput
          value={hasPurchasedInputValue(usageDetails.expectedPurchaseYear)}
          validation={validation}
          onChange={(value) => onChange('expectedPurchaseYear', value)}
        />
        {expectedPurchaseYear !== -1 && expectedPurchaseYear !== null && (
          <YearInput
            value={expectedPurchaseYear}
            //@ts-ignore
            onChange={(value: number) => onChange(ID_YEAR_OF_PURCHASE, value)}
            validation={validation}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <QuestionStack as="form">
      <VehicleSearch vehicleId={vehicleId} validation={validation} />
      <PriceOfAccessoriesInput
        value={usageDetails.priceOfAccessories}
        validation={validation}
        onChange={(value) =>
          onChange(
            ID_PRICE_OF_ACCESSORIES,
            value === undefined ? '' : String(value),
          )
        }
      />
      {yearBoughtInput()}
      <LeasingInput
        value={usageDetails.leasing}
        onChange={(value) => onChange('leasing', value)}
        validation={validation}
      />
      <KmPerYearInput
        value={usageDetails.kilometerPerYear}
        validation={validation}
        onChange={(value) =>
          onChange(ID_KM_PER_YEAR, value === undefined ? '' : String(value))
        }
      />
      <UsageInput
        privateUsage={usageDetails.privateUsage}
        businessUsage={usageDetails.businessUsage}
        commuteUsage={usageDetails.commuteUsage}
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
      />
      <RegistrationCantonInput
        value={usageDetails.registrationCanton}
        validation={validation}
        onChange={(value) => onChange('registrationCanton', value)}
      />
      <StepButtons
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
