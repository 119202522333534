import React from 'react';
import { NumberQuestion } from '@scout24ch/fs24-design-system';
import { NS_STEP_DRIVER } from 'utils/namespace-const';
import { useTranslation } from 'hooks';
import { NumberQuestionProps } from 'interfaces';
import { ID_AGE_OF_YOUNGEST_CHILD, NS_AGE_OF_YOUNGEST_CHILD } from './const';

export const AgeOfYoungestChildInput: React.FC<NumberQuestionProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { validation, value, onChange } = props;

  return (
    <NumberQuestion
      label={t(`${NS_STEP_DRIVER}.${NS_AGE_OF_YOUNGEST_CHILD}`)}
      name={ID_AGE_OF_YOUNGEST_CHILD}
      value={value}
      onValueChange={onChange}
      errorMessage={t(validation.key(NS_AGE_OF_YOUNGEST_CHILD, NS_STEP_DRIVER))}
      showFeedback={!!value}
    />
  );
};
