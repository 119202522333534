import React from 'react';
import { NumberQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import { NumberQuestionProps } from 'interfaces';

export const MileageInput: React.FC<NumberQuestionProps> = (props) => {
  const { value, validation, onChange } = props;
  const { t } = useTranslation();

  return (
    <NumberQuestion
      label={t(`${NS_STEP_VEHICLE}.mileage`)}
      name="mileage"
      value={value ?? ('' as unknown as number)}
      onValueChange={onChange}
      onBlur={() => validation.isInvalid('mileage')}
      errorMessage={t(validation.key('mileage'))}
      unit="Km"
      showFeedback={!!value}
      thousandSeparator="'"
    />
  );
};
