import React from 'react';
import _set from 'lodash/set';
import { QuestionStack, Text } from '@scout24ch/fs24-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyArray } from '@scout24ch/fs24-utils';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_INCIDENTS, STEP_DRIVER_MAIN } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useTranslation, useValidation } from 'hooks';
import { StepButtons } from '../StepButtons';
import {
  ID_AGE_OF_YOUNGEST_CHILD,
  ID_NUMBER_OF_CHILDREN,
} from './components/const';
import {
  AdditionalQuestionsInput,
  CancellationOrSpecialConditionsInput,
  ClaimListInput,
  DenialOrSpecialConditionsInput,
  InfringementListInput,
  SuspensionListInput,
  NumberOfChildrenInput,
  AgeOfYoungestChildInput,
  TermsAndConditionsInput,
} from './components';

export interface StepIncidents {
  nextStep: () => void;
  previousStep: () => void;
}

export const StepIncidents: React.FC<StepIncidents> = (props) => {
  const { nextStep, previousStep } = props;
  const { mainDriver, incidents } = useSelector(getInquiryInputs);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const { t } = useTranslation();
  const validation = useValidation(NS_STEP_INCIDENTS, getInquiryValidation);
  const dispatch = useDispatch();

  const onChange = (name: string, value: any) => {
    incidents[name] = value;
    dispatch(inquiryInputChanged(incidents, null));
  };

  const onNumberOfChildrenChanged = (value) => {
    _set({ mainDriver }, ID_NUMBER_OF_CHILDREN, value);
    if (value === 0) {
      _set({ mainDriver }, ID_AGE_OF_YOUNGEST_CHILD, '');
    }

    dispatch(inquiryInputChanged(mainDriver, STEP_DRIVER_MAIN));
  };

  const onAgeOfYoungestChildChanged = (value: number) => {
    const newValue = value === undefined ? '' : String(value);
    _set({ mainDriver }, ID_AGE_OF_YOUNGEST_CHILD, newValue);
    dispatch(inquiryInputChanged(mainDriver, STEP_DRIVER_MAIN));
  };

  const clearAdditionalInputs = () => {
    const newIncidents = { ...incidents };
    newIncidents.claims = [];
    newIncidents.infringements = [];
    newIncidents.suspensionOfDrivingLicense = [];
    dispatch(inquiryInputChanged(newIncidents));
  };

  return (
    <QuestionStack as="form">
      <NumberOfChildrenInput
        value={mainDriver.driverDetails.numberOfChildren}
        validation={validation}
        onChange={onNumberOfChildrenChanged}
      />
      {mainDriver.driverDetails.numberOfChildren > 0 && (
        <AgeOfYoungestChildInput
          value={mainDriver.driverDetails.ageOfTheYoungestChild as number}
          validation={validation}
          onChange={onAgeOfYoungestChildChanged}
        />
      )}
      <div>
        <Text fontSize="xl">
          {t(`${NS_STEP_INCIDENTS}.additional-questions-title`)}
        </Text>
        <Text fontSize="sm" mt={3}>
          {t(`${NS_STEP_INCIDENTS}.additional-questions`)}
        </Text>
      </div>
      <CancellationOrSpecialConditionsInput
        value={incidents.cancellationOrSpecialConditions}
        validation={validation}
        onChange={(value) => onChange('cancellationOrSpecialConditions', value)}
      />
      <DenialOrSpecialConditionsInput
        value={incidents.denialOrSpecialConditions}
        validation={validation}
        onChange={(value) => onChange('denialOrSpecialConditions', value)}
      />
      <AdditionalQuestionsInput
        isAdditionalQuestions={incidents.isAdditionalQuestions}
        claims={incidents.claims}
        validation={validation}
        infringements={incidents.infringements}
        suspensionOfDrivingLicense={incidents.suspensionOfDrivingLicense}
        onChange={(obj) => {
          onChange(obj.name, obj.value);
          if (!obj.value) {
            clearAdditionalInputs();
          }
        }}
      />
      {incidents.isAdditionalQuestions && !isEmptyArray(incidents.claims) && (
        <ClaimListInput
          claims={incidents.claims}
          onChange={(obj) => onChange(obj.name, obj.value)}
          validation={validation}
        />
      )}
      {incidents.isAdditionalQuestions &&
        !isEmptyArray(incidents.infringements) && (
          <InfringementListInput
            infringements={incidents.infringements}
            onChange={(obj) => onChange(obj.name, obj.value)}
            validation={validation}
          />
        )}
      {incidents.isAdditionalQuestions &&
        !isEmptyArray(incidents.suspensionOfDrivingLicense) && (
          <SuspensionListInput
            suspensions={incidents.suspensionOfDrivingLicense}
            onChange={(obj) => onChange(obj.name, obj.value)}
            validation={validation}
          />
        )}
      <TermsAndConditionsInput />
      <StepButtons
        nextStep={nextStep}
        nextStepText={t(`${NS_STEP_INCIDENTS}.button-next`)}
        previousStep={previousStep}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
