import { STEP_DRIVER_MAIN } from 'utils/namespace-const';

export const ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS =
  'cancellationOrSpecialConditions';
export const ID_IS_DENIAL_OR_SPECIAL_CONDITIONS = 'denialOrSpecialConditions';
export const ID_IS_ADDITIONAL_QUESTIONS = 'isAdditionalQuestions';
export const ID_ADDITIONAL_QUESTIONS = 'additionalQuestions';
export const ID_HAS_CLAIMS = 'hasClaims';
export const ID_HAS_INFRINGEMENTS = 'hasInfringements';
export const ID_HAS_SUSPENSION_OF_DRIVING_LICENSE =
  'hasSuspensionOfDrivingLicense';

export const ID_CLAIM_TYPE = 'type';
export const ID_CLAIM_DATE = 'claimDate';
export const ID_CLAIM_AMOUNT = 'amount';
export const ID_CLAIM_CAUSE = 'cause';
export const ID_CLAIM_SELF_INFLICTED = 'selfInflicted';
export const ID_CLAIM_CAUSER = 'causer';

export const ID_INFRINGEMENT_DATE = 'infringementDate';
export const ID_INFRINGEMENT_REASON = 'infringementReason';

export const ID_SUSPENSION_DATE = 'suspensionDate';
export const ID_SUSPENSION_PERIOD = 'suspensionPeriod';

export const ID_NUMBER_OF_CHILDREN = `${STEP_DRIVER_MAIN}.driverDetails.numberOfChildren`;
export const ID_AGE_OF_YOUNGEST_CHILD = `${STEP_DRIVER_MAIN}.driverDetails.ageOfTheYoungestChild`;
export const NS_AGE_OF_YOUNGEST_CHILD = `${STEP_DRIVER_MAIN}.driverDetails.ageOfYoungestChild`;
