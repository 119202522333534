import { isSmallDevice } from '@scout24ch/fs24-utils';

export enum FeatureFlag {
  // Example required for tests. Replace with a real A/B test.
  FlagName = 'CI_2023_00_SplitName',
  PortalAS24VehicleRedirect = 'Portal_2024_03_AS24-Direct-Funnel-Access',
}

export const ONGOING_TREATMENTS: Array<keyof typeof FeatureFlag> = Object.keys(
  FeatureFlag,
).map((f) => FeatureFlag[f]);

export type FeatureFlags = Record<FeatureFlag, string | undefined>;

/**
 * Typical treatment values for a split feature flag.
 */
export enum FeatureFlagTreatmentValues {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control',
}

// Add new split tracking events
export enum TrackEventType {}

export enum TrackEventProperties {
  deviceType = 'deviceType',
}

export enum TrackEventValues {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const getTrackEventDeviceType = (): {
  [key: string]: TrackEventValues;
} =>
  isSmallDevice()
    ? {
        [TrackEventProperties.deviceType as string]: TrackEventValues.MOBILE,
      }
    : {
        [TrackEventProperties.deviceType as string]: TrackEventValues.DESKTOP,
      };
