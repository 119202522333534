import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import { useTranslation } from 'hooks';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import {
  ID_GARAGE,
  ID_GARAGE_AT_HOME,
  ID_GARAGE_AT_HOME_AND_WORK,
  ID_GARAGE_AT_WORK,
  ID_GARAGE_NONE,
} from './const';

export interface GarageInputProps {
  onChange: (item: any) => void;
  validation: EvaluatedValidation;
  garageAtWork: boolean;
  garageAtHome: boolean;
}

export const GarageInput: React.FC<GarageInputProps> = (props) => {
  const { garageAtWork, garageAtHome, onChange, validation } = props;
  const [value, setValue] = React.useState(null);
  const { t } = useTranslation();

  const values = [
    { field: ID_GARAGE_NONE, value: !garageAtHome && !garageAtWork },
    { field: ID_GARAGE_AT_HOME, value: garageAtHome && !garageAtWork },
    { field: ID_GARAGE_AT_WORK, value: garageAtWork && !garageAtHome },
    { field: ID_GARAGE_AT_HOME_AND_WORK, value: garageAtHome && garageAtWork },
  ];

  React.useEffect(() => {
    if (garageAtHome === null || garageAtWork === null) {
      setValue(null);
      return;
    }
    if (!garageAtHome && !garageAtWork) {
      setValue(ID_GARAGE_NONE);
    }
    if (garageAtHome && !garageAtWork) {
      setValue(ID_GARAGE_AT_HOME);
    }
    if (garageAtWork && !garageAtHome) {
      setValue(ID_GARAGE_AT_WORK);
    }
    if (garageAtHome && garageAtWork) {
      setValue(ID_GARAGE_AT_HOME_AND_WORK);
    }
  }, [garageAtWork, garageAtHome]);

  const convertSelectedGarage = (value: string) => {
    setValue(value);
    values.forEach((item) => {
      let newValue = value === item.field;
      if (value === ID_GARAGE_AT_HOME_AND_WORK) {
        newValue =
          item.field === ID_GARAGE_AT_HOME || item.field === ID_GARAGE_AT_WORK;
      }
      onChange({ name: item.field, value: newValue });
    });
  };

  const options = values.map((value) => ({
    value: value.field,
    label: t(`${NS_STEP_VEHICLE}.${ID_GARAGE}-${value.field}`),
  }));

  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_VEHICLE}.${ID_GARAGE}`)}
      labelHelp={t(`${NS_STEP_VEHICLE}.${ID_GARAGE}-info`)}
      name={ID_GARAGE}
      onChange={convertSelectedGarage}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(ID_GARAGE)}
      errorMessage={t(validation.key(ID_GARAGE))}
      showFeedback={!!value}
    />
  );
};
