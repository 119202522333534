import { LicensePlateResult } from 'api/vehicles';
import { VehicleSuggestion } from '../../types';

export function transfromLicensePlateVehicleData(
  data: LicensePlateResult,
): VehicleSuggestion[] {
  // multiple car choices
  if (data.eurotax_different_data.length > 1) {
    return data.eurotax_different_data.map((vehicle) => {
      return {
        id: Number(vehicle.eurotax_id),
        name: vehicle.model_name,
        make: data.make_name,
        model: vehicle.model_name,
        year: Number(data.registration_date.split('-')[0]),
        month: Number(data.registration_date.split('-')[1]),
        priceProvider: vehicle.price_brutto,
        engineHorsePower: data.eurotax_common_data.engine_power_hp,
        bodyType: undefined,
        doors: data.eurotax_common_data.doors[0],
        transmissionType: undefined,
        certificationCodes: [],
      };
    });
  }

  // only one choice
  return [
    {
      id: Number(data.eurotax_common_data.eurotax_id),
      name: data.eurotax_common_data.model_name,
      make: data.eurotax_common_data.make_name,
      model: data.eurotax_common_data.model_name,
      year: Number(data.registration_date.split('-')[0]),
      month: Number(data.registration_date.split('-')[1]),
      priceProvider: data.eurotax_common_data.price_brutto,
      engineHorsePower: data.eurotax_common_data.engine_power_hp,
      bodyType: undefined,
      doors: data.eurotax_common_data.doors[0],
      transmissionType: undefined,
      certificationCodes: [],
    },
  ];
}
