import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD } from './const';

export const DriversOutsideTheHouseholdInput: React.FC<BooleanQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD}`)}
      value={value}
      name={ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD)}
      errorMessage={t(validation.key(ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
