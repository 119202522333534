import React, { useState } from 'react';
import { DateQuestion } from '@scout24ch/fs24-design-system';
import { validateDateNotInFuture } from '@scout24ch/fs24-forms';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { NS_STEP_DRIVER } from 'utils/namespace-const';
import { useTranslation } from 'hooks';
import {
  convertDateValueToString,
  fromInput,
  isValidDateInput,
} from 'utils/dateformat-const';
import { DateQuestionProps } from 'interfaces';
import { ID_RESIDENCE_PERMIT_SINCE } from './const';

export const ResidencePermitSinceInput = (props: DateQuestionProps) => {
  const { value, validation, onChange } = props;
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);

  useOnMount(() => {
    setValid(!validateDateNotInFuture(convertDateValueToString(value)));
  });

  const handleOnChange = (value: string) => {
    setValid(!validateDateNotInFuture(value));

    onChange(isValidDateInput(value) ? fromInput(value) : value);
  };

  return (
    <DateQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_RESIDENCE_PERMIT_SINCE}`)}
      name={ID_RESIDENCE_PERMIT_SINCE}
      onValueChange={handleOnChange}
      value={convertDateValueToString(value)}
      errorMessage={t(validation.key(ID_RESIDENCE_PERMIT_SINCE))}
      showFeedback={isValid}
    />
  );
};
