import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE } from './const';

export const ParkingDamageInfiniteInput: React.FC<BooleanQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE}`,
      )}
      value={value}
      name={ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE}
      onChange={onChange}
      onBlur={() =>
        validation.isInvalid(ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE)
      }
      errorMessage={t(
        validation.key(ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE),
      )}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
