import Head from 'next/head';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IS_AUTO_INSURANCE } from '../../utils/const';

export function SeoSection(): React.ReactElement {
  const { i18n } = useTranslation();

  const BASE_URL = IS_AUTO_INSURANCE
    ? 'https://autoversicherung.financescout24.ch'
    : 'https://motorradversicherung.financescout24.ch';

  return (
    <Head>
      <link rel="canonical" href={`${BASE_URL}/${i18n.language}/inquiry`} />
    </Head>
  );
}
