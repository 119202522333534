import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import EvaluatedValidation from '../../../../../utils/EvaluatedValidation';
import { ID_IS_MAIN_DRIVER } from './const';

export interface IsMainDriverInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  validation: EvaluatedValidation;
}

export const IsMainDriverInput: React.FC<IsMainDriverInputProps> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_IS_MAIN_DRIVER}`)}
      labelHelp={t(`${NS_STEP_DRIVER}.${ID_IS_MAIN_DRIVER}-tooltip`)}
      value={value}
      name={ID_IS_MAIN_DRIVER}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_IS_MAIN_DRIVER)}
      errorMessage={t(validation.key(ID_IS_MAIN_DRIVER))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
