import React from 'react';
import { Text } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'react-i18next';
import { NS_STEP_INCIDENTS } from '../../../../../utils/namespace-const';
import { claimDefaults } from '../helpers/incidentsDefaults';
import EvaluatedValidation from '../../../../../utils/EvaluatedValidation';
import { ListInput } from './ListInput';
import { ClaimInput } from './ClaimInput';
import { Claim } from '../types';

type ClaimListInputProps = {
  claims: Claim[];
  validation: EvaluatedValidation;
  onChange: (obj: Record<string, any>) => void;
};

function ClaimListInput(props: ClaimListInputProps) {
  const { claims, validation, onChange: handleOnChange } = props;
  const { t } = useTranslation();

  function onChange(i: number, { name, value }) {
    const copiedClaims = [...claims];
    copiedClaims[i][name] = value;

    handleOnChange({ name: 'claims', value: copiedClaims });
  }

  function addElement(index: number) {
    const updatedClaims = [...claims];
    updatedClaims.splice(index + 1, 0, claimDefaults());
    handleOnChange({ name: 'claims', value: updatedClaims });
  }

  function removeElement(index: number) {
    const updatedClaims = [...claims];
    updatedClaims.splice(index, 1);
    handleOnChange({ name: 'claims', value: updatedClaims });
  }

  return (
    <div>
      <Text fontSize="xl" mb={6}>
        {t(`${NS_STEP_INCIDENTS}.claims-title`)}
      </Text>
      <ListInput
        translationKey="claims"
        addElement={addElement}
        removeElement={removeElement}
      >
        {claims.map((claim, i) => (
          <ClaimInput
            key={i}
            index={i}
            claim={claim}
            validation={validation}
            onChange={(e) => onChange(i, e)}
          />
        ))}
      </ListInput>
    </div>
  );
}

export default ClaimListInput;
