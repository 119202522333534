import React from 'react';
import { StepMotoInsuranceOptions, StepInsuranceOptions } from './';

export const createStepInsuranceOptions = (
  nextStep: () => void,
  previousStep: () => void,
  isAutoInsurance: boolean,
  lastStep: () => void,
  isAdjustingData: boolean,
): JSX.Element => {
  if (isAutoInsurance) {
    return (
      <StepInsuranceOptions
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
      />
    );
  }

  return (
    <StepMotoInsuranceOptions
      nextStep={nextStep}
      previousStep={previousStep}
      lastStep={lastStep}
      isAdjustingData={isAdjustingData}
    />
  );
};
