import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOnMount } from '@scout24ch/fs24-hooks';
import {
  changeSearchType,
  inquiryInputChanged,
} from 'state/InquiryInput/actions';
import { clearVehicle, fetchVehicleFulfilled } from 'state/VehicleData/actions';
import { logger } from 'utils';
import {
  VehicleSuggestion,
  VehicleTypeSuggestion,
} from 'containers/InquiryPage/components/StepVehicle/types';
import { getUsageDetails, getVehicle } from 'state/InquiryInput/selectors';
import { getVehicleData } from 'state/VehicleData/selectors';
import { Heading } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'react-i18next';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { ACTION_FIND_VEHICLE, CAR_COMPARISON_CATEGORY } from 'utils/GTM';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { FirstRegistrationDateInput } from '..';
import { VehicleSelection } from './VehicleSelection';
import VehicleSearchInput from './VehicleSearchInput';
import EvaluatedValidation from '../../../../../../utils/EvaluatedValidation';
import MakeInput from '../MakeInput';
import { Vehicle } from '../../../../../../state/InquiryInput/types';
import {
  getVehicleByLicensePlate,
  searchVehicles,
  searchVehicleType,
} from '../../../../../../api/vehicles';
import { SearchTypeInput } from '../SearchTypeInput';
import { TypeCertificateInput } from '../TypeCertificateInput';
import { useTypeCertificate } from '../../hooks/useTypeCertificate';
import { formatSelectedVehicle } from './helpers';
import { TypeCertificateErrors } from '../TypeCertificateErrors';
import { SearchFlow } from '../../../../../../utils/types';
import { AS24VehicleInfoCard } from '../AS24VehicleInfoCard';
import { useNumberPlateSearch } from '../../hooks/useNumberPlateSearch';
import { NumberPlateInput } from '../NumberPlateInput';
import { transfromLicensePlateVehicleData } from '../NumberPlateInput/helpers';

interface VehicleSearchProps {
  validation: EvaluatedValidation;
  vehicleId: number;
  vehicleData?: Vehicle | null;
  showAS24VehicleInfoCard?: boolean;
  isPerfectMatch?: boolean;
  setShowAS24VehicleInfoCard?: (show: boolean) => void;
}

function VehicleSearch(props: VehicleSearchProps) {
  const {
    vehicleId,
    validation,
    isPerfectMatch,
    showAS24VehicleInfoCard,
    setShowAS24VehicleInfoCard,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gtm = useGoogleTagManager();
  const [vehicles, setVehicles] = React.useState(null);
  const vehicle = useSelector(getVehicle);
  const usageDetails = useSelector(getUsageDetails);
  const vehicleData = useSelector(getVehicleData);
  const [vehicleRadiosToChoose, setVehicleRadiosToChoose] = React.useState<
    VehicleSuggestion[]
  >([]);
  const {
    makeName,
    typeName,
    mileage,
    eurotaxId,
    typeCertificate,
    searchType,
    licensePlateNumber,
  } = vehicle;

  const {
    fetchVehicleByCertificate,
    isTypeCertificateFlow,
    typeCertificateError,
    noResultsByCertificate,
  } = useTypeCertificate();

  const { isNumberPlateSearch } = useNumberPlateSearch();

  const {
    firstRegistrationYear: registrationYear,
    firstRegistrationMonth: registrationMonth,
  } = usageDetails;

  function logVehicleNotFound(
    useCase: string,
    makeName: string,
    typeName: string,
    eurotaxId: number,
  ): void {
    logger.info(`Vehicle not found - ${useCase}`, {
      makeName,
      typeName,
      eurotaxId,
    });
  }

  function setMake(make: string) {
    const newVehicle = {
      ...vehicle,
      makeName: make,
    } as Vehicle;
    setAs24VehicleId(newVehicle);
    dispatch(inquiryInputChanged(newVehicle, 'vehicle'));
  }

  function setTypeName(typeName: string, makeName: string, selected = false) {
    const newVehicle = {
      makeName,
      typeName,
      typeCertificate,
      searchType,
      typeNameSelected: selected,
    } as Vehicle;
    dispatch(inquiryInputChanged(newVehicle, 'vehicle'));
  }

  function resetTypeName() {
    const newVehicle = {
      makeName: vehicle.make,
    } as Vehicle;

    setAs24VehicleId(newVehicle);
    dispatch(inquiryInputChanged(newVehicle, 'vehicle'));
  }

  function setYear(year: number) {
    dispatch(
      inquiryInputChanged(
        { ...usageDetails, firstRegistrationYear: year },
        null,
      ),
    );
  }

  function setMonth(month: number) {
    dispatch(
      inquiryInputChanged(
        { ...usageDetails, firstRegistrationMonth: month },
        null,
      ),
    );
  }

  async function setVehicleSelected(
    euroTaxId: number,
    makeName: string,
    mileage: number,
    typeName: string = null,
    vehicles: VehicleSuggestion[],
    vehicleType: string,
    firstRegistrationMonth?: number,
    firstRegistrationYear?: number,
  ) {
    const formattedVehicle = await formatSelectedVehicle(
      euroTaxId,
      makeName,
      mileage,
      typeName,
      vehicles,
      vehicle,
      usageDetails,
      vehicleType,
      searchType === SearchFlow.TYPE_CERTIFICATE ? typeCertificate : undefined,
      firstRegistrationMonth,
      firstRegistrationYear,
    );

    setAs24VehicleId(formattedVehicle);
    setEuroTaxId(euroTaxId);
    dispatch(fetchVehicleFulfilled(formattedVehicle));
  }

  function setEuroTaxId(euroTaxId: number) {
    let newVehicle = vehicle;
    if (!newVehicle) {
      newVehicle = {} as Vehicle;
    }

    newVehicle.eurotaxId = euroTaxId;

    dispatch(inquiryInputChanged(newVehicle, 'vehicle'));
  }

  function resetVehicleSelected(typeName: string) {
    let newVehicle = vehicle;
    if (!newVehicle) {
      newVehicle = {} as Vehicle;
    }

    newVehicle.eurotaxId = null;
    newVehicle.typeName = typeName;
    dispatch(inquiryInputChanged(newVehicle, 'vehicle'));
  }

  function setAs24VehicleId(vehicle: Vehicle) {
    const as24VehicleId = vehicleId;
    if (as24VehicleId) {
      vehicle.as24VehicleId = as24VehicleId;
      vehicle.imageUrl = vehicleData?.imageUrl ?? null;
    }
  }

  async function loadAndSelectModel(
    make: string,
    typeName: string,
    registrationYear: number,
    registrationMonth: number,
    mileage: number,
    eurotaxId: number,
    isTypeCertificateFlow: boolean,
    isOnPageLoad = false,
  ): Promise<any> {
    try {
      if (isTypeCertificateFlow) {
        await fetchByCertificate({
          month: registrationMonth,
          year: registrationYear,
        });
      } else {
        const suggestedVehicles: VehicleSuggestion[] = vehicleData
          ?.eurotaxMatches?.length
          ? vehicleData.eurotaxMatches
          : await searchVehicles(
              make,
              typeName,
              registrationMonth,
              registrationYear,
              eurotaxId,
            );

        if (!isOnPageLoad) {
          gtm.push({
            eventAction: ACTION_FIND_VEHICLE,
            eventCategory: CAR_COMPARISON_CATEGORY,
            vehicle_number_results: suggestedVehicles?.length,
            vehicle_choice: 'model',
            error_reason: '',
          });
        }

        setVehicleRadiosToChoose(suggestedVehicles);

        if (suggestedVehicles?.length === 0) {
          logVehicleNotFound('No vehicle selection', make, typeName, null);
        }

        setVehicles(
          suggestedVehicles.sort(
            (first, second) =>
              first.engineHorsePower - second.engineHorsePower ||
              first.priceProvider - second.priceProvider,
          ),
        );

        let selectedVehicle = suggestedVehicles.find(
          (vehicle) => vehicle.id === eurotaxId,
        );

        if (!selectedVehicle && suggestedVehicles.length === 1) {
          selectedVehicle = suggestedVehicles[0];
        }

        if (selectedVehicle) {
          setVehicleSelected(
            selectedVehicle.id,
            make,
            mileage,
            null,
            suggestedVehicles,
            typeName,
          );

          return;
        }

        resetVehicleSelected(typeName);
      }
    } catch (error) {
      logger.error('Failed handling change of month', error);
      resetTypeName();
    }
  }

  function handleSuggestionSelect(suggestion: VehicleTypeSuggestion) {
    setVehicles(null);
    setTypeName(suggestion.vehicleType, makeName, true);
    loadAndSelectModel(
      makeName,
      suggestion.vehicleType,
      registrationYear,
      registrationMonth,
      mileage,
      suggestion.euroTaxId,
      isTypeCertificateFlow,
    );
  }

  function resetOnNumberPlateChange() {
    setVehicleRadiosToChoose([]);
    setVehicles(null);
  }

  async function handleNumberPlateSearch({
    vehicles,
    make,
    typeName,
    firstRegistrationMonth,
    firstRegistrationYear,
  }: {
    vehicles: VehicleSuggestion[];
    make: string;
    typeName: string;
    firstRegistrationMonth?: number;
    firstRegistrationYear?: number;
  }) {
    setVehicleRadiosToChoose(vehicles);

    setVehicles(
      vehicles.sort(
        (first, second) =>
          first.engineHorsePower - second.engineHorsePower ||
          first.priceProvider - second.priceProvider,
      ),
    );

    if (vehicles.length === 1) {
      setVehicleSelected(
        vehicles[0].id,
        make,
        null,
        null,
        vehicles,
        typeName,
        firstRegistrationMonth,
        firstRegistrationYear,
      );

      return;
    }

    resetVehicleSelected(typeName);
  }

  function handleSearch(searchTerm: string) {
    return searchVehicleType({
      makeName,
      searchTerm,
      year: registrationYear,
      month: registrationMonth,
    });
  }

  function handleModelNameChange(newValue: string) {
    setVehicles(null);
    setTypeName(newValue, makeName);
  }

  async function fetchByCertificate({
    month,
    year,
  }: {
    month: number;
    year: number;
  }) {
    const vehiclesByCertificate = await fetchVehicleByCertificate({
      month,
      year,
    });

    if (vehiclesByCertificate?.vehicleTypes) {
      setVehicles(vehiclesByCertificate.vehicleTypes);
      setMake(vehiclesByCertificate.make);
      if (vehiclesByCertificate.vehicleTypes.length === 1) {
        setEuroTaxId(vehiclesByCertificate.vehicleTypes[0].euroTaxId);
      }

      gtm.push({
        eventAction: ACTION_FIND_VEHICLE,
        eventCategory: CAR_COMPARISON_CATEGORY,
        vehicle_number_results: vehiclesByCertificate.vehicleTypes.length,
        vehicle_choice: 'type',
        error_reason: '',
      });
    }
  }

  async function handleYearChange(year: number) {
    setYear(year);
    setVehicles(null);
    setTypeName(null, makeName);

    // Type certificate flow
    if (typeCertificate && registrationMonth) {
      await fetchByCertificate({ month: registrationMonth, year });
    }
  }

  async function handleMonthChange(month: number) {
    setMonth(month);
    setVehicles(null);
    setTypeName(null, makeName);

    // Type certificate flow
    if (typeCertificate && registrationYear) {
      await fetchByCertificate({ month, year: registrationYear });
    }
  }

  function handleBrandChange(elementId: string, value: any) {
    dispatch(clearVehicle());
    setVehicles(null);
    setMake(value.name);
    setTypeName(null, value.name);
  }

  function handleExactModelChange(eventData: {
    id: number;
    vehicleType: string;
  }) {
    setVehicleSelected(
      eventData.id,
      makeName,
      mileage,
      null,
      vehicleRadiosToChoose,
      eventData.vehicleType,
    );
  }

  // Reset vehicle data when changing search type
  function handleSearchTypeChange(searchType: string) {
    dispatch(clearVehicle());
    dispatch(changeSearchType(searchType));
    setVehicles(null);
    dispatch(
      inquiryInputChanged(
        {
          ...usageDetails,
          firstRegistrationYear: null,
          firstRegistrationMonth: null,
        },
        null,
      ),
    );
  }

  async function loadCarByNumberPlate() {
    const canton = vehicle.licensePlateNumber.substring(0, 2);
    const licenseNumber = vehicle.licensePlateNumber.substring(2).trim();

    const licensePlateVehicles = await getVehicleByLicensePlate(
      canton,
      licenseNumber,
    );

    const { make_name, model_name } = licensePlateVehicles[0];

    const vehicles = transfromLicensePlateVehicleData(licensePlateVehicles[0]);

    handleNumberPlateSearch({
      vehicles,
      make: make_name,
      typeName: model_name,
    });
  }

  useOnMount(() => {
    // this one fires only when coming back from results list (editing funnel details)
    // so all of those params in if condition should already be present in redux store
    if (vehicle.licensePlateNumber) {
      loadCarByNumberPlate();
      return;
    }

    if (makeName && typeName && registrationYear && registrationMonth) {
      loadAndSelectModel(
        makeName,
        typeName,
        registrationYear,
        registrationMonth,
        mileage,
        eurotaxId,
        isTypeCertificateFlow,
        true,
      );
    }
  });

  function renderSearchTypeInput() {
    // should show number plate input if page is loaded without preloaded car or number plate search is explicitly selected
    if (IS_AUTO_INSURANCE && isNumberPlateSearch) {
      return (
        <NumberPlateInput
          value={licensePlateNumber}
          validation={validation}
          handleNumberPlateSearch={handleNumberPlateSearch}
          resetVehicle={resetOnNumberPlateChange}
        />
      );
    }

    if (isTypeCertificateFlow) {
      return (
        <TypeCertificateInput value={typeCertificate} validation={validation} />
      );
    }

    return (
      <MakeInput
        validation={validation}
        makeName={makeName}
        onChange={handleBrandChange}
      />
    );
  }

  return (
    <>
      {showAS24VehicleInfoCard ? (
        <>
          <AS24VehicleInfoCard
            vehicle={vehicle}
            onEditIconClick={() => setShowAS24VehicleInfoCard(false)}
          />

          {isPerfectMatch ? null : vehicles ? (
            <VehicleSelection
              vehicleSuggestions={vehicles}
              selectedVehicleEuroTaxId={eurotaxId}
              validation={validation}
              onChange={handleExactModelChange}
            />
          ) : null}
        </>
      ) : (
        <>
          <Heading size="xl" mb={[0, null, 2]}>
            {t('step:common.usageDetails')}
          </Heading>
          <SearchTypeInput
            onChange={handleSearchTypeChange}
            searchType={vehicle.searchType}
          />

          {renderSearchTypeInput()}

          {(makeName || typeCertificate) && !isNumberPlateSearch ? (
            <FirstRegistrationDateInput
              month={registrationMonth}
              year={registrationYear}
              validation={validation}
              onYearChange={handleYearChange}
              onMonthChange={handleMonthChange}
            />
          ) : null}
          {registrationYear &&
          registrationMonth &&
          !isTypeCertificateFlow &&
          !isNumberPlateSearch ? (
            <VehicleSearchInput
              value={typeName}
              validation={validation}
              isVehicleSelected={vehicles?.length > 0}
              onChange={handleModelNameChange}
              onSuggestionSelected={handleSuggestionSelect}
              search={handleSearch}
            />
          ) : null}
          {vehicles ? (
            <VehicleSelection
              vehicleSuggestions={vehicles}
              selectedVehicleEuroTaxId={eurotaxId}
              validation={validation}
              onChange={handleExactModelChange}
            />
          ) : null}
          <TypeCertificateErrors
            haveNoResults={noResultsByCertificate}
            typeCertificateError={typeCertificateError}
          />
        </>
      )}
    </>
  );
}

export default VehicleSearch;
