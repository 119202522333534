import React from 'react';
import {
  Badge,
  Heading,
  Checkbox,
  Icon,
  theme,
  Stack,
  Question,
  Text,
} from '@scout24ch/fs24-design-system';
import { AcceptGlyph } from '@scout24ch/fs24-design-system/icons/24';
import { useTranslation } from 'hooks';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import { NS_STEP_INSURANCE_OPTIONS } from 'utils/namespace-const';
import {
  ID_FULLY_COMPREHENSIVE,
  ID_INSURANCE_TYPE,
  ID_LIABILITY,
  ID_PARTIAL_COVER,
} from './const';

interface InsuranceTypeInputProps {
  liabilityInsurance: boolean;
  partialCoverInsurance: boolean;
  fullyComprehensiveInsurance: boolean;
  onChange: (item: any) => void;
  validation: EvaluatedValidation;
  fullyComprehensiveSuggested: boolean;
  partialCoverSuggested: boolean;
  disabled: boolean;
}

const getTarget = ({ name }, value) => ({ name, value: !value });

const InputDescription = ({ id, suggested, label, t }) => (
  <>
    <Heading as="h3" size="lg" mb={0}>
      {t(`${NS_STEP_INSURANCE_OPTIONS}.${id}-title`)}{' '}
      {label && (
        <span>({t(`${NS_STEP_INSURANCE_OPTIONS}.mandatory-${id}`)})</span>
      )}
    </Heading>

    {suggested && (
      <Badge colorScheme="blue">
        {t(`${NS_STEP_INSURANCE_OPTIONS}.suggested`)}
      </Badge>
    )}
  </>
);

export const InsuranceTypeInput: React.FC<InsuranceTypeInputProps> = (
  props,
) => {
  const {
    liabilityInsurance,
    partialCoverInsurance,
    fullyComprehensiveInsurance,
    onChange,
    validation,
    fullyComprehensiveSuggested,
    partialCoverSuggested,
    disabled,
  } = props;
  const { t } = useTranslation();

  const handleOnChangePartial = ({ target }) => {
    onChange(getTarget(target, partialCoverInsurance));
  };

  const handleOnChangeFull = ({ target }) => {
    if (!fullyComprehensiveInsurance && !partialCoverInsurance) {
      onChange(getTarget({ name: ID_PARTIAL_COVER }, partialCoverInsurance));
    }
    onChange(getTarget(target, fullyComprehensiveInsurance));
  };

  return (
    <>
      <Heading as="h3" size="md" mt={3} display="flex" alignItems="center">
        <Icon
          as={AcceptGlyph}
          color={theme.colors.green[400]}
          position="relative"
          mr={2}
          w={5}
          h={5}
        />
        <strong>
          {t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_INSURANCE_TYPE}`)}
        </strong>
      </Heading>
      <Stack spacing={3}>
        <Question
          mb={0}
          name={ID_LIABILITY}
          label={
            <Checkbox
              mb={0}
              id={ID_LIABILITY}
              name={ID_LIABILITY}
              isChecked={liabilityInsurance}
              isDisabled={true}
            >
              {InputDescription({
                suggested: false,
                id: ID_LIABILITY,
                label: true,
                t,
              })}
            </Checkbox>
          }
          labelHelp={t(
            `${NS_STEP_INSURANCE_OPTIONS}.${ID_LIABILITY}-description`,
          )}
        />
        <Question
          mb={0}
          name={ID_PARTIAL_COVER}
          label={
            <Checkbox
              mb={0}
              id={ID_PARTIAL_COVER}
              name={ID_PARTIAL_COVER}
              onChange={handleOnChangePartial}
              isChecked={partialCoverInsurance}
              isDisabled={fullyComprehensiveInsurance}
              isInvalid={validation.isInvalid(ID_INSURANCE_TYPE)}
            >
              {InputDescription({
                suggested: partialCoverSuggested,
                id: ID_PARTIAL_COVER,
                label: false,
                t,
              })}
            </Checkbox>
          }
          labelHelp={t(
            `${NS_STEP_INSURANCE_OPTIONS}.${ID_PARTIAL_COVER}-description`,
          )}
        />
        <Question
          mb={0}
          name={ID_FULLY_COMPREHENSIVE}
          label={
            <Checkbox
              mb={0}
              id={ID_FULLY_COMPREHENSIVE}
              name={ID_FULLY_COMPREHENSIVE}
              onChange={handleOnChangeFull}
              isChecked={fullyComprehensiveInsurance}
              isDisabled={disabled}
              isInvalid={validation.isInvalid(ID_INSURANCE_TYPE)}
            >
              {InputDescription({
                suggested: fullyComprehensiveSuggested,
                id: ID_FULLY_COMPREHENSIVE,
                label: false,
                t,
              })}
            </Checkbox>
          }
          labelHelp={t(
            `${NS_STEP_INSURANCE_OPTIONS}.${ID_FULLY_COMPREHENSIVE}-description`,
          )}
        />
      </Stack>
      {validation.isInvalid(ID_INSURANCE_TYPE) && (
        <Text color="info.red">{t(validation.key(ID_INSURANCE_TYPE))}</Text>
      )}
    </>
  );
};
