import React from 'react';
import { Text } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'react-i18next';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { infringementDefaults } from '../helpers/incidentsDefaults';
import { ListInput } from './ListInput';
import InfringementInput from './InfringementInput';
import { Infringement } from '../types';

type InfringementListInputProps = {
  infringements: Infringement[];
  validation: EvaluatedValidation;
  onChange: (obj: Record<string, any>) => void;
};

function InfringementListInput(props: InfringementListInputProps) {
  const { onChange: handleOnChange, infringements, validation } = props;
  const { t } = useTranslation();

  function onChange(i: number, { name, value }) {
    const copiedInfringements = [...infringements];
    copiedInfringements[i][name] = value;
    handleOnChange({
      name: 'infringements',
      value: copiedInfringements,
    });
  }

  function addElement(index: number) {
    const updatedInfrigements = [...infringements];
    updatedInfrigements.splice(index + 1, 0, infringementDefaults());
    handleOnChange({ name: 'infringements', value: updatedInfrigements });
  }

  function removeElement(index: number) {
    const updatedInfrigements = [...infringements];
    updatedInfrigements.splice(index, 1);
    handleOnChange({ name: 'infringements', value: updatedInfrigements });
  }

  return (
    <div>
      <Text fontSize="xl" mb={6}>
        {t(`${NS_STEP_INCIDENTS}.infringements-title`)}
      </Text>
      <ListInput
        translationKey="infringements"
        addElement={addElement}
        removeElement={removeElement}
      >
        {infringements.map((infringement, i) => (
          <InfringementInput
            key={i}
            index={Number(i)}
            infringement={infringement}
            validation={validation}
            onChange={(e) => onChange(i, e)}
          />
        ))}
      </ListInput>
    </div>
  );
}

export default InfringementListInput;
