import { Button, Flex, Image, Text } from '@scout24ch/fs24-design-system';
import { Edit } from '@scout24ch/fs24-design-system/icons/24';
import React, { SyntheticEvent } from 'react';
import { formatCurrency } from '@scout24ch/fs24-utils';
import { AutoScout24Logo } from '../../../../../../Icon';
import { Vehicle } from '../../../../../../state/InquiryInput/types';

const placeholderImagePath = '/img/car-img-fallback.svg';

type AS24VehicleInfoCardProps = {
  vehicle: Vehicle;
  onEditIconClick?(): void;
};

export function AS24VehicleInfoCard(
  props: AS24VehicleInfoCardProps,
): React.ReactElement {
  const { vehicle, onEditIconClick } = props;

  const fullCarName = `${vehicle.makeName} ${vehicle.typeName}`.replace(
    /\s+/g,
    ' ',
  );

  function onImageLoadError(event: SyntheticEvent<HTMLImageElement>) {
    event.currentTarget.src = placeholderImagePath;
  }

  return (
    <Flex
      position="relative"
      borderRadius="md"
      border="1px"
      borderColor="grey.400"
      overflow="hidden"
      h={112}
      mt={8}
    >
      <Flex
        h={112}
        w={133}
        flexShrink={0}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        <Image
          src={vehicle.imageUrl ?? placeholderImagePath}
          alt={
            vehicle.imageUrl
              ? `${vehicle.makeName} Photo`
              : 'Fallback car image'
          }
          h={112}
          w={133}
          objectFit="cover"
          onError={onImageLoadError}
        />
      </Flex>
      <Flex flexDirection="column" px={4} py={3} justifyContent="space-between">
        <div>
          <AutoScout24Logo height="24" width="104" />
          <Text fontWeight="bold" noOfLines={1} maxW={255}>
            {fullCarName}
          </Text>
        </div>
        {vehicle.sellingPrice ? (
          <Text>
            {formatCurrency(vehicle.sellingPrice, {
              decimals: 0,
            })}
            .—
          </Text>
        ) : null}
        <Button
          position="absolute"
          top={-3}
          right={-3}
          aria-label="Show car search details"
          variant="link"
          onClick={onEditIconClick}
        >
          <Edit />
        </Button>
      </Flex>
    </Flex>
  );
}
