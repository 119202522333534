import React from 'react';
import {
  fs24,
  Icon,
  NumberQuestion,
  Text,
} from '@scout24ch/fs24-design-system';
import { formatDecimalNumber } from '@scout24ch/fs24-utils';
import { PositiveCheck } from '@scout24ch/fs24-design-system/icons/24';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { useTranslation } from 'hooks';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import { NumberQuestionProps } from 'interfaces';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { CAR_COMPARISON_CATEGORY, EVENT_MOST_FREQUENT_KMS } from 'utils/GTM';
import { ID_KM_PER_YEAR } from './const';

const QUICK_KM_OPTIONS = [10_000, 15_000, 20_000];

type KMsPerYearQuickOptionsProps = {
  value: number;
  onChange: (value: number) => void;
};

function KMsPerYearQuickOptions(props: KMsPerYearQuickOptionsProps) {
  const { value, onChange } = props;
  const gtm = useGoogleTagManager();
  const { t } = useTranslation();

  function handleChange(option: number) {
    gtm.push({
      eventCategory: CAR_COMPARISON_CATEGORY,
      eventAction: EVENT_MOST_FREQUENT_KMS,
      eventLabel: option.toString(),
    });
    onChange(option);
  }

  return (
    <div>
      <Text mb={3} fontSize="xs">
        {t(`${NS_STEP_VEHICLE}.quickYearyKmsTitle`)}
      </Text>
      <fs24.div display="flex">
        {QUICK_KM_OPTIONS.map((option) => {
          const isSelected = option === Number(value);

          return (
            <fs24.button
              key={option}
              type="button"
              border="1px"
              borderColor={isSelected ? 'aqua.500' : 'grey.900'}
              bgColor={isSelected ? 'aqua.50' : undefined}
              borderRadius="24px"
              fontSize="sm"
              px={3}
              py={2}
              mr={2}
              onClick={() => handleChange(option)}
              _hover={{
                bgColor: 'grey.100',
              }}
            >
              <fs24.span display="flex" alignItems="center">
                {isSelected ? (
                  <Icon
                    data-testid="check-icon"
                    w={5}
                    h={5}
                    mr={1}
                    as={PositiveCheck}
                  />
                ) : null}
                {`${formatDecimalNumber(option)} km`}
              </fs24.span>
            </fs24.button>
          );
        })}
      </fs24.div>
    </div>
  );
}

export const KmPerYearInput: React.FC<NumberQuestionProps> = (props) => {
  const { value, validation, onChange } = props;
  const { t } = useTranslation();

  return (
    <div>
      <NumberQuestion
        label={t(`${NS_STEP_VEHICLE}.${ID_KM_PER_YEAR}`)}
        labelHelp={t(`${NS_STEP_VEHICLE}.${ID_KM_PER_YEAR}-info`)}
        name={ID_KM_PER_YEAR}
        value={value ?? ('' as unknown as number)}
        onValueChange={onChange}
        onBlur={() => validation.isInvalid(ID_KM_PER_YEAR)}
        errorMessage={t(validation.key(ID_KM_PER_YEAR))}
        unit="Km"
        showFeedback={value && !validation.isInvalid(ID_KM_PER_YEAR)}
        mb={4}
      />
      {IS_AUTO_INSURANCE ? (
        <KMsPerYearQuickOptions onChange={onChange} value={value} />
      ) : null}
    </div>
  );
};
