import React, { useEffect, useMemo, useState } from 'react';
import { months, SelectQuestion } from '@scout24ch/fs24-design-system';
import moment, { utc } from 'moment';
import { useTranslation } from 'react-i18next';
import { range } from '@scout24ch/fs24-utils';
import { formatDate } from 'utils/dateformat-const';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';

const monthsOptions = (locale) =>
  months[locale].map((month) => ({ text: month }));

const yearRange = (currentYear: number) =>
  range(currentYear - 5, currentYear + 1);
const years = (currentYear: number) =>
  yearRange(currentYear).map((year) => ({ text: year.toString(10) }));

type MonthYearInputProps = {
  validation: EvaluatedValidation;
  value: Date;
  propName: string;
  id: string;
  index?: number;
  onChange: (obj: Record<string, any>) => void;
};

function getYearMonthIndex(value: Date, years: { text: string }[]) {
  if (value) {
    const date = moment(value);
    const monthIndex = date.month() + 1;
    const yearIndex = years.findIndex((y) => y.text === date.year().toString());

    return { monthIndex, yearIndex };
  }

  return { monthIndex: 0, yearIndex: 0 };
}

function MonthYearInput(props: MonthYearInputProps) {
  const { onChange, validation, value, propName, id, index } = props;
  const { t, i18n } = useTranslation();

  const MONTHS = useMemo(
    () => [
      { text: t(`${NS_STEP_INCIDENTS}.date-none`) },
      ...monthsOptions(i18n.language),
    ],
    [i18n.language, t],
  );

  const YEARS = useMemo(
    () => [
      { text: t(`${NS_STEP_INCIDENTS}.date-none`) },
      ...years(moment().year()),
    ],
    [t],
  );

  const [monthIndex, setMonthIndex] = useState(
    () => getYearMonthIndex(value, YEARS).monthIndex,
  );
  const [yearIndex, setYearIndex] = useState(
    () => getYearMonthIndex(value, YEARS).yearIndex,
  );

  function handleOnChange({
    monthIndex,
    yearIndex,
  }: {
    monthIndex: number;
    yearIndex: number;
  }) {
    setMonthIndex(monthIndex);
    setYearIndex(yearIndex);

    const month = monthIndex - 1;
    const year = YEARS[yearIndex].text;

    const date = utc({ month, year: Number(year) });

    if (date.month() >= 0 && date.year() > 0) {
      onChange({ name: [propName], value: formatDate(date) });
    }
  }

  function evaluateValidation(yearIndex, validation, id, index, t) {
    const matchingValidation = validation?.key(`${id}_${index}`);
    if (matchingValidation) {
      if (yearIndex === 0) {
        return t('step:incidents.year-empty');
      }

      return t(matchingValidation);
    }

    return '';
  }

  useEffect(() => {
    setMonthIndex(getYearMonthIndex(value, YEARS).monthIndex);
    setYearIndex(getYearMonthIndex(value, YEARS).yearIndex);
  }, [value, YEARS]);

  return (
    <React.Fragment>
      <SelectQuestion
        label={t(`${NS_STEP_INCIDENTS}.month`)}
        name={`${id}_month`}
        onChange={(value) => {
          handleOnChange({ monthIndex: Number(value), yearIndex });
        }}
        options={MONTHS.map((month, i) => ({
          disabled: i === 0,
          label: month.text,
          value: i,
        }))}
        value={monthIndex}
        onBlur={() =>
          validation.isInvalid(`${id}_${index}`) && monthIndex === 0
        }
        errorMessage={
          monthIndex === 0 && validation.key(`${id}_${index}`)
            ? t(`${NS_STEP_INCIDENTS}.month-empty`)
            : ''
        }
        showFeedback={!!monthIndex}
      />
      <SelectQuestion
        label={t(`${NS_STEP_INCIDENTS}.year`)}
        onChange={(value) =>
          handleOnChange({ monthIndex, yearIndex: Number(value) })
        }
        options={YEARS.map((year, i) => ({
          disabled: i === 0,
          label: year.text,
          value: i,
        }))}
        value={yearIndex}
        name={`${id}_year`}
        onBlur={() => validation.isInvalid(`${id}_${index}`) && yearIndex === 0}
        errorMessage={evaluateValidation(yearIndex, validation, id, index, t)}
        showFeedback={!!yearIndex}
      />
    </React.Fragment>
  );
}

export default MonthYearInput;
