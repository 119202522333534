import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_INCIDENTS } from '../../../../../utils/namespace-const';
import { ID_CLAIM_TYPE } from './const';

export interface ClaimTypeInputProps extends RadioGroupQuestionProps {
  index: number;
}

export const ClaimTypeInput: React.FC<ClaimTypeInputProps> = (props) => {
  const { index, value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    value: entry,
    label: t(
      `${NS_STEP_INCIDENTS}.${ID_CLAIM_TYPE}-${entry.name.toLowerCase()}`,
    ),
  }));

  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_INCIDENTS}.${ID_CLAIM_TYPE}`)}
      name={`${ID_CLAIM_TYPE}_${index}`}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(`${ID_CLAIM_TYPE}_${index}`)}
      errorMessage={t(validation.key(`${ID_CLAIM_TYPE}_${index}`))}
      showFeedback={!!value}
    />
  );
};
