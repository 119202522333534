import React, { useEffect } from 'react';
import { BooleanQuestion, useEffectValue } from '@scout24ch/fs24-design-system';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { getVehicleData } from 'state/VehicleData/selectors';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import {
  ACTION_PARKDAMAGE_UPSELLING_HINT,
  createEvent,
} from 'utils/GTM/events';
import { ID_IS_PARKING_DAMAGE } from './const';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ParkingDamageHintCard } from './ParkingDamageHintCard';

export const ParkingDamageInput: React.FC<BooleanQuestionProps> = (props) => {
  const { value, validation, onChange } = props;
  const { t } = useTranslation();
  const gtm = useGoogleTagManager();
  const vehicle = useSelector(getVehicleData);

  const isYoungerThanSixYears =
    moment().diff(vehicle?.dateOfFirstRegistration, 'years') < 6;

  const showDescription = useEffectValue(() => {
    if (!vehicle?.dateOfFirstRegistration) {
      return false;
    }

    return moment().diff(vehicle.dateOfFirstRegistration, 'years') > 5;
  }, [vehicle]);

  useEffect(() => {
    if (value) {
      gtm.push(
        createEvent(ACTION_PARKDAMAGE_UPSELLING_HINT, {
          eventLabel: 'Yes',
        }),
      );

      return;
    }

    if (value === false) {
      gtm.push(
        createEvent(ACTION_PARKDAMAGE_UPSELLING_HINT, {
          eventLabel: 'No',
        }),
      );
    }
  }, [value, gtm]);

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_PARKING_DAMAGE}`)}
      labelHelp={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_PARKING_DAMAGE}-info`)}
      description={
        isYoungerThanSixYears ? (
          <ParkingDamageHintCard />
        ) : showDescription ? (
          ''
        ) : (
          t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_IS_PARKING_DAMAGE}-suggestion`)
        )
      }
      value={value}
      name={ID_IS_PARKING_DAMAGE}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_IS_PARKING_DAMAGE)}
      errorMessage={t(validation.key(ID_IS_PARKING_DAMAGE))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
