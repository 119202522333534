import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_HAS_SUSPENSION_RENUNCIATION } from './const';

export const HasSuspensionRenunciationInput: React.FC<BooleanQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_HAS_SUSPENSION_RENUNCIATION}`,
      )}
      description={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_HAS_SUSPENSION_RENUNCIATION}-description`,
      )}
      value={value}
      name={ID_HAS_SUSPENSION_RENUNCIATION}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_HAS_SUSPENSION_RENUNCIATION)}
      errorMessage={t(validation.key(ID_HAS_SUSPENSION_RENUNCIATION))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
