import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY } from './const';

export const AccidentInsuranceSumPayableAtDisabilityInput: React.FC<
  RadioGroupQuestionProps
> = (props) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((i) => ({
    value: i,
    label: t(
      `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY}-${i.name.toLowerCase()}`,
    ),
  }));

  return (
    <RadioGroupQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY}`,
      )}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY}-description`,
      )}
      name={ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() =>
        validation.isInvalid(ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY)
      }
      errorMessage={t(
        validation.key(ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY),
      )}
      showFeedback={!!value?.key}
    />
  );
};
