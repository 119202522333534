import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_GENDER } from './const';

const translationKeyPrefix = `${NS_STEP_DRIVER}.${ID_GENDER}-`;

export const GenderInput: React.FC<RadioGroupQuestionProps> = (props) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    value: entry,
    label: t(`${translationKeyPrefix}${entry.name.toLowerCase()}`),
  }));

  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_GENDER}`)}
      name={ID_GENDER}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(ID_GENDER)}
      errorMessage={t(validation.key(ID_GENDER))}
      showFeedback={!!value}
    />
  );
};
