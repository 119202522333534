import {
  Flex,
  NumberQuestion,
  SelectQuestion,
  Text,
  TextQuestion,
} from '@scout24ch/fs24-design-system';
import { useOnMount } from '@scout24ch/fs24-hooks';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { captureException } from '@sentry/node';
import {
  ID_CANTON,
  ID_CITY,
  ID_MANUAL_CANTON,
  ID_MANUAL_CITY,
  ID_NAME,
  ID_POST_CODE,
} from 'containers/OfferPage/components/RequestOffer/const';
import { useTranslation } from 'hooks';
import { NS_REQUEST_OFFER } from 'utils/namespace-const';
import { IEvaluatedValidation } from 'utils/validation/types';
import { useSelector } from 'react-redux';
import { getLookupData } from 'state/LookUpData/selectors';
import { getCity } from '../../api/apiClients/locationService';
import { City } from '../../state/InquiryInput/types';

export enum CityInputModifier {
  LABEL = 'label',
}

interface CityInputProps {
  value: number;
  city: City;
  onChange: (event: { name: string; value: Partial<City> }) => void;
  validation: IEvaluatedValidation;
  path: string;
  readOnly?: boolean;
  modifier?: CityInputModifier;
}
export const fetchCities = (
  value: number,
  cities: City[],
  city: City | {},
  getCityByPostCode: (value: number) => void,
) => {
  if (value && (_isEmpty(cities) || _isEmpty(city))) {
    getCityByPostCode(value);
  }
};

export const CityInput: React.FC<CityInputProps> = ({
  value,
  city = {} as City,
  onChange,
  validation,
  path,
  modifier,
}) => {
  const { t } = useTranslation();
  const { cantons = [] } = useSelector(getLookupData);
  const [hasLocationServiceError, setHasLocationServiceError] =
    useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);

  // Fetch cities
  useOnMount(() => {
    fetchCities(value, cities, city, getCityByPostCode);
  });

  const getPostCodeTarget = (newValue: City['postCode']) => {
    const postCodeId = `${path}.${ID_POST_CODE}`;
    return { name: postCodeId, value: newValue as Partial<City> };
  };

  const getCityTarget = (newValue: Partial<City>) => {
    const cityId = `${path}.${ID_CITY}`;
    return { name: cityId, value: newValue };
  };

  const getCityByPostCode = async (postCode: number) => {
    try {
      const data = await getCity(postCode);
      if (Array.isArray(data) && data.length > 0) {
        const foundCity: City = data[0];
        if (hasLocationServiceError) {
          setHasLocationServiceError(false);
        }
        setCities(data);
        onChange(getCityTarget(foundCity));
        onChange(getPostCodeTarget(foundCity?.postCode ?? postCode));
      }
    } catch (error) {
      setCities([]);
      onChange(getCityTarget({ ...city, postCode }));
      if (!hasLocationServiceError) {
        setHasLocationServiceError(true);
      }
      captureException(error);
    }
  };

  const postCodeId = `${path}.${ID_POST_CODE}`;
  const cityId = `${path}.${ID_CITY}`;
  const manualCityId = `${path}.${ID_CITY}.${ID_NAME}`;
  const manualCantonId = `${path}.${ID_CITY}.${ID_CANTON}`;

  const postCodeChange = (value: number) => {
    const newValue = String(value).substring(0, 4);
    if (Number.isNaN(Number(newValue))) {
      return;
    }

    if (newValue.length === 4) {
      getCityByPostCode(Number(newValue));
    }
    onChange(getCityTarget(null));
    onChange(getPostCodeTarget(Number(newValue)));
  };

  if (modifier === CityInputModifier.LABEL) {
    return (
      <Text fontSize="xl">
        {t(`${NS_REQUEST_OFFER}.zipCodeLabel`, {
          postCode: value,
          cityName: city ? city.name : '',
        })}
      </Text>
    );
  }

  return (
    <>
      <Flex gap={4}>
        <NumberQuestion
          width="50%"
          label={t(`${NS_REQUEST_OFFER}.${ID_POST_CODE}`)}
          name={postCodeId}
          thousandSeparator=""
          value={value}
          onValueChange={(value) => postCodeChange(value)}
          onBlur={() => validation.isInvalid(cityId)}
          errorMessage={t(validation.key(cityId))}
          showFeedback={city && cities.length === 1}
        />
        <Text pt={14} fontSize="md">
          {city && cities.length === 1 && `${city.name} ${city.canton}`}
        </Text>
      </Flex>
      {city && cities?.length > 1 && (
        <SelectQuestion
          label={t(`${NS_REQUEST_OFFER}.${ID_CITY}`)}
          onChange={(value) =>
            onChange({
              name: cityId,
              value: value as Partial<City>,
            })
          }
          options={cities.map((entry) => ({
            label: entry.name,
            value: entry,
          }))}
          value={city}
          name={cityId}
          onBlur={() => validation.isInvalid(cityId)}
          showFeedback={!!city?.canton}
        />
      )}
      {hasLocationServiceError && (
        <>
          <TextQuestion
            label={t(`${NS_REQUEST_OFFER}.${ID_MANUAL_CITY}`)}
            name={manualCityId}
            value={city?.name ?? ''}
            onValueChange={(cityVal) => {
              onChange(getCityTarget({ ...city, name: cityVal }));
            }}
            errorMessage={t(validation.key(manualCityId))}
            onBlur={() => validation.isInvalid(manualCityId)}
            showFeedback={!!city?.name}
          />
          <SelectQuestion
            label={t(`${NS_REQUEST_OFFER}.${ID_MANUAL_CANTON}`)}
            onChange={(value) =>
              onChange(getCityTarget({ ...city, canton: String(value) }))
            }
            options={cantons.map((entry) => ({
              value: entry.name,
              disabled: entry.name === 'None',
              label: t(
                `step:usageDetails.registrationCanton-${entry.name.toLowerCase()}`,
              ),
            }))}
            value={city?.canton || cantons[0].name}
            name={manualCantonId}
            onBlur={() => validation.isInvalid(manualCantonId)}
            errorMessage={t(validation.key(manualCantonId))}
          />
        </>
      )}
    </>
  );
};

CityInput.displayName = 'CityInput';
