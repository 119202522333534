import React from 'react';
import { NextPageContext } from 'next';
import { Store } from 'redux';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useRouter } from 'next/router';
import InquiryPage from 'containers/InquiryPage';
import {
  fetchVehicleMakesFailed,
  fetchVehicleMakesFulfilled,
} from 'state/VehicleMakes/actions';
import { SearchFlow } from 'utils/types';
import { RootState } from '../state/reducers';
import { createLongInquiry } from '../state/InquiryInput/actions';
import { STEP_VEHICLE } from '../utils/namespace-const';
import { getParamFromQuery } from '../utils/routerUtil';
import { PARAM_MAKE, PARAM_VEHICLE_ID } from '../utils/queryparam-const';
import { fetchVehicleFulfilled } from '../state/VehicleData/actions';
import { Vehicle } from '../state/InquiryInput/types';
import { goToError } from '../utils/navigationUtil';
import { captureMessage } from '../utils/sentry';
import { getVehicleById, getVehicleMakes } from '../api/vehicles';

interface InquiryProps {
  navigateToErrorPage?: boolean;
}

interface InquiryContext extends NextPageContext {
  reduxStore: Store<RootState>;
}

const Inquiry = (props: InquiryProps): React.ReactElement => {
  const { navigateToErrorPage } = props;
  const router = useRouter();

  useOnMount(() => {
    if (navigateToErrorPage) {
      goToError(router);
      return;
    }
  });

  return <InquiryPage />;
};

Inquiry.getInitialProps = async (ctx: InquiryContext) => {
  const { reduxStore } = ctx;

  /** Create an empty inquiry */
  const state = reduxStore.getState();
  if (!state.inquiryInput.inputs) {
    reduxStore.dispatch(createLongInquiry());
  }

  /** Fetch makes */
  try {
    const makes = await getVehicleMakes();
    reduxStore.dispatch(fetchVehicleMakesFulfilled(makes));
  } catch (error) {
    reduxStore.dispatch(fetchVehicleMakesFailed(error));
  }

  /** Fetch vehicle data */
  const pageProps = await fetchVehicleData(ctx);
  return pageProps;
};

const fetchVehicleData = async (ctx: InquiryContext): Promise<InquiryProps> => {
  const { reduxStore, query, res } = ctx;
  const state = reduxStore.getState();

  const pageProps: InquiryProps = {};
  if (state.inquiryInput.step.index !== STEP_VEHICLE.index) {
    return pageProps;
  }

  const vehicleId = getParamFromQuery(query, PARAM_VEHICLE_ID);
  const makeName = getParamFromQuery(query, PARAM_MAKE);

  if (
    vehicleId &&
    (!state.vehicleData.vehicle ||
      state.vehicleData.vehicle.as24VehicleId !== vehicleId)
  ) {
    try {
      const id = Number(vehicleId);
      if (!Number.isInteger(id)) {
        captureMessage(`Inquiry page: Invalid vehicleId = ${id}`, 'log');
      }

      let vehicleData: Vehicle = {};
      try {
        vehicleData = await getVehicleById(id);
      } catch (error) {
        if (error.response?.status === 404) {
          if (res) {
            const responseStatusCode = 410;
            captureMessage(
              `Vehicle fetch failed - vehicleId is not valid anymore. vehicleId = ${vehicleId}`,
              'log',
            );

            res.statusCode = responseStatusCode;
            return pageProps;
          }
        } else if (error.response?.status === 500) {
          if (res) {
            res.statusCode = error.response?.status;
            throw new Error(error.response?.status.toString());
          }
        }
      }

      // if it is AS24 flow with car in the query string we should set 'searchType' to 'BRAND'
      reduxStore.dispatch(
        fetchVehicleFulfilled({ ...vehicleData, searchType: SearchFlow.BRAND }),
      );
    } catch (error) {
      captureMessage(error, 'log');
    }
  } else if (makeName) {
    const vehicleData = { makeName };

    // if it is AS24 flow with car in the query string we should set 'searchType' to 'BRAND'
    // NOTE: changed (temp?) to car plate search by default - https://smg-central.atlassian.net/browse/AGGR-4751
    reduxStore.dispatch(
      fetchVehicleFulfilled({
        ...vehicleData,
        searchType: SearchFlow.LICENSE_PLATE_NUMBER,
      }),
    );
  }

  return pageProps;
};

export default Inquiry;
