import React from 'react';
import {
  Button,
  Flex,
  Image,
  Text,
  fs24,
  useDisclosure,
} from '@scout24ch/fs24-design-system';
import { Closing } from '@scout24ch/fs24-design-system/icons/24';
import { useTranslation } from 'hooks';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getVehicleData } from 'state/VehicleData/selectors';
import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import {
  ACTION_PARKDAMAGE_UPSELLING_HINT,
  createEvent,
} from 'utils/GTM/events';
import ParkingDamageIllustration from './parking-damage-illustration.png';

export const ParkingDamageHintCard: React.FC = () => {
  const { t } = useTranslation();
  const gtm = useGoogleTagManager();
  const vehicle = useSelector(getVehicleData);
  const { isOpen: isVisible, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  function onIconClose() {
    gtm.push(
      createEvent(ACTION_PARKDAMAGE_UPSELLING_HINT, { eventLabel: 'Close' }),
    );
    onClose();
  }

  return isVisible ? (
    <fs24.div
      bgColor="grey.100"
      borderRadius="md"
      position="relative"
      px={4}
      pt={6}
      pb={2}
    >
      <Button
        minH={6}
        position="absolute"
        right={3}
        top={3}
        aria-label="Adjust car step vehicle"
        variant="unstyled"
        onClick={onIconClose}
      >
        <Closing color="grey.900" />
      </Button>
      <Text fontSize="xl" pb={1} mr={5}>
        {t(`step:insuranceOptions.isParkingDamage-hintTitle`)}
      </Text>
      <Flex>
        <Text fontSize="sm">
          <Trans
            i18nKey={`step:insuranceOptions.isParkingDamage-hintText`}
            values={{ brand: vehicle?.makeName }}
            components={{ bold: <strong /> }}
          />
        </Text>
        <Image pt={6} src={ParkingDamageIllustration.src} w={126} />
      </Flex>
    </fs24.div>
  ) : null;
};
