import React, { useState, ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { goToPremiums } from 'utils/navigationUtil';
import { LoadingIndicatorHolder } from './Loader';
import { getSaveInsuranceInquiry } from '../state/InsuranceInquiry/selectors';

export const withAutomaticRedirectToPremiums = (
  Component: ComponentType,
): React.FC => {
  const WrappedComponent = (props) => {
    const {
      savingInsuranceInquiry,
      savingInsuranceInquiryFailed,
      insuranceInquiryId,
    } = useSelector(getSaveInsuranceInquiry);

    const [showLoadingIndicator, setLoadingIndicator] = useState(false);
    const router = useRouter();

    useEffect(() => {
      if (savingInsuranceInquiry) {
        setLoadingIndicator(true);
      }
    }, [savingInsuranceInquiry]);

    useEffect(() => {
      if (
        savingInsuranceInquiry !== showLoadingIndicator &&
        !savingInsuranceInquiry &&
        !savingInsuranceInquiryFailed &&
        insuranceInquiryId
      ) {
        goToPremiums(insuranceInquiryId, router);
      }
    }, [
      showLoadingIndicator,
      insuranceInquiryId,
      router,
      savingInsuranceInquiry,
      savingInsuranceInquiryFailed,
    ]);

    if (showLoadingIndicator) {
      return (
        <LoadingIndicatorHolder
          delayed
          text={`${NS_STEP_INCIDENTS}.saving`}
          isLogoLoader
        />
      );
    }

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `WithAutomaticRedirectToPremiumsHOC(${
    Component.displayName || Component.name || 'Anonymous'
  })`;

  return WrappedComponent;
};
