import React, { useState } from 'react';
import { TextQuestion } from '@scout24ch/fs24-design-system';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useTranslation } from 'hooks';
import { NS_STEP_DRIVER } from 'utils/namespace-const';
import { TextQuestionProps } from 'interfaces';
import { ID_DRIVER_EMAIL_ADDRESS } from './const';
import { isEmailValid } from '../../../../../utils/validation/emailValidation';

export const DriverEmailAddressInput: React.FC<TextQuestionProps> = (props) => {
  const { t } = useTranslation();
  const { value, validation, onChange } = props;
  const [isValid, setValid] = useState(false);

  useOnMount(() => {
    const isValid = async () => {
      setValid(await isEmailValid(value));
    };

    isValid();
  });

  const handleOnChange = async (value: string) => {
    setValid(await isEmailValid(value));

    onChange(value);
  };

  return (
    <TextQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVER_EMAIL_ADDRESS}`)}
      name={ID_DRIVER_EMAIL_ADDRESS}
      errorMessage={t(validation.key(ID_DRIVER_EMAIL_ADDRESS))}
      value={value}
      onValueChange={handleOnChange}
      showFeedback={isValid}
    />
  );
};
