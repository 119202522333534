import React, { useMemo } from 'react';
import {
  Checkbox,
  Flex,
  fs24,
  Question,
  Stack,
  Text,
} from '@scout24ch/fs24-design-system';
import {
  HouseHome,
  CalendarCar,
  Building3,
} from '@scout24ch/fs24-design-system/icons/24';
import { useTranslation } from 'hooks';
import EvaluatedValidation from 'utils/EvaluatedValidation';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import {
  ID_BUSINESS_USAGE,
  ID_COMMUTE_USAGE,
  ID_PRIVATE_USAGE,
  ID_USAGE,
} from './const';

interface UsageInputProps {
  onChange: (arg: any) => void;
  validation: EvaluatedValidation;
  privateUsage: boolean;
  businessUsage: boolean;
  commuteUsage: boolean;
}

export const UsageInput: React.FC<UsageInputProps> = (props) => {
  const {
    privateUsage = false,
    businessUsage = false,
    commuteUsage = false,
    onChange,
    validation,
  } = props;
  const { t } = useTranslation();
  const values = [
    {
      id: ID_PRIVATE_USAGE,
      value: privateUsage,
      icon: <HouseHome />,
    },
    {
      id: ID_COMMUTE_USAGE,
      value: commuteUsage,
      icon: <CalendarCar />,
    },
    {
      id: ID_BUSINESS_USAGE,
      value: businessUsage,
      icon: <Building3 />,
    },
  ];

  const handleOnChange = (item) => {
    onChange({
      name: item.id,
      value: !item.selected,
    });
  };

  const items = values.map((key) => ({
    id: key.id,
    text: (
      <Flex>
        {key.icon}
        <fs24.span ml={2}>
          {t(`${NS_STEP_VEHICLE}.${ID_USAGE}-${key.id}`)}
        </fs24.span>
      </Flex>
    ),
    selected: key.value,
  }));

  const isValid = useMemo(
    () => !!items.find((item) => item.selected === true),
    [items],
  );

  return (
    <Question
      name={ID_USAGE}
      label={t(`${NS_STEP_VEHICLE}.${ID_USAGE}`)}
      labelHelp={t(`${NS_STEP_VEHICLE}.${ID_USAGE}-tooltip`)}
      showFeedback={isValid}
    >
      <Stack mt={2} spacing={3}>
        {items.map((item) => (
          <Checkbox
            mb={0}
            key={item.id}
            name={item.id}
            id={item.id}
            isChecked={item.selected}
            onChange={() => handleOnChange(item)}
          >
            {item.text}
          </Checkbox>
        ))}
      </Stack>
      {validation.isInvalid(ID_USAGE) && (
        <Text color="info.red" className="chakra-form__error-message">
          {t(validation.key(ID_USAGE))}
        </Text>
      )}
    </Question>
  );
};
