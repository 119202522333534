import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { getInquiryInputs } from 'state/selectors';
import EvaluatedValidation from '../../../../../../utils/EvaluatedValidation';
import { ID_YEAR_OF_PURCHASE } from '../const';
import { HasPurchasedInput } from '../HasPurchasedInput';
import { YearInput } from '../YearInput';

interface YearBoughtInputProps {
  validation: EvaluatedValidation;
}

/**
 * AGGR-1898
 * Added additional input field (HasPurchasedInput) to year of purchase question
 * We don't want to add another field to backend/db, so as a result of that,
 * this function will map the value so the yes/no input stays checked
 * even though expectedPurchaseYear changes on the YearInput dropdown
 * @param value [number || null]
 */
const hasPurchasedInputValue = (value: number | null): number | null => {
  return [null, -1].includes(value) ? value : 0;
};

export const YearBoughtInput: React.FC<YearBoughtInputProps> = (props) => {
  const { validation } = props;
  const { usageDetails } = useSelector(getInquiryInputs);
  const dispatch = useDispatch();

  const onChange = (name: string, value: any) => {
    usageDetails[name] = value;
    dispatch(inquiryInputChanged(usageDetails, null));
  };

  return (
    <>
      <HasPurchasedInput
        value={hasPurchasedInputValue(usageDetails.expectedPurchaseYear)}
        onChange={(value) => onChange('expectedPurchaseYear', value)}
        validation={validation}
      />
      {usageDetails.expectedPurchaseYear !== -1 &&
        usageDetails.expectedPurchaseYear !== null && (
          <YearInput
            value={usageDetails.expectedPurchaseYear}
            validation={validation}
            //@ts-ignore
            onChange={(value: number) => {
              onChange(ID_YEAR_OF_PURCHASE, value);
            }}
          />
        )}
    </>
  );
};
