import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { LoadingIndicatorHolder } from 'components/Loader';
import { getLookupData } from 'state/LookUpData/selectors';
import { getInquiryStep } from 'state/InquiryInput/selectors';
import { getQueryParam } from 'utils/routerUtil';
import { PARAM_VEHICLE_ID } from 'utils/queryparam-const';
import {
  STEP_DRIVER,
  STEP_FINISH,
  STEP_INCIDENTS,
  STEP_INSURANCE_OPTIONS,
  STEP_VEHICLE,
} from 'utils/namespace-const';
import {
  changeStepWithValidation,
  requestChangeStep,
} from 'state/InquiryInput/actions';
import { goToFinanceScout } from 'utils/navigationUtil';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { getIsAdjustingData } from 'state/AdjustingData/selectors';
import { Step } from 'types/funnel';
import { createStepDriver } from './StepDriver/StepDriverFactory';
import { createStepInsuranceOptions } from './StepInsuranceOptions/StepInsuranceOptionsFactory';
import { createStepIncidents } from './StepIncidents/StepIncidentsFactory';
import { createStepVehicle } from './StepVehicle/StepVehicleFactory';

export function Steps(): React.ReactElement {
  const router = useRouter();
  const dispatch = useDispatch();
  const lookUpData = useSelector(getLookupData);
  const step = useSelector(getInquiryStep);
  const isAdjustingData = useSelector(getIsAdjustingData);
  const vehicleId = getQueryParam(router, PARAM_VEHICLE_ID);
  const abredirect = getQueryParam(router, 'abredirect'); // TODO: remove after AGGR-4752 is done (FeatureFlag.PortalAS24VehicleRedirect)

  function goToStepFinish(currentStep: Step) {
    dispatch(requestChangeStep(STEP_FINISH, currentStep));
  }

  if (!lookUpData) {
    return <LoadingIndicatorHolder />;
  }

  if (step.name === STEP_VEHICLE.name) {
    return createStepVehicle(
      () => dispatch(requestChangeStep(STEP_DRIVER, STEP_VEHICLE)),
      () => {
        goToFinanceScout(router, vehicleId);
      },
      IS_AUTO_INSURANCE,
      () => goToStepFinish(STEP_VEHICLE),
      isAdjustingData,
      vehicleId,
      abredirect, // TODO: remove after AGGR-4752 is done (FeatureFlag.PortalAS24VehicleRedirect)
    );
  }

  if (step.name === STEP_DRIVER.name) {
    return createStepDriver(
      () => dispatch(requestChangeStep(STEP_INSURANCE_OPTIONS, STEP_DRIVER)),
      () => dispatch(changeStepWithValidation(STEP_VEHICLE, STEP_DRIVER)),
      IS_AUTO_INSURANCE,
      () => goToStepFinish(STEP_DRIVER),
      isAdjustingData,
    );
  }

  if (step.name === STEP_INSURANCE_OPTIONS.name) {
    return createStepInsuranceOptions(
      () => dispatch(requestChangeStep(STEP_INCIDENTS, STEP_INSURANCE_OPTIONS)),
      () =>
        dispatch(changeStepWithValidation(STEP_DRIVER, STEP_INSURANCE_OPTIONS)),
      IS_AUTO_INSURANCE,
      () => goToStepFinish(STEP_INSURANCE_OPTIONS),
      isAdjustingData,
    );
  }

  if (step.name === STEP_INCIDENTS.name) {
    return createStepIncidents(
      () => goToStepFinish(STEP_INCIDENTS),
      () =>
        dispatch(
          changeStepWithValidation(STEP_INSURANCE_OPTIONS, STEP_INCIDENTS),
        ),
      IS_AUTO_INSURANCE,
    );
  }

  return null;
}
