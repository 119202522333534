import React from 'react';
import { useSelector } from 'react-redux';
import { getLookupData } from 'state/LookUpData/selectors';
import { InfringementReasonInput } from './InfringementReasonInput';
import ListInputForm from './ListInputForm';
import MonthYearInput from './MonthYearInput';
import { ID_INFRINGEMENT_DATE } from './const';

export interface InfringementInputProps {
  index: number;
  infringement: any;
  validation: any;
  onChange: (item: any) => void;
}

export const InfringementInput: React.FC<InfringementInputProps> = (props) => {
  const { index, infringement, validation, onChange } = props;
  const lookupData = useSelector(getLookupData);

  return (
    <ListInputForm index={index}>
      <InfringementReasonInput
        index={index}
        value={infringement.infringementReason}
        validation={validation}
        lookupData={lookupData.infringementReasons}
        onChange={(value) => onChange({ name: 'infringementReason', value })}
      />
      <MonthYearInput
        id={ID_INFRINGEMENT_DATE}
        propName="date"
        value={infringement.date}
        onChange={onChange}
        validation={validation}
      />
    </ListInputForm>
  );
};

export default InfringementInput;
