import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import EvaluatedValidation from '../../../../../utils/EvaluatedValidation';
import {
  NS_STEP_COMMON,
  NS_VEHICLE_SELECTION,
} from '../../../../../utils/namespace-const';
import { VEHICLE_SELECTION } from './const';

interface HasPurchasedProps {
  value: number | null;
  onChange: (value: number) => void;
  validation: EvaluatedValidation;
}

export const HasPurchasedInput: React.FC<HasPurchasedProps> = (props) => {
  const { onChange, value, validation } = props;
  const { t } = useTranslation();

  const options = [
    {
      value: 0,
      label: t(`${NS_STEP_COMMON}.yes`),
    },
    {
      value: -1,
      label: t(`${NS_STEP_COMMON}.no`),
    },
  ];

  return (
    <RadioGroupQuestion
      label={t(`${VEHICLE_SELECTION}.hasPurchased`)}
      name="expectedPurchaseYear"
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid('expectedPurchaseYear')}
      errorMessage={t(
        validation.key('expectedPurchaseYear', NS_VEHICLE_SELECTION),
      )}
      showFeedback={[0, -1].includes(value)}
    />
  );
};
