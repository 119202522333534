import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { BooleanQuestionProps } from 'interfaces';
import { ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS } from './const';

export const CancellationOrSpecialConditionsInput: React.FC<
  BooleanQuestionProps
> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(
        `${NS_STEP_INCIDENTS}.${ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS}`,
      )}
      value={value}
      name={ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS}
      onChange={onChange}
      onBlur={() =>
        validation.isInvalid(ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS)
      }
      errorMessage={t(validation.key(ID_IS_CANCELLATION_OR_SPECIAL_CONDITIONS))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
