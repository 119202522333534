import {
  Alert,
  Button,
  fs24,
  TextQuestion,
} from '@scout24ch/fs24-design-system';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Search } from '@scout24ch/fs24-design-system/icons/24';
import { IEvaluatedValidation } from 'utils/validation/types';
import { ID_LICENSE_PLATE_NUMBER } from '../const';
import { inquiryInputChanged } from '../../../../../../state/InquiryInput/actions';
import { VehicleSuggestion } from '../../types';
import { useNumberPlateSearch } from './hooks/useNumberPlateSearch';

interface NumberPlateInputProps {
  validation: IEvaluatedValidation;
  value: string;
  handleNumberPlateSearch: ({
    vehicles,
    make,
    typeName,
    firstRegistrationMonth,
    firstRegistrationYear,
  }: {
    vehicles: VehicleSuggestion[];
    make: string;
    typeName: string;
    firstRegistrationMonth?: number;
    firstRegistrationYear?: number;
  }) => void;
  resetVehicle: () => void;
}

export function NumberPlateInput(props: NumberPlateInputProps): JSX.Element {
  const { value, validation, handleNumberPlateSearch, resetVehicle } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const serachInput = useRef<HTMLInputElement>(null);

  const {
    errorMessage,
    failedToFetch,
    isLicensePlateNumberValid,
    handleSearchByLicensePlate,
  } = useNumberPlateSearch(value);

  async function onFindCarByLicenseNumberClick() {
    try {
      const { vehicles, make_name, model_name, month, year } =
        await handleSearchByLicensePlate();

      serachInput?.current?.scrollIntoView({ behavior: 'smooth' });

      handleNumberPlateSearch({
        vehicles,
        make: make_name,
        typeName: model_name,
        firstRegistrationMonth: Number(month),
        firstRegistrationYear: Number(year),
      });
    } catch (error) {}
  }

  function handleNumberChange(value: string) {
    resetVehicle();
    dispatch(
      inquiryInputChanged(
        { [ID_LICENSE_PLATE_NUMBER]: value.toUpperCase() },
        'vehicle',
      ),
    );
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && isLicensePlateNumberValid) {
      onFindCarByLicenseNumberClick();
    }
  }

  return (
    <div ref={serachInput}>
      <TextQuestion
        label={t('step:usageDetails.searchType-numberplate')}
        placeholder={t('step:usageDetails.searchType-numberplateph')}
        name={ID_LICENSE_PLATE_NUMBER}
        value={value ?? ''}
        onBlur={() => validation.isInvalid(ID_LICENSE_PLATE_NUMBER)}
        errorMessage={t(validation.key(ID_LICENSE_PLATE_NUMBER))}
        showFeedback={isLicensePlateNumberValid}
        onValueChange={handleNumberChange}
        onKeyPress={handleKeyPress}
        mb={3}
      />

      {errorMessage ? (
        <Alert my={4} status="error">
          {errorMessage}
        </Alert>
      ) : null}

      {failedToFetch ? (
        <Alert my={4} status="error">
          {t('common:common.serverError')}
        </Alert>
      ) : null}

      <Button
        isDisabled={!isLicensePlateNumberValid}
        onClick={onFindCarByLicenseNumberClick}
        w="full"
        variant="secondary"
      >
        <Search />
        <fs24.span ml={1}>
          {t('step:usageDetails.licensePlateNumber-button')}
        </fs24.span>
      </Button>
    </div>
  );
}
