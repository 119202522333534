import React from 'react';
import { SelectQuestion } from '@scout24ch/fs24-design-system';
import { range } from '@scout24ch/fs24-utils';
import { useTranslation } from 'hooks';
import { NS_STEP_DRIVER } from 'utils/namespace-const';
import { SelectQuestionProps } from 'interfaces';
import { ID_NUMBER_OF_CHILDREN } from './const';

export type NumberOfChildrenInputProps = Omit<
  SelectQuestionProps,
  'lookupData'
>;

export const NumberOfChildrenInput: React.FC<NumberOfChildrenInputProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  const options = [
    {
      value: -1,
      label: t(`${NS_STEP_DRIVER}.number-of-children-no-information`),
      disabled: true,
    },
    ...range(6, {
      project: (n) => ({
        label: String(n),
        value: n,
      }),
    }),
  ];

  return (
    <SelectQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_NUMBER_OF_CHILDREN}`)}
      labelHelp={t(`${NS_STEP_DRIVER}.${ID_NUMBER_OF_CHILDREN}-tooltip`)}
      name={ID_NUMBER_OF_CHILDREN}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(ID_NUMBER_OF_CHILDREN)}
      errorMessage={t(validation.key(ID_NUMBER_OF_CHILDREN))}
      showFeedback={value > -1}
    />
  );
};
