import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_VEHICLE } from '../../../../../utils/namespace-const';
import { ID_LEASING } from './const';

export const LeasingInput: React.FC<BooleanQuestionProps> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_VEHICLE}.${ID_LEASING}`)}
      labelHelp={t(`${NS_STEP_VEHICLE}.${ID_LEASING}-tooltip`)}
      value={value}
      name={ID_LEASING}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_LEASING)}
      errorMessage={t(validation.key(ID_LEASING))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
