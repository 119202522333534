import { fs24, Link } from '@scout24ch/fs24-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NS_REQUEST_OFFER } from 'utils/namespace-const';

export const TermsAndConditionsInput: React.FC = () => {
  const { t } = useTranslation();

  return (
    <fs24.div mb={3}>
      <p>
        {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-start`)}{' '}
        <Link
          href={t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-informationUrl`)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-informationText`)}
        </Link>{' '}
        {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-mid`)}{' '}
        <Link
          href={t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-dataProtectionUrl`)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-dataProtectionText`)}
        </Link>{' '}
        {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-end`)}{' '}
        <Link
          href={t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-termsUrl`)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${NS_REQUEST_OFFER}.acceptPrivacyPolicy-termsLinkText`)}
        </Link>
        .
      </p>
    </fs24.div>
  );
};
