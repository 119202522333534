import React from 'react';
import { useSelector } from 'react-redux';
import { fs24, Stepper } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_COMMON } from 'utils/namespace-const';
import { getInquiry } from 'state/selectors';
import { INSURANCE_INQUIRY_STEPS } from '../../../../../utils/const';

const StepperHolder: React.FC = () => {
  const { t } = useTranslation();
  const { step, stepReached } = useSelector(getInquiry);

  const stepLabels = INSURANCE_INQUIRY_STEPS.map((step) =>
    t(`${NS_STEP_COMMON}.${step.name}-short`),
  );
  return step.index === 0 ? null : (
    <fs24.div my={[6, null, 8]}>
      <Stepper
        stepLabels={stepLabels}
        currentStep={step.index || stepReached}
      />
    </fs24.div>
  );
};

export default StepperHolder;
