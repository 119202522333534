import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE } from './const';

export const DriversWithLearningOrTrialLicenseInput: React.FC<
  BooleanQuestionProps
> = (props) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(
        `${NS_STEP_DRIVER}.${ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE}`,
      )}
      value={value}
      name={ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE}
      onChange={onChange}
      onBlur={() =>
        validation.isInvalid(ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE)
      }
      errorMessage={t(
        validation.key(ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE),
      )}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
