import React from 'react';
import { useSelector } from 'react-redux';
import { getLookupData } from 'state/LookUpData/selectors';
import { SuspensionPeriodInput } from './SuspensionPeriodInput';
import ListInputForm from './ListInputForm';
import MonthYearInput from './MonthYearInput';
import { ID_SUSPENSION_DATE } from './const';

export interface SuspensionInputProps {
  index: number;
  suspension: any;
  validation: any;
  onChange: (item: any) => void;
}

export const SuspensionInput: React.FC<SuspensionInputProps> = (props) => {
  const { index, suspension, validation, onChange } = props;
  const lookupData = useSelector(getLookupData);
  return (
    <ListInputForm index={index}>
      <SuspensionPeriodInput
        index={index}
        value={suspension.suspensionPeriod}
        validation={validation}
        lookupData={lookupData.suspensionPeriods}
        onChange={(value) => {
          onChange({ name: 'suspensionPeriod', value });
        }}
      />
      <MonthYearInput
        id={ID_SUSPENSION_DATE}
        propName="date"
        value={suspension.date}
        onChange={onChange}
        validation={validation}
      />
    </ListInputForm>
  );
};
