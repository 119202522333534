import React from 'react';
import { QuestionStack, Text } from '@scout24ch/fs24-design-system';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_INSURANCE_OPTIONS } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
  getVehicleData,
} from 'state/selectors';
import { useTranslation, useValidation } from 'hooks';
import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';
import { InsuranceOptions } from 'state/InquiryInput/types';
import { useUpdateEmailHash } from 'hooks/useUpdateEmailHash';
import { getLookupData } from 'state/LookUpData/selectors';
import { StepButtons } from '../StepButtons';
import {
  AccidentInsuranceDailyAllowanceInput,
  AccidentInsuranceInput,
  AccidentInsuranceSumPayableAtDeathInput,
  AccidentInsuranceSumPayableAtDisabilityInput,
  AccidentInsuredPersonsInput,
  BonusProtectionInput,
  CarriedThingsInsuranceSumInput,
  CurrentInsuranceCompanyInput,
  ExistingParkingDamageInput,
  FreeGarageChoiceInput,
  FullyComprehensiveInsuranceCostSharingInput,
  HasCurrentInsuranceCompanyInput,
  InsuranceTypeInput,
  ParkingDamageInfiniteInput,
  ParkingDamageInput,
  PartialCoverInsuranceCostSharingInput,
} from './components';
import {
  ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE,
  ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DEATH,
  ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY,
  ID_ACCIDENT_INSURED_PERSONS,
  ID_CARRIED_THINGS_INSURANCE_SUM,
  ID_CURRENT_INSURANCE,
  ID_FREE_GARAGE_CHOICE,
  ID_FULLY_COMPREHENSIVE,
  ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING,
  ID_HAS_EXISTING_PARKING_DAMAGE,
  ID_IS_ACCIDENT_INSURANCE,
  ID_IS_BONUS_PROTECTION,
  ID_IS_PARKING_DAMAGE,
  ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE,
  ID_PARTIAL_COVER,
  ID_PARTIAL_COVER_INSURANCE_COST_SHARING,
} from './components/const';
import {
  getHasPreviousInsuranceState,
  staticInsuranceCompanyValues,
} from './helpers/insuranceOptionsUtils';

export interface StepInsuranceOptionsProps {
  nextStep: () => void;
  previousStep: () => void;
  lastStep: () => void;
  isAdjustingData: boolean;
}

function resetPartialInsurance(insuranceOptions: InsuranceOptions) {
  insuranceOptions[ID_CARRIED_THINGS_INSURANCE_SUM] = null;
  insuranceOptions[ID_PARTIAL_COVER_INSURANCE_COST_SHARING] =
    defaultLookupValue;
  insuranceOptions[ID_FREE_GARAGE_CHOICE] = defaultLookupValue;
}

function resetFullInsurance(insuranceOptions: InsuranceOptions) {
  insuranceOptions[ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING] =
    defaultLookupValue;
}

function resetAccidentInsuranceFields(insuranceOptions: InsuranceOptions) {
  insuranceOptions[ID_ACCIDENT_INSURED_PERSONS] = defaultLookupValue;
  insuranceOptions[ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DEATH] = null;
  insuranceOptions[ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY] = null;
  insuranceOptions[ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE] = null;
}

function resetFieldsOnFullAndPartialDeselect(
  insuranceOptions: InsuranceOptions,
) {
  insuranceOptions[ID_CARRIED_THINGS_INSURANCE_SUM] = null;
  insuranceOptions[ID_IS_PARKING_DAMAGE] = null;
  insuranceOptions[ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE] = null;
  insuranceOptions[ID_HAS_EXISTING_PARKING_DAMAGE] = null;
  resetAccidentInsuranceFields(insuranceOptions);
}

export const StepInsuranceOptions: React.FC<StepInsuranceOptionsProps> = (
  props,
) => {
  const { isAdjustingData, nextStep, previousStep, lastStep } = props;
  const dispatch = useDispatch();
  const lookupData = useSelector(getLookupData);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const {
    usageDetails: { leasing },
    insuranceOptions,
  } = useSelector(getInquiryInputs);
  const vehicle = useSelector(getVehicleData);
  const { t } = useTranslation();
  const validation = useValidation(
    NS_STEP_INSURANCE_OPTIONS,
    getInquiryValidation,
  );

  // set/update cookies for email hash after email is set in previous step
  useUpdateEmailHash();

  const [hasPreviousInsurance, setHasPreviousInsurance] = React.useState(
    getHasPreviousInsuranceState(insuranceOptions.currentInsuranceCompany),
  );

  const onChange = (name: string, value: any) => {
    insuranceOptions[name] = value;

    // reset values of the fields of "isPartialCoverInsurance" if it was first selected and it own fields changed
    // and user maybe deselects it then we should reset it's values back as not being touched
    if (name === ID_PARTIAL_COVER && value === false) {
      resetPartialInsurance(insuranceOptions);
    }

    // reset values of the fields of "isFullyComprehensiveInsurance" if it was first selected and it own fields changed
    // and user maybe deselects it then we should reset it's values back as not being touched
    if (name === ID_FULLY_COMPREHENSIVE && value === false) {
      resetFullInsurance(insuranceOptions);
    }

    // if partial coverage just got unchecked and full coverage is already unchecked then reset fields that appear if they were previously selected/chosen
    if (name === ID_PARTIAL_COVER && value === false) {
      if (!insuranceOptions.isFullyComprehensiveInsurance) {
        resetFieldsOnFullAndPartialDeselect(insuranceOptions);
      }
    }

    // if full coverage just got unchecked and partial coverage is already unchecked then reset fields that appear if they were previously selected/chosen
    if (name === ID_FULLY_COMPREHENSIVE && value === false) {
      if (!insuranceOptions.isPartialCoverInsurance) {
        resetFieldsOnFullAndPartialDeselect(insuranceOptions);
      }
    }

    // reset fields that appear after incident insurance is selected and then deselected
    if (name === ID_IS_ACCIDENT_INSURANCE && value === false) {
      resetAccidentInsuranceFields(insuranceOptions);
    }

    dispatch(inquiryInputChanged(insuranceOptions, null));
  };

  const showCarriedThingsInsuranceInput =
    insuranceOptions.isPartialCoverInsurance ||
    insuranceOptions.isFullyComprehensiveInsurance;

  const parkingDamageInput = () => {
    if (
      insuranceOptions.isPartialCoverInsurance ||
      insuranceOptions.isFullyComprehensiveInsurance
    ) {
      return (
        <>
          <ParkingDamageInput
            value={insuranceOptions.isParkingDamage}
            validation={validation}
            onChange={(value) => onChange(ID_IS_PARKING_DAMAGE, value)}
          />
          {insuranceOptions.isParkingDamage && (
            <>
              <ParkingDamageInfiniteInput
                value={insuranceOptions.isParkingDamageSumUnlimited}
                validation={validation}
                onChange={(value) =>
                  onChange(ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE, value)
                }
              />
              <ExistingParkingDamageInput
                value={insuranceOptions.hasExistingParkingDamage}
                validation={validation}
                onChange={(value) =>
                  onChange(ID_HAS_EXISTING_PARKING_DAMAGE, value)
                }
              />
            </>
          )}
        </>
      );
    }

    return null;
  };

  const vehicleOlderThan7 =
    moment().diff(vehicle?.dateOfFirstRegistration, 'years') > 7;

  return (
    <QuestionStack as="form">
      <InsuranceTypeInput
        liabilityInsurance={insuranceOptions.isLiabilityInsurance}
        partialCoverInsurance={insuranceOptions.isPartialCoverInsurance}
        fullyComprehensiveInsurance={
          insuranceOptions.isFullyComprehensiveInsurance
        }
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
        disabled={leasing}
        partialCoverSuggested={!leasing && vehicleOlderThan7}
        fullyComprehensiveSuggested={!leasing && !vehicleOlderThan7}
      />
      {insuranceOptions.isPartialCoverInsurance && (
        <PartialCoverInsuranceCostSharingInput
          value={insuranceOptions.partialCoverInsuranceCostSharing}
          validation={validation}
          lookupData={lookupData.partialCoverCostSharings}
          onChange={(value) =>
            onChange(ID_PARTIAL_COVER_INSURANCE_COST_SHARING, value)
          }
        />
      )}
      {insuranceOptions.isPartialCoverInsurance && (
        <FreeGarageChoiceInput
          value={insuranceOptions.freeGarageChoice}
          validation={validation}
          lookupData={lookupData.freeGarageChoices}
          onChange={(value) => onChange(ID_FREE_GARAGE_CHOICE, value)}
        />
      )}
      {insuranceOptions.isFullyComprehensiveInsurance && (
        <FullyComprehensiveInsuranceCostSharingInput
          value={insuranceOptions.fullyComprehensiveInsuranceCostSharing}
          validation={validation}
          lookupData={lookupData.fullyComprehensiveCostSharings}
          onChange={(value) =>
            onChange(ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING, value)
          }
        />
      )}
      <Text fontSize="xl">
        {t(`${NS_STEP_INSURANCE_OPTIONS}.additional-title`)}
      </Text>
      <BonusProtectionInput
        value={insuranceOptions.isBonusProtection}
        validation={validation}
        onChange={(value) => onChange(ID_IS_BONUS_PROTECTION, value)}
      />
      {showCarriedThingsInsuranceInput && (
        <CarriedThingsInsuranceSumInput
          value={insuranceOptions.carriedThingsInsuranceSum}
          validation={validation}
          lookupData={lookupData.carriedThingsInsuranceSums}
          onChange={(value) => onChange(ID_CARRIED_THINGS_INSURANCE_SUM, value)}
        />
      )}
      {parkingDamageInput()}
      <AccidentInsuranceInput
        value={insuranceOptions.isAccidentInsurance}
        validation={validation}
        onChange={(value) => onChange(ID_IS_ACCIDENT_INSURANCE, value)}
      />
      {insuranceOptions.isAccidentInsurance && (
        <>
          <AccidentInsuredPersonsInput
            value={insuranceOptions.accidentInsuredPersons}
            lookupData={lookupData.insuredPersons}
            validation={validation}
            onChange={(value) => onChange(ID_ACCIDENT_INSURED_PERSONS, value)}
          />
          <AccidentInsuranceSumPayableAtDeathInput
            value={insuranceOptions.accidentInsuranceSumPayableAtDeath}
            validation={validation}
            lookupData={lookupData.insuredSumsAtDeath}
            onChange={(value) =>
              onChange(ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DEATH, value)
            }
          />
          <AccidentInsuranceSumPayableAtDisabilityInput
            value={insuranceOptions.accidentInsuranceSumPayableAtDisability}
            validation={validation}
            lookupData={lookupData.insuredSumsAtDisability}
            onChange={(value) =>
              onChange(ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY, value)
            }
          />
          <AccidentInsuranceDailyAllowanceInput
            value={insuranceOptions.accidentInsuranceDailyAllowance}
            validation={validation}
            lookupData={lookupData.allowances}
            onChange={(value) =>
              onChange(ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE, value)
            }
          />
        </>
      )}

      <>
        <HasCurrentInsuranceCompanyInput
          value={hasPreviousInsurance}
          onChange={(value) => {
            setHasPreviousInsurance(value);
            onChange(
              ID_CURRENT_INSURANCE,
              value
                ? staticInsuranceCompanyValues.none
                : staticInsuranceCompanyValues.noInsurance,
            );
          }}
          validation={validation}
        />
        {hasPreviousInsurance ? (
          <>
            <CurrentInsuranceCompanyInput
              value={insuranceOptions.currentInsuranceCompany}
              validation={validation}
              lookupData={lookupData.insuranceCompanies}
              onChange={(value) => onChange(ID_CURRENT_INSURANCE, value)}
            />
          </>
        ) : null}
      </>

      <StepButtons
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
