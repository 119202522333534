import React from 'react';
import { useSelector } from 'react-redux';
import {
  AutocompleteQuestion,
  AutocompleteQuestionProps,
} from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { ID_REGISTRATION_CANTON } from './const';
import { getLookupData } from '../../../../../state/LookUpData/selectors';
import { defaultLookupValue } from '../../../../../models/InsuranceInquiry/InsuranceInquiryUtils';
import EvaluatedValidation from '../../../../../utils/EvaluatedValidation';
import { LookupItem } from '../../../../../utils/types';

const translationsPrefix = 'step:usageDetails.registrationCanton-';

interface RegistrationCantonInputProps
  extends Omit<
    AutocompleteQuestionProps,
    'onChange' | 'label' | 'name' | 'options'
  > {
  validation: EvaluatedValidation;
  onChange: (value: LookupItem) => void;
}

export const RegistrationCantonInput: React.FC<RegistrationCantonInputProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();
  const lookupData = useSelector(getLookupData);
  const cantons = lookupData?.cantons ?? [];

  const options = cantons.map((entry) => ({
    disabled: entry.name === 'None',
    value: entry,
    label: t(`${translationsPrefix}${entry.name.toLowerCase()}`),
  }));

  const onValueChange = (value) => {
    onChange(value.name === 'None' ? defaultLookupValue : value);
  };

  return (
    <AutocompleteQuestion
      name={ID_REGISTRATION_CANTON}
      label={t('step:usageDetails.registrationCanton')}
      placeholder={t('common.choose')}
      options={options}
      onSearchInputClear={() => onChange(defaultLookupValue)}
      errorMessage={t(validation.key(ID_REGISTRATION_CANTON))}
      showFeedback={!!value?.key}
      value={value?.name === 'None' ? '' : value}
      onChange={onValueChange}
    />
  );
};
