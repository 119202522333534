import React from 'react';
import { SelectQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { SelectQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_ACCIDENT_INSURED_PERSONS } from './const';

export const AccidentInsuredPersonsInput: React.FC<SelectQuestionProps> = (
  props,
) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    disabled: entry.name === 'None',
    value: entry,
    label: t(
      `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURED_PERSONS}-${entry.name.toLowerCase()}`,
    ),
  }));

  return (
    <SelectQuestion
      label={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURED_PERSONS}`)}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_ACCIDENT_INSURED_PERSONS}-info`,
      )}
      onChange={onChange}
      options={options}
      value={value}
      name={ID_ACCIDENT_INSURED_PERSONS}
      onBlur={() => validation.isInvalid(ID_ACCIDENT_INSURED_PERSONS)}
      errorMessage={t(validation.key(ID_ACCIDENT_INSURED_PERSONS))}
      showFeedback={!!value?.key}
    />
  );
};
