import React from 'react';
import { SelectQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { SelectQuestionProps } from 'interfaces/QuestionInterfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_CURRENT_INSURANCE, ID_INSURANCE_COMPANY_NONE } from './const';

export const CurrentInsuranceCompanyInput: React.FC<SelectQuestionProps> = (
  props,
) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const filteredInsuranceCompaniesList = lookupData.filter(
    (insuranceCompany) => insuranceCompany.key !== ID_INSURANCE_COMPANY_NONE,
  );

  const options = filteredInsuranceCompaniesList.map((entry) => ({
    disabled: entry.name === 'None',
    value: entry,
    label: t(
      `${NS_STEP_INSURANCE_OPTIONS}.${ID_CURRENT_INSURANCE}-${entry.name.toLowerCase()}`,
    ),
  }));

  return (
    <SelectQuestion
      label={t(`${NS_STEP_INSURANCE_OPTIONS}.${ID_CURRENT_INSURANCE}`)}
      onChange={onChange}
      options={options}
      value={value}
      name={ID_CURRENT_INSURANCE}
      onBlur={() => validation.isInvalid(ID_CURRENT_INSURANCE)}
      errorMessage={t(validation.key(ID_CURRENT_INSURANCE))}
      showFeedback={!!value?.key}
    />
  );
};
