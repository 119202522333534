import React from 'react';
import { SelectQuestion } from '@scout24ch/fs24-design-system';
import { range } from '@scout24ch/fs24-utils';
import { NS_VEHICLE_SELECTION } from 'utils/namespace-const';
import { SelectQuestionProps } from 'interfaces';
import { useTranslation } from 'hooks';
import { CHOOSE, ID_YEAR_OF_PURCHASE, LBL_YEAR_OF_PURCHASE } from './const';

const getYearRangeOptions = (currentYear: number) => {
  const years = range(currentYear - 20, currentYear, {
    project: (year) => ({
      label: String(year),
      value: year,
    }),
    inclusive: true,
  });

  return years.reverse();
};

export const YearInput: React.FC<SelectQuestionProps> = (props) => {
  const { onChange, validation, value } = props;
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const options = [
    { label: t(CHOOSE), value: 0, disabled: true },
    ...getYearRangeOptions(currentYear),
  ];

  return (
    <SelectQuestion
      label={t(LBL_YEAR_OF_PURCHASE)}
      onChange={onChange}
      options={options}
      name={ID_YEAR_OF_PURCHASE}
      value={value}
      onBlur={() => validation.isInvalid('yearOfPurchase')}
      errorMessage={t(validation.key('yearOfPurchase', NS_VEHICLE_SELECTION))}
      showFeedback={!!value}
    />
  );
};
