import {
  STEP_DRIVER_MAIN,
  STEP_DRIVER_POLICY,
} from '../../../../../utils/namespace-const';

export const ID_GENDER = `${STEP_DRIVER_MAIN}.gender`;
export const ID_DATE_OF_BIRTH = `${STEP_DRIVER_MAIN}.dateOfBirth`;
export const ID_DATE_OF_DRIVING_LICENSE = `${STEP_DRIVER_MAIN}.dateOfDrivingLicense`;
export const ID_DATE_OF_MOTO_DRIVING_LICENSE = `${STEP_DRIVER_MAIN}.dateOfMotoDrivingLicense`;
export const ID_HAS_AUTO_DRIVING_LICENSE = `${STEP_DRIVER_MAIN}.hasAutoDrivingLicense`;
export const ID_NATIONALITY = `${STEP_DRIVER_MAIN}.nationality`;
export const ID_RESIDENCE_PERMIT = `${STEP_DRIVER_MAIN}.residencePermit`;
export const ID_RESIDENCE_PERMIT_SINCE = `${STEP_DRIVER_MAIN}.residencePermitSince`;
export const ID_IS_MAIN_DRIVER = `${STEP_DRIVER_MAIN}.isPolicyHolder`;
export const ID_EMAIL_ADDRESS = `${STEP_DRIVER_MAIN}.emailAddress`;
export const ID_DRIVER_EMAIL_ADDRESS_MANDATORY = `${STEP_DRIVER_MAIN}.emailAddressMandatory`;
export const ID_DRIVER_EMAIL_ADDRESS_MANDATORY_REASON = `${STEP_DRIVER_MAIN}.emailAddressMandatoryReason`;

export const ID_OTHER_DRIVERS = `${STEP_DRIVER_MAIN}.driverDetails.otherDrivers`;
export const ID_DRIVERS_OUTSIDE_THE_HOUSEHOLD = `${STEP_DRIVER_MAIN}.driverDetails.driversOutsideTheHousehold`;
export const ID_DRIVERS_WITH_LEARNING_OR_TRIAL_LICENSE = `${STEP_DRIVER_MAIN}.driverDetails.driversWithLearningOrTrialLicense`;
export const ID_DRIVERS_UNDER_THE_AGE_OF_25 = `${STEP_DRIVER_MAIN}.driverDetails.driversUnderTheAgeOf25`;

export const ID_DRIVER_GENDER = `${STEP_DRIVER_POLICY}.gender`;
export const ID_DRIVER_DATE_OF_BIRTH = `${STEP_DRIVER_POLICY}.dateOfBirth`;
export const ID_DRIVER_NATIONALITY = `${STEP_DRIVER_POLICY}.nationality`;
export const ID_DRIVER_RESIDENCE_PERMIT = `${STEP_DRIVER_POLICY}.residencePermit`;
export const ID_DRIVER_RESIDENCE_PERMIT_SINCE = `${STEP_DRIVER_POLICY}.residencePermitSince`;
export const ID_DRIVER_EMAIL_ADDRESS = `${STEP_DRIVER_POLICY}.emailAddress`;
