import { getVehicleByLicensePlate, LicensePlateResponse } from 'api/vehicles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsageDetails } from 'state/InquiryInput/selectors';
import { isNumberPlateValid } from 'utils/validation/numberPlateValidation';
import { getLookupData } from 'state/LookUpData/selectors';
import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';
import {
  UseGoogleTagManager,
  useGoogleTagManager,
} from '@scout24ch/fs24-gtm-react';
import { ACTION_FIND_VEHICLE, CAR_COMPARISON_CATEGORY } from 'utils/GTM';
import { transfromLicensePlateVehicleData } from '../helpers';
import { inquiryInputChanged } from '../../../../../../../state/InquiryInput/actions';

function shouldShowNotFoundError(result: LicensePlateResponse) {
  if (result.length === 0 || result.length > 1) {
    return true;
  }

  if (
    Object.keys(result[0].eurotax_common_data).length === 0 &&
    result[0].eurotax_different_data.length === 0
  ) {
    return true;
  }

  return false;
}

function trackError({
  result,
  gtm,
}: {
  result: LicensePlateResponse;
  gtm: UseGoogleTagManager<object>;
}): boolean {
  if (result.length === 0) {
    gtm.push({
      eventAction: ACTION_FIND_VEHICLE,
      eventCategory: CAR_COMPARISON_CATEGORY,
      vehicle_number_results: 0,
      vehicle_choice: 'plate',
      error_reason: 'No results found',
    });

    return;
  }

  if (result.length > 1) {
    gtm.push({
      eventAction: ACTION_FIND_VEHICLE,
      eventCategory: CAR_COMPARISON_CATEGORY,
      vehicle_number_results: result.length,
      vehicle_choice: 'plate',
      error_reason: 'Multiple cars associated',
    });

    return;
  }

  if (
    Object.keys(result[0].eurotax_common_data).length === 0 &&
    result[0].eurotax_different_data.length === 0
  ) {
    gtm.push({
      eventAction: ACTION_FIND_VEHICLE,
      eventCategory: CAR_COMPARISON_CATEGORY,
      vehicle_number_results: result.length,
      vehicle_choice: 'plate',
      error_reason: 'No Eurotax data',
    });

    return;
  }
}

export function useNumberPlateSearch(value: string) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gtm = useGoogleTagManager();
  const usageDetails = useSelector(getUsageDetails);
  const lookupData = useSelector(getLookupData);
  const [errorMessage, setErrorMessage] = useState('');
  const [failedToFetch, setFailedToFetch] = useState(false);
  const cantons = lookupData?.cantons ?? [];

  const isLicensePlateNumberValid = isNumberPlateValid(value);

  async function handleSearchByLicensePlate() {
    setErrorMessage('');
    setFailedToFetch(false);

    const canton = value?.substring(0, 2);
    const licenseNumber = value?.substring(2).replace(/\s+/g, '');

    try {
      const licensePlateVehicles = await getVehicleByLicensePlate(
        canton,
        licenseNumber,
      );

      // if there are multipe cars connected to one license plate or if there are no cars, or missing data
      if (shouldShowNotFoundError(licensePlateVehicles)) {
        trackError({
          result: licensePlateVehicles,
          gtm,
        });
        setErrorMessage(t('step:usageDetails.licensePlateNumber-notfound'));
        return;
      }

      const { make_name, model_name, registration_date } =
        licensePlateVehicles[0];
      const [year, month] = registration_date.split('-');

      // set first registration date and canton
      dispatch(
        inquiryInputChanged(
          {
            ...usageDetails,
            firstRegistrationYear: Number(year),
            firstRegistrationMonth: Number(month),
            registrationCanton:
              cantons.find((c) => c.name === canton) ?? defaultLookupValue,
          },
          'usageDetails',
        ),
      );

      const vehicles = transfromLicensePlateVehicleData(
        licensePlateVehicles[0],
      );

      gtm.push({
        eventAction: ACTION_FIND_VEHICLE,
        eventCategory: CAR_COMPARISON_CATEGORY,
        vehicle_number_results: vehicles.length,
        vehicle_choice: 'plate',
        error_reason: '',
      });

      return {
        vehicles,
        make_name,
        model_name,
        month,
        year,
      };
    } catch (error) {
      gtm.push({
        eventAction: ACTION_FIND_VEHICLE,
        eventCategory: CAR_COMPARISON_CATEGORY,
        vehicle_number_results: 0,
        vehicle_choice: 'plate',
        error_reason: 'Backend or server error',
      });
      setFailedToFetch(true);
    }
  }

  return {
    errorMessage,
    failedToFetch,
    isLicensePlateNumberValid,
    handleSearchByLicensePlate,
  };
}
