import React from 'react';
import { StepIncidents, StepMotoIncidents } from './';

export const createStepIncidents = (
  nextStep: () => void,
  previousStep: () => void,
  isAutoInsurance: boolean,
): JSX.Element => {
  if (isAutoInsurance) {
    return <StepIncidents nextStep={nextStep} previousStep={previousStep} />;
  }

  return <StepMotoIncidents nextStep={nextStep} previousStep={previousStep} />;
};
