import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';

export const claimDefaults = () => ({
  type: null,
  causer: defaultLookupValue,
  selfInflicted: defaultLookupValue,
  cause: defaultLookupValue,
  date: null,
  amount: '',
});

export const infringementDefaults = () => ({
  infringementReason: defaultLookupValue,
  date: null,
});

export const suspensionOfDrivingLicenseDefaults = () => ({
  suspensionPeriod: defaultLookupValue,
  date: null,
});
