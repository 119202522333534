import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { IS_AUTO_INSURANCE } from 'utils/const';
import { ID_SEARCH_TYPE_FLOW } from '../const';
import { SearchFlow } from '../../../../../../utils/types';

interface SearchTypeInputProps {
  onChange: (searchType: SearchFlow) => void;
  searchType?: SearchFlow;
}

function getDefaultSearchFlow(searchType: SearchFlow): SearchFlow {
  if (IS_AUTO_INSURANCE) {
    return searchType || SearchFlow.LICENSE_PLATE_NUMBER;
  }

  return searchType || SearchFlow.BRAND;
}

export const SearchTypeInput: React.FC<SearchTypeInputProps> = (props) => {
  const { onChange, searchType } = props;
  const { t } = useTranslation();

  const searchFlow = getDefaultSearchFlow(searchType);

  const AUTO_OPTIONS = [
    {
      value: SearchFlow.LICENSE_PLATE_NUMBER,
      label: t('step:usageDetails.searchType-numberplate'),
    },
    {
      value: SearchFlow.TYPE_CERTIFICATE,
      label: t('step:usageDetails.searchType-typeCertificate'),
    },
    {
      value: SearchFlow.BRAND,
      label: t('step:usageDetails.searchType-brand'),
    },
  ];

  const MOTO_OPTIONS = [
    {
      value: SearchFlow.BRAND,
      label: t('step:usageDetails.searchType-brand'),
    },
    {
      value: SearchFlow.TYPE_CERTIFICATE,
      label: t('step:usageDetails.searchType-typeCertificate'),
    },
  ];

  return (
    <RadioGroupQuestion
      name={ID_SEARCH_TYPE_FLOW}
      label={t('step:usageDetails.searchType')}
      labelHelp={t('step:usageDetails.searchType-info')}
      showFeedback={false}
      value={searchFlow}
      options={IS_AUTO_INSURANCE ? AUTO_OPTIONS : MOTO_OPTIONS}
      onChange={onChange}
    />
  );
};
