import React from 'react';
import { fs24, QuestionStack } from '@scout24ch/fs24-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyArray } from '@scout24ch/fs24-utils';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useTranslation, useValidation } from 'hooks';
import {
  AdditionalQuestionsInput,
  CancellationOrSpecialConditionsInput,
  ClaimListInput,
  DenialOrSpecialConditionsInput,
  InfringementListInput,
  SuspensionListInput,
  TermsAndConditionsInput,
} from './components';
import { StepButtons } from '../StepButtons';
import { StepIncidents } from './StepIncidents';

export const StepMotoIncidents: React.FC<StepIncidents> = (props) => {
  const { nextStep, previousStep } = props;
  const { incidents } = useSelector(getInquiryInputs);
  const validation = useValidation(NS_STEP_INCIDENTS, getInquiryValidation);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChange = (name: string, value: any) => {
    incidents[name] = value;
    dispatch(inquiryInputChanged(incidents, null));
  };

  const clearAdditionalInputs = () => {
    const newIncidents = { ...incidents };
    newIncidents.claims = [];
    newIncidents.infringements = [];
    newIncidents.suspensionOfDrivingLicense = [];
    dispatch(inquiryInputChanged(newIncidents));
  };

  const handleAdditionQuestionChange = (obj: any) => {
    onChange(obj.name, obj.value);
    if (!obj.value) {
      clearAdditionalInputs();
    }
  };

  return (
    <QuestionStack as="form">
      <fs24.p fontSize="lg" mt={2} mb={2}>
        {t(`${NS_STEP_INCIDENTS}.additional-questions-title`)}
      </fs24.p>
      <fs24.p fontSize="sm" mb={10}>
        {t(`${NS_STEP_INCIDENTS}.additional-questions`)}
      </fs24.p>
      <CancellationOrSpecialConditionsInput
        value={incidents.cancellationOrSpecialConditions}
        validation={validation}
        onChange={(value) => onChange('cancellationOrSpecialConditions', value)}
      />
      <DenialOrSpecialConditionsInput
        value={incidents.denialOrSpecialConditions}
        validation={validation}
        onChange={(value) => onChange('denialOrSpecialConditions', value)}
      />
      <AdditionalQuestionsInput
        isAdditionalQuestions={incidents.isAdditionalQuestions}
        claims={incidents.claims}
        validation={validation}
        infringements={incidents.infringements}
        suspensionOfDrivingLicense={incidents.suspensionOfDrivingLicense}
        onChange={handleAdditionQuestionChange}
      />
      {incidents.isAdditionalQuestions && !isEmptyArray(incidents.claims) && (
        <ClaimListInput
          // @ts-ignore
          claims={incidents.claims}
          onChange={(obj) => onChange(obj.name, obj.value)}
          validation={validation}
        />
      )}
      {incidents.isAdditionalQuestions &&
        !isEmptyArray(incidents.infringements) && (
          <InfringementListInput
            infringements={incidents.infringements}
            onChange={(obj) => onChange(obj.name, obj.value)}
            validation={validation}
          />
        )}
      {incidents.isAdditionalQuestions &&
        !isEmptyArray(incidents.suspensionOfDrivingLicense) && (
          <SuspensionListInput
            suspensions={incidents.suspensionOfDrivingLicense}
            onChange={(obj) => onChange(obj.name, obj.value)}
            validation={validation}
          />
        )}
      <TermsAndConditionsInput />
      <StepButtons
        nextStep={nextStep}
        nextStepText={t(`${NS_STEP_INCIDENTS}.button-next`)}
        previousStep={previousStep}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
