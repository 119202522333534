import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_RESIDENCE_PERMIT, ID_DRIVER_RESIDENCE_PERMIT } from './const';

const translationKeyPrefix = `${NS_STEP_DRIVER}.${ID_RESIDENCE_PERMIT}-`;

export const DriverResidencePermitInput: React.FC<RadioGroupQuestionProps> = (
  props,
) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    value: entry,
    label: t(`${translationKeyPrefix}${entry.name.toLowerCase()}`),
  }));

  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVER_RESIDENCE_PERMIT}`)}
      value={value}
      name={ID_DRIVER_RESIDENCE_PERMIT}
      onChange={onChange}
      options={options}
      onBlur={() => validation.isInvalid(ID_DRIVER_RESIDENCE_PERMIT)}
      errorMessage={t(validation.key(ID_DRIVER_RESIDENCE_PERMIT))}
      showFeedback={!!value?.key}
    />
  );
};
