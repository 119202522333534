import * as React from 'react';
import {
  Flex,
  fs24,
  Question,
  SelectQuestion,
} from '@scout24ch/fs24-design-system';
import moment from 'moment';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import {
  VEHICLE_SELECTION,
  CHOOSE_SHORT,
  ID_FIRST_REGISTRATION_MONTH,
  ID_FIRST_REGISTRATION_YEAR,
  LBL_FIRST_REGISTRATION,
} from 'containers/InquiryPage/components/StepVehicle/components/const';
import { IEvaluatedValidation } from 'utils/validation/types';
import { useTranslation } from 'hooks';
import { IS_AUTO_INSURANCE, LANGUAGES } from '../../../../../utils/const';

function generateLastXYears(lastXYears: number) {
  return Array.from(
    { length: lastXYears },
    (_, i) => new Date().getFullYear() - i,
  ).map((year) => ({ label: year.toString(), value: year }));
}

function getMonths(locale: string) {
  moment.locale(locale);
  return moment.months().map((m, index) => ({ value: index + 1, label: m }));
}

const LAST_30_YERAS = generateLastXYears(30);

interface YearMonthInputPropTypes {
  month: number;
  year: number;
  validation: IEvaluatedValidation;
  onYearChange(value: number): void;
  onMonthChange(value: number): void;
}

export const YearMonthInput: React.FC<YearMonthInputPropTypes> = (props) => {
  const { onYearChange, onMonthChange, year, month, validation } = props;

  const { t, i18n } = useTranslation();
  const monthOptions = React.useMemo(
    () => getMonths(LANGUAGES[i18n.language]),
    [i18n.language],
  );

  const defaultDropDownValue = {
    value: 0,
    label: t(CHOOSE_SHORT),
    disabled: true,
  };

  return (
    <Question
      name="yearMonthInput"
      label={t(LBL_FIRST_REGISTRATION)}
      labelHelp={IS_AUTO_INSURANCE && t(`${LBL_FIRST_REGISTRATION}-desc`)}
      showFeedback={!!year && !!month}
    >
      <Flex gap={2}>
        <SelectQuestion
          label={
            <fs24.span fontWeight="normal">
              {t(`${VEHICLE_SELECTION}.year`)}
            </fs24.span>
          }
          onChange={onYearChange as any}
          options={[defaultDropDownValue, ...LAST_30_YERAS]}
          value={year}
          name={`${ID_FIRST_REGISTRATION_YEAR}`}
          onBlur={() => validation.isInvalid(ID_FIRST_REGISTRATION_YEAR)}
          errorMessage={t(
            validation.key(ID_FIRST_REGISTRATION_YEAR, VEHICLE_SELECTION),
          )}
        />
        <SelectQuestion
          label={
            <fs24.span fontWeight="normal">
              {t(`${VEHICLE_SELECTION}.month`)}
            </fs24.span>
          }
          onChange={onMonthChange as any}
          options={[defaultDropDownValue, ...monthOptions]}
          value={month}
          name={`${ID_FIRST_REGISTRATION_MONTH}`}
          onBlur={() => validation.isInvalid(ID_FIRST_REGISTRATION_MONTH)}
          errorMessage={t(
            validation.key(ID_FIRST_REGISTRATION_MONTH, VEHICLE_SELECTION),
          )}
        />
      </Flex>
    </Question>
  );
};
