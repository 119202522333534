import React from 'react';
import { QuestionStack } from '@scout24ch/fs24-design-system';

const ListInputForm: React.FC<{
  index: number;
  children?: React.ReactElement[];
}> = (props) => {
  const { children, index } = props;
  return (
    <QuestionStack>
      {children.map((child, key) => {
        if (child) {
          return React.cloneElement(child, { index, key });
        }
      })}
    </QuestionStack>
  );
};

export default ListInputForm;
