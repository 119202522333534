import React from 'react';
import { SelectQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';
import { SelectQuestionProps } from 'interfaces';
import { ID_SUSPENSION_PERIOD } from './const';

export const SuspensionPeriodInput: React.FC<
  SelectQuestionProps & { index: number }
> = (props) => {
  const { index, value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    disabled: entry.name === 'None' || entry.name === 'All',
    value: entry,
    label: t(`step:incidents.suspensionPeriod-${entry.name.toLowerCase()}`),
  }));

  return (
    <SelectQuestion
      label={t(`${NS_STEP_INCIDENTS}.${ID_SUSPENSION_PERIOD}`)}
      onChange={onChange}
      options={options}
      value={value}
      name={`${ID_SUSPENSION_PERIOD}_${index}`}
      onBlur={() => validation.isInvalid(`${ID_SUSPENSION_PERIOD}_${index}`)}
      errorMessage={t(validation.key(`${ID_SUSPENSION_PERIOD}_${index}`))}
      showFeedback={!!value?.key}
    />
  );
};
