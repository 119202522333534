import React, { useMemo, useState } from 'react';
import { AutocompleteQuestion } from '@scout24ch/fs24-design-system';
import _remove from 'lodash/remove';
import _orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useTranslation } from 'hooks';
import { getVehicleMakes } from 'state/VehicleData/selectors';
import { VehicleMakes } from 'state/VehicleMakes/types';
import { NS_COMMON } from 'utils/namespace-const';
import EvaluatedValidation from '../../../../../../utils/EvaluatedValidation';
import { VEHICLE_SELECTION, ID_MAKE } from '../const';

interface MakeInputProps {
  makeName: string;
  onChange: (elementId: string, value: any) => void;
  validation: EvaluatedValidation;
}

const allMakesElement = {
  id: -2,
  name: 'step:vehicle-selection.allMakes',
  isFavourite: false,
};

export default function MakeInput(props: MakeInputProps): React.ReactElement {
  const { makeName, validation, onChange } = props;
  const { t } = useTranslation();
  const makesState = useSelector(getVehicleMakes);
  const [makes, setMakes] = useState<VehicleMakes[]>([]);

  useOnMount(() => {
    function loadMakeList() {
      if (makesState?.length) {
        const newMakes = [...makesState];
        const favourites = _remove(newMakes, (make) => make.isFavourite);
        setMakes([
          ...sortMakes(favourites),
          allMakesElement,
          ...sortMakes(newMakes),
        ]);
      }
    }

    loadMakeList();
  });

  function sortMakes(makes: VehicleMakes[]) {
    return _orderBy(makes, (make) => make.name, 'asc');
  }

  const handleMakeChange = (makes: VehicleMakes[], makeId: number) => {
    const foundMake = makes.find((make) => make.id === makeId) ?? {};
    onChange(ID_MAKE, foundMake);
  };

  function onMakeSearchInputClear() {
    onChange(ID_MAKE, { name: '' });
  }

  const selectOptions = useMemo(
    () =>
      makes.map((entry) => ({
        disabled: entry.id < 0,
        label: t(entry.name),
        value: entry.id,
      })),
    [makes, t],
  );

  const selected = makes.find((m) => m.name === makeName) ?? {
    id: -1,
    name: '',
  };

  function onMakeBlur(event: React.FocusEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (value === selected.name) {
      return;
    }

    const makeExist = makes.find((option) => option.name === value);

    if (makeExist) {
      onChange(ID_MAKE, { name: value });
      return;
    }

    if (selected.name) {
      onMakeSearchInputClear();
    }
  }

  return (
    <AutocompleteQuestion
      name={ID_MAKE}
      label={t(`${VEHICLE_SELECTION}.${ID_MAKE}`)}
      placeholder={t(`${NS_COMMON}.choose`)}
      options={selectOptions}
      onSearchInputClear={onMakeSearchInputClear}
      onBlurCapture={onMakeBlur}
      showFeedback={selected.id > 0}
      errorMessage={t(validation.key(ID_MAKE))}
      onChange={(value: any) => handleMakeChange(makes, value as number)}
      value={selected.name}
    />
  );
}
