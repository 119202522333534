import React from 'react';
import { CurrencyQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import { NumberQuestionProps } from 'interfaces';
import { ID_PRICE_OF_ACCESSORIES } from './const';

export const PriceOfAccessoriesInput: React.FC<NumberQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  const isInvalid = validation.isInvalid(ID_PRICE_OF_ACCESSORIES);

  return (
    <CurrencyQuestion
      value={value ?? ('' as unknown as number)}
      isInvalid={isInvalid}
      name={ID_PRICE_OF_ACCESSORIES}
      label={t(`${NS_STEP_VEHICLE}.${ID_PRICE_OF_ACCESSORIES}`)}
      labelHelp={t(`${NS_STEP_VEHICLE}.${ID_PRICE_OF_ACCESSORIES}-tooltip`)}
      onValueChange={onChange}
      onBlur={() => validation.isInvalid(ID_PRICE_OF_ACCESSORIES)}
      errorMessage={
        isInvalid ? t(validation.key(ID_PRICE_OF_ACCESSORIES)) : undefined
      }
      showFeedback={!!value}
    />
  );
};
