import { Person } from '../state/InquiryInput/types';

export const tryClearResidencePermit = (
  individual: Person,
  step: string,
  name: string,
  value: { name: string },
): void => {
  if (
    name === `${step}.nationality` &&
    value.name &&
    ['CH', 'None'].includes(value.name)
  ) {
    individual.residencePermit = null;
    individual.residencePermitSince = null;
  }
};

export default tryClearResidencePermit;
