import { defaultLookupValue } from 'models/InsuranceInquiry/InsuranceInquiryUtils';
import { LookupItem } from 'utils/types';

export const getHasPreviousInsuranceState = (
  currentInsuranceCompany: LookupItem,
): null | boolean => {
  if (currentInsuranceCompany === null) {
    return null;
  }
  if (
    currentInsuranceCompany.key === staticInsuranceCompanyValues.noInsurance.key
  ) {
    return false;
  }

  return true;
};

export const staticInsuranceCompanyValues: {
  [key: string]: LookupItem;
} = {
  noInsurance: {
    key: 15,
    name: 'NoIns',
    displayName: 'No Insurance',
  },
  none: defaultLookupValue,
};
