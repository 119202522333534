import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { ID_TYPE_CERTIFICATE } from '../const';
import { getVehicle } from '../../../../../../state/InquiryInput/selectors';
import { inquiryInputChanged } from '../../../../../../state/InquiryInput/actions';
import { IEvaluatedValidation } from '../../../../../../utils/validation/types';

interface TypeCertificateInputProps {
  validation: IEvaluatedValidation;
  value: string;
}

export const TypeCertificateInput: React.FC<TypeCertificateInputProps> = (
  props,
) => {
  const { value, validation } = props;
  const { t } = useTranslation();
  const vehicle = useSelector(getVehicle);
  const dispatch = useDispatch();

  return (
    <TextQuestion
      label={t('step:usageDetails.typeCertificate')}
      labelHelp={t('step:usageDetails.typeCertificate-tooltip')}
      name={ID_TYPE_CERTIFICATE}
      value={value}
      onBlur={() => validation.isInvalid('typeCertificate')}
      errorMessage={t(validation.key('typeCertificate'))}
      showFeedback={!!value}
      onValueChange={(value) =>
        dispatch(
          inquiryInputChanged(
            { ...vehicle, typeCertificate: value },
            'vehicle',
          ),
        )
      }
    />
  );
};
