import React from 'react';
import { BooleanQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { BooleanQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_DRIVERS_UNDER_THE_AGE_OF_25 } from './const';

export const DriversUnderTheAgeOf25Input: React.FC<BooleanQuestionProps> = (
  props,
) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  return (
    <BooleanQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVERS_UNDER_THE_AGE_OF_25}`)}
      value={value}
      name={ID_DRIVERS_UNDER_THE_AGE_OF_25}
      onChange={onChange}
      onBlur={() => validation.isInvalid(ID_DRIVERS_UNDER_THE_AGE_OF_25)}
      errorMessage={t(validation.key(ID_DRIVERS_UNDER_THE_AGE_OF_25))}
      showFeedback={typeof value === 'boolean'}
    />
  );
};
