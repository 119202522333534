import React from 'react';
import { CurrencyQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { QuestionProps } from 'interfaces';
import { NS_STEP_INCIDENTS } from '../../../../../utils/namespace-const';
import { ID_CLAIM_AMOUNT } from './const';

export interface ClaimAmountInputProps extends QuestionProps<number> {
  index: number;
}

export const ClaimAmountInput: React.FC<ClaimAmountInputProps> = (props) => {
  const { index, value, onChange, validation } = props;
  const { t } = useTranslation();

  const isInvalid = validation.isInvalid(`${ID_CLAIM_AMOUNT}_${index}`);

  return (
    <CurrencyQuestion
      value={value}
      isInvalid={isInvalid}
      name={`${ID_CLAIM_AMOUNT}_${index}`}
      label={t(`${NS_STEP_INCIDENTS}.${ID_CLAIM_AMOUNT}`)}
      onValueChange={onChange}
      onBlur={() => validation.isInvalid(`${ID_CLAIM_AMOUNT}_${index}`)}
      errorMessage={
        isInvalid ? t(validation.key(`${ID_CLAIM_AMOUNT}_${index}`)) : undefined
      }
      showFeedback={!!value}
    />
  );
};
