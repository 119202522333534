import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_GENDER, ID_DRIVER_GENDER } from './const';

export const DriverGenderInput: React.FC<RadioGroupQuestionProps> = (props) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((entry) => ({
    value: entry,
    label: t(`${NS_STEP_DRIVER}.${ID_GENDER}-${entry.name.toLowerCase()}`),
  }));

  return (
    <RadioGroupQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVER_GENDER}`)}
      name={ID_DRIVER_GENDER}
      onChange={onChange}
      options={options}
      value={value}
      onBlur={() => validation.isInvalid(ID_DRIVER_GENDER)}
      errorMessage={t(validation.key(ID_DRIVER_GENDER))}
      showFeedback={!!value?.key}
    />
  );
};
