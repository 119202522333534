import React from 'react';
import {
  AutocompleteQuestion,
  AutocompleteQuestionProps,
} from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { sortCountries } from 'state/LookUpData/SortLookUpData/epic';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_NATIONALITY } from './const';
import { LookupItem } from '../../../../../utils/types';
import { defaultLookupValue } from '../../../../../models/InsuranceInquiry/InsuranceInquiryUtils';
import EvaluatedValidation from '../../../../../utils/EvaluatedValidation';

interface NationalityInputProps
  extends Omit<AutocompleteQuestionProps, 'onChange' | 'label' | 'options'> {
  lookupData?: LookupItem[];
  validation: EvaluatedValidation;
  onChange: (value: LookupItem) => void;
}

export const NationalityInput: React.FC<NationalityInputProps> = (props) => {
  const { value, onChange, validation, lookupData, name } = props;
  const { t } = useTranslation();

  const options = lookupData
    .filter((c) => !['None'].includes(c.name))
    .map((entry) => ({
      disabled: entry.name === 'All',
      value: entry,
      label: t(
        `${NS_STEP_DRIVER}.mainDriver.nationality-${entry.name.toLowerCase()}`,
      ),
    }))
    .sort(sortCountries);

  const onValueChange = (value) => {
    onChange(value.name === 'None' ? defaultLookupValue : value);
  };

  return (
    <AutocompleteQuestion
      name={name}
      label={t('step:driver.mainDriver.nationality')}
      placeholder={t('common.choose')}
      options={options}
      onSearchInputClear={() => onChange(defaultLookupValue)}
      errorMessage={t(validation.key(ID_NATIONALITY))}
      showFeedback={!!value?.key}
      value={value?.name === 'None' ? '' : value}
      onChange={onValueChange}
    />
  );
};
