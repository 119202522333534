import React, { useState } from 'react';
import { DateQuestion } from '@scout24ch/fs24-design-system';
import { validateDateBefore18Years } from '@scout24ch/fs24-forms';
import { useOnMount } from '@scout24ch/fs24-hooks';
import { useTranslation } from 'hooks';
import {
  convertDateValueToString,
  fromInput,
  isValidDateInput,
} from 'utils/dateformat-const';
import { DateQuestionProps } from 'interfaces';
import { NS_STEP_DRIVER } from '../../../../../utils/namespace-const';
import { ID_DRIVER_DATE_OF_BIRTH } from './const';

export const DriverDateOfBirthInput = (props: DateQuestionProps) => {
  const { value, validation, onChange } = props;
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);

  useOnMount(() => {
    setValid(!validateDateBefore18Years(convertDateValueToString(value)));
  });

  const handleOnChange = (value: string) => {
    setValid(!validateDateBefore18Years(value));

    onChange(isValidDateInput(value) ? fromInput(value) : value);
  };

  return (
    <DateQuestion
      label={t(`${NS_STEP_DRIVER}.${ID_DRIVER_DATE_OF_BIRTH}`)}
      name={ID_DRIVER_DATE_OF_BIRTH}
      value={convertDateValueToString(value)}
      errorMessage={t(validation.key(ID_DRIVER_DATE_OF_BIRTH))}
      onValueChange={handleOnChange}
      showFeedback={isValid}
    />
  );
};
