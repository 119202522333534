import React from 'react';
import { QuestionStack, fs24 } from '@scout24ch/fs24-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { inquiryInputChanged } from 'state/InquiryInput/actions';
import { NS_STEP_VEHICLE } from 'utils/namespace-const';
import {
  getInquiryInputs,
  getInquiryValidation,
  getIsInquiryPageValid,
} from 'state/selectors';
import { useValidation } from 'hooks';
import { useOnMount } from '@scout24ch/fs24-hooks';
import {
  createSplitEvent,
  useGoogleTagManager,
} from '@scout24ch/fs24-gtm-react';
import { FeatureFlag, FeatureFlagTreatmentValues } from 'utils/split/const';
import { ID_KM_PER_YEAR, ID_PRICE_OF_ACCESSORIES } from './components/const';
import {
  GarageInput,
  KmPerYearInput,
  LeasingInput,
  PriceOfAccessoriesInput,
  RegistrationCantonInput,
  UsageInput,
  MileageInput,
  VehicleSearch,
} from './components';
import { StepButtons } from '../StepButtons';
import { YearBoughtInput } from './components/YearBoughtInput';
import { useAS24PerfectCarMatch } from './hooks/useAS24PerfectCarMatch';
export interface StepVehicleProps {
  nextStep: () => void;
  previousStep: () => void;
  lastStep?: () => void;
  isAdjustingData?: boolean;
  vehicleId: number;
  abredirect?: string; // TODO: remove after AGGR-4752 is done (FeatureFlgt.PortalAS24VehicleRedirect)
}

export const StepVehicle: React.FC<StepVehicleProps> = (props) => {
  const {
    vehicleId,
    lastStep,
    nextStep,
    isAdjustingData,
    previousStep,
    abredirect,
  } = props;
  const { usageDetails } = useSelector(getInquiryInputs);
  const isInquiryPageValid = useSelector(getIsInquiryPageValid);
  const validation = useValidation(NS_STEP_VEHICLE, getInquiryValidation);
  const {
    isPerfectMatch,
    showAS24VehicleInfoCard,
    setShowAS24VehicleInfoCard,
  } = useAS24PerfectCarMatch();
  const dispatch = useDispatch();
  const gtm = useGoogleTagManager();

  const onChange = (name: string, value: any) => {
    usageDetails[name] = value;
    dispatch(inquiryInputChanged(usageDetails, null));
  };

  // TODO: remove after AGGR-4752 is done (FeatureFlag.PortalAS24VehicleRedirect)
  useOnMount(() => {
    if (abredirect) {
      gtm.push({
        ...createSplitEvent({
          [FeatureFlag.PortalAS24VehicleRedirect]:
            FeatureFlagTreatmentValues.ON,
        }),
      });
    }
  });

  if (showAS24VehicleInfoCard === undefined) {
    return <fs24.div height="100vh"></fs24.div>;
  } // prevents flickering while checking is perfect match

  return (
    <QuestionStack as="form">
      <VehicleSearch
        vehicleId={vehicleId}
        validation={validation}
        isPerfectMatch={isPerfectMatch}
        showAS24VehicleInfoCard={showAS24VehicleInfoCard}
        setShowAS24VehicleInfoCard={setShowAS24VehicleInfoCard}
      />

      {isPerfectMatch && usageDetails.mileage ? null : (
        <MileageInput
          value={usageDetails.mileage}
          validation={validation}
          onChange={(value) =>
            onChange('mileage', value === undefined ? '' : String(value))
          }
        />
      )}
      {isPerfectMatch && usageDetails.priceOfAccessories ? null : (
        <PriceOfAccessoriesInput
          value={usageDetails.priceOfAccessories}
          validation={validation}
          onChange={(value) => {
            onChange(
              ID_PRICE_OF_ACCESSORIES,
              value === undefined ? '' : String(value),
            );
          }}
        />
      )}
      {isPerfectMatch ? null : <YearBoughtInput validation={validation} />}
      <LeasingInput
        value={usageDetails.leasing}
        validation={validation}
        onChange={(value) => onChange('leasing', value)}
      />
      <KmPerYearInput
        value={usageDetails.kilometerPerYear}
        validation={validation}
        onChange={(value) =>
          onChange(ID_KM_PER_YEAR, value === undefined ? '' : String(value))
        }
      />
      <UsageInput
        privateUsage={usageDetails.privateUsage}
        businessUsage={usageDetails.businessUsage}
        commuteUsage={usageDetails.commuteUsage}
        onChange={(obj) => onChange(obj.name, obj.value)}
        validation={validation}
      />
      <RegistrationCantonInput
        value={usageDetails.registrationCanton}
        validation={validation}
        onChange={(value) => onChange('registrationCanton', value)}
      />
      <GarageInput
        garageAtHome={usageDetails.garageAtHome}
        garageAtWork={usageDetails.garageAtWork}
        validation={validation}
        onChange={(obj) => onChange(obj.name, obj.value)}
      />
      <StepButtons
        nextStep={nextStep}
        previousStep={previousStep}
        lastStep={lastStep}
        isAdjustingData={isAdjustingData}
        isInquiryPageValid={isInquiryPageValid}
      />
    </QuestionStack>
  );
};
