import { SelectOption } from 'utils/types';

function getSortOrder(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

function getCompareResult<T extends object>(
  getOrderNumber: (entry: T) => number,
  a: T,
  b: T,
) {
  const orderA = getOrderNumber(a);
  const orderB = getOrderNumber(b);

  const compareResult = getSortOrder(orderA, orderB);

  if (compareResult !== 0) {
    return compareResult;
  }

  if ('displayName' in a && 'displayName' in b) {
    return ((a['displayName'] as string) ?? '')
      .toLowerCase()
      .localeCompare(
        ((b['displayName'] as string) ?? '').toLowerCase(),
        'de-CH',
      );
  }

  if ('label' in a && 'label' in b) {
    return ((a['label'] as string) ?? '')
      .toLowerCase()
      .localeCompare(((b['label'] as string) ?? '').toLowerCase(), 'de-CH');
  }

  return 0;
}

export const sortCountries = (a: SelectOption, b: SelectOption) => {
  const getOrderNumber = (entry: SelectOption): number => {
    if (
      ['tous les pays', 'alle Länder', 'tutti i paesi'].includes(entry.label)
    ) {
      return -2;
    }

    if (['Autriche', 'Austria', 'Österreich'].includes(entry.label)) {
      return -4;
    }

    if (['France', 'Frankreich', 'Francia'].includes(entry.label)) {
      return -6;
    }

    if (['Deutschland', 'Allemagne', 'Germania'].includes(entry.label)) {
      return -8;
    }

    if (['Suisse', 'Schweiz', 'Svizzera'].includes(entry.label)) {
      return -10;
    }

    return 0;
  };

  return getCompareResult<SelectOption>(getOrderNumber, a, b);
};

export const sortCantons = (a, b) => {
  const getOrderNumber = (entry) => (entry.name === 'None' ? -2 : 0);

  return getCompareResult(getOrderNumber, a, b);
};

export const sortInsuranceCompanies = (insuranceCompanies) => {
  const getOrderNumber = (entry) => {
    switch (entry.name) {
      case 'None':
        return -6;
      case 'NoIns':
        return -4;
      case 'NewInCH':
        return -2;
      case 'Other':
        return 99;
      default:
        return 0;
    }
  };

  return insuranceCompanies.sort((a, b) =>
    getCompareResult(getOrderNumber, a, b),
  );
};
