import React from 'react';
import {
  Alert,
  FormControl,
  FormLabel,
  RadioGroupQuestion,
} from '@scout24ch/fs24-design-system';
import { formatCurrencyToInteger } from '@scout24ch/fs24-utils';
import { NS_STEP_VEHICLE, NS_STEP_OVERVIEW } from 'utils/namespace-const';
import {
  VehicleSuggestion,
  VehicleTypeSuggestion,
} from 'containers/InquiryPage/components/StepVehicle/types';
import { IEvaluatedValidation } from 'utils/validation/types';
import { useTranslation } from 'hooks';

interface VehicleSelectionProps {
  vehicleSuggestions: VehicleSuggestion[] | VehicleTypeSuggestion[];
  selectedVehicleEuroTaxId: number;
  onChange({ id }: any): void;
  validation: IEvaluatedValidation;
}

const hasTypeCertificateSuggestions = (vehicleSuggestions) =>
  vehicleSuggestions.some((suggestions) =>
    Object.keys(suggestions).includes('euroTaxId'),
  );

const getVehicleSelectionAsSelectGroupItems = (
  vehicleSuggestions: VehicleSuggestion[] | VehicleTypeSuggestion[],
  hpText: string,
) => {
  if (hasTypeCertificateSuggestions(vehicleSuggestions)) {
    return vehicleSuggestions.map((vehicle) => ({
      value: vehicle.euroTaxId,
      label: vehicle.vehicleType,
    }));
  }

  return vehicleSuggestions.map((key) => ({
    value: key.id,
    label: `${key.name} 
      ${key.engineHorsePower} ${hpText} - ${formatCurrencyToInteger(
        key.priceProvider,
      )}`,
  }));
};

const getSelectedVehicle = (id, vehicleSuggestions) => {
  if (hasTypeCertificateSuggestions(vehicleSuggestions)) {
    const vehicle = vehicleSuggestions.find(
      (vehicle) => vehicle.euroTaxId === id,
    );
    return {
      id: vehicle.euroTaxId,
      vehicleType: vehicle.vehicleType,
    };
  }

  const vehicle = vehicleSuggestions.find((vehicle) => vehicle.id === id);

  return {
    id: vehicle.id,
    vehicleType: vehicle.name,
  };
};

export const VehicleSelection: React.FC<VehicleSelectionProps> = ({
  vehicleSuggestions,
  selectedVehicleEuroTaxId,
  onChange,
  validation,
}) => {
  const { t } = useTranslation();
  const vehicleSelections = getVehicleSelectionAsSelectGroupItems(
    vehicleSuggestions,
    t(`${NS_STEP_OVERVIEW}.hp`),
  );

  return (
    <>
      {vehicleSuggestions.length > 0 ? (
        <RadioGroupQuestion
          label={t(`${NS_STEP_VEHICLE}.vehicleSelection`)}
          value={selectedVehicleEuroTaxId}
          name="vehicleSelection"
          onChange={(value) => {
            onChange(getSelectedVehicle(value, vehicleSuggestions));
          }}
          options={vehicleSelections}
          onBlur={() => validation.isInvalid('vehicleSelection')}
          errorMessage={t(validation.key('vehicleSelection'))}
          showFeedback={!!selectedVehicleEuroTaxId}
        />
      ) : (
        <FormControl>
          <FormLabel>{t(`${NS_STEP_VEHICLE}.vehicleSelection`)}</FormLabel>
          <Alert status="error">
            {t(`${NS_STEP_VEHICLE}.vehicleNotFound`)}
          </Alert>
        </FormControl>
      )}
    </>
  );
};
