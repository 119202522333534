import { useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_FIND_VEHICLE, CAR_COMPARISON_CATEGORY } from 'utils/GTM';
import { searchVehiclesByCertificate } from '../../../../../api/vehicles';
import { getVehicle } from '../../../../../state/InquiryInput/selectors';
import { SearchFlow } from '../../../../../utils/types';

export function useTypeCertificate() {
  const [typeCertificateError, setTypeCertificateError] = useState(null);
  const [noResultsByCertificate, setNoResults] = useState(false);
  const vehicle = useSelector(getVehicle);
  const gtm = useGoogleTagManager();
  const { typeCertificate, searchType } = vehicle;
  const isTypeCertificateFlow = searchType === SearchFlow.TYPE_CERTIFICATE;

  const fetchVehicleByCertificate = async ({ month, year }) => {
    try {
      setNoResults(false);
      setTypeCertificateError(false);
      const vehiclesByCertificate = await searchVehiclesByCertificate({
        typeCertificate,
        month,
        year,
      });

      if (vehiclesByCertificate.vehicleTypes === undefined) {
        gtm.push({
          eventAction: ACTION_FIND_VEHICLE,
          eventCategory: CAR_COMPARISON_CATEGORY,
          vehicle_number_results: 0,
          vehicle_choice: 'type',
          error_reason: 'No results',
        });
        setNoResults(true);
        return;
      }

      return vehiclesByCertificate;
    } catch (error) {
      setTypeCertificateError(true);
      gtm.push({
        eventAction: ACTION_FIND_VEHICLE,
        eventCategory: CAR_COMPARISON_CATEGORY,
        vehicle_number_results: 0,
        vehicle_choice: 'type',
        error_reason: 'Error fetching by type certificate',
      });
    }
  };

  return {
    fetchVehicleByCertificate,
    noResultsByCertificate,
    typeCertificateError,
    isTypeCertificateFlow,
  };
}
