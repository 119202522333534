import React, { ReactNode } from 'react';
import { Button, ButtonGroup } from '@scout24ch/fs24-design-system';
import { Plus, Delete } from '@scout24ch/fs24-design-system/icons/24';
import { useTranslation } from 'hooks';
import { NS_STEP_INCIDENTS } from 'utils/namespace-const';

interface ListInputProps {
  translationKey: string;
  addElement: (index: number) => void;
  removeElement: (index: number) => void;
  children: ReactNode[];
}

export const ListInput: React.FC<ListInputProps> = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.children.map((child, index) => (
        <React.Fragment key={`${props.translationKey}_children_${index}`}>
          {child}
          <ButtonGroup mb={10}>
            <Button
              variant="ghost"
              mt={10}
              colorScheme="red"
              leftIcon={<Delete />}
              onClick={() => props.removeElement(index)}
            >
              {t(`${NS_STEP_INCIDENTS}.${props.translationKey}-remove`)}
            </Button>
            <Button
              variant="ghost"
              colorScheme="green"
              leftIcon={<Plus />}
              onClick={() => {
                props.addElement(index);
              }}
            >
              {t(`${NS_STEP_INCIDENTS}.${props.translationKey}-add`)}
            </Button>
          </ButtonGroup>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default ListInput;
