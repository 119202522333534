import React from 'react';
import { RadioGroupQuestion } from '@scout24ch/fs24-design-system';
import { useTranslation } from 'hooks';
import { RadioGroupQuestionProps } from 'interfaces';
import { NS_STEP_INSURANCE_OPTIONS } from '../../../../../utils/namespace-const';
import { ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING } from './const';

export const FullyComprehensiveInsuranceCostSharingInput: React.FC<
  RadioGroupQuestionProps
> = (props) => {
  const { value, onChange, validation, lookupData } = props;
  const { t } = useTranslation();

  const options = lookupData.map((i) => ({
    value: i,
    label: t(
      `${NS_STEP_INSURANCE_OPTIONS}.${ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING}-${i.name.toLowerCase()}`,
    ),
  }));

  return (
    <RadioGroupQuestion
      label={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING}`,
      )}
      labelHelp={t(
        `${NS_STEP_INSURANCE_OPTIONS}.${ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING}-suggestion`,
      )}
      value={value}
      name={ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING}
      onChange={onChange}
      options={options}
      onBlur={() =>
        validation.isInvalid(ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING)
      }
      errorMessage={t(
        validation.key(ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING),
      )}
      showFeedback={!!value?.key}
    />
  );
};
